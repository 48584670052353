import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EditorComponent implements OnInit, OnDestroy {
  constructor(private _title: Title) {}

  ngOnInit(): void {
    this._title.setTitle('OWNXR | Editor');
  }

  ngOnDestroy(): void {
    this._title.setTitle('OWNXR');
  }
}

import { AccountOption } from '@/data/src/lib/models/data/account';
import { EnterpriseService } from '@/data/src/lib/services/enterprise.service';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-collaborator-group-edit',
  templateUrl: './collaborator-group-edit.component.html',
  styleUrls: ['./collaborator-group-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaboratorGroupEditComponent implements OnInit {
  dialog = false;
  isLoading = false;
  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(20)]),
  });

  get errors() {
    return Object.keys(this.form.controls.name.errors || {});
  }

  @Input() enterpriseAccount!: AccountOption;
  @Output() onChange = new EventEmitter<string>();

  constructor(private _enterpriseService: EnterpriseService, private _cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  reset() {
    this.isLoading = false;
    this.form.reset();
  }

  open() {
    this.form.controls.name.setValue(this.enterpriseAccount?.Name || '');
    this.dialog = true;
  }

  close() {
    this.reset();
    this.dialog = false;
    this._cd.detectChanges();
  }

  async change() {
    if (this.form.controls.name.value) {
      this.isLoading = true;
      const payload = {
        id: this.enterpriseAccount.Id,
        name: this.form.controls.name.value,
      };
      const response = await this._enterpriseService.updateEnterprise(payload);
      this.isLoading = false;
      if (response) {
        this.onChange.emit(this.form.controls.name.value || '');
        this.close();
      }
    }
  }
}

<div class="title">{{ marketItem.Name || marketItem.DisplayName }}</div>
<div class="oxr">
  <div class="oxr-background">
    <div class="icon icon-ownxr"></div>
  </div>
  OWNXR
  <!-- {{ marketItem.Type }} -->
</div>
<div class="actions">
  <div class="drop-shadow">
    <div class="icon" (click)="toggleLike()" [ngClass]="{ 'icon-like-fill ': isLiked, 'icon-like': !isLiked }"></div>
  </div>
  <div class="drop-shadow" (click)="onShareClick()">
    <div class="icon icon-share"></div>
  </div>
</div>
<div class="statistics">
  <div class="item">
    <div class="icon icon-view"></div>
    {{ (marketItem.Views | number: '4.0-0' | removeComma) || '0000' }}
  </div>
  <div class="item">
    <div class="icon icon-like"></div>
    {{ (marketItem.Likes | number: '4.0-0' | removeComma) || '0000' }}
  </div>
</div>
<div class="purchase">
  <div class="price">{{ marketItem.Tokens | currency }}</div>
  @if (!isPurchased) {
    <div class="drop-shadow" [title]="(isInCart ? 'market.removeFromCart' : 'market.addToCart') | translate" (click)="toggleCart()">
      <div class="icon icon-cart" [class.in-cart]="isInCart"></div>
    </div>
  }
  @if (isPurchased) {
    <span class="owned">{{ 'market.owned' | translate }}</span>
  }
</div>

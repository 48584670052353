<div class="actions">
  <div class="drop-shadow">
    <div class="icon" (click)="toggleLike()" [ngClass]="{ 'icon-like-fill ': isLiked, 'icon-like': !isLiked }"></div>
  </div>
  <div class="drop-shadow" (click)="onShareClick()">
    <div class="icon icon-share"></div>
  </div>
</div>
<div class="creator">
  <div class="creator-background">
    @if (isCreatorUserAdmin || !scene.CreatorUser?.Thumbnail) {
      <div class="icon icon-ownxr"></div>
    }
    @if (!isCreatorUserAdmin && scene.CreatorUser?.Thumbnail) {
      <img class="thumbnail" [src]="scene.CreatorUser?.Thumbnail" />
    }
  </div>
  {{ isCreatorUserAdmin ? 'OWNXR' : scene.CreatorUser?.Alias ?? 'OWNXR' }}
</div>
<div class="title" title="{{ scene.Name }}">{{ scene.Name }}</div>
<textarea readonly uiTextarea nonResizable class="description" title="{{ scene.Description }}">{{ scene.Description }}</textarea>

<div class="actions">
  <div class="drop-shadow">
    @if (isCollaborator) {
      <div class="icon icon-settings" uiTooltip="{{ 'shared.list.contactOwner' | translate }}"></div>
    } @else {
      <div class="icon icon-settings" (click)="openSettings()"></div>
    }
  </div>
</div>
<div class="title">{{ scene.Name }}</div>
@if (scene.Plan === scenePlan.Enterprise) {
  <div class="creator">
    <div class="creator-background">
      @if (!scene.CreatorUser?.Thumbnail) {
        <div class="icon icon-ownxr"></div>
      }
      @if (scene.CreatorUser?.Thumbnail) {
        <img class="thumbnail" [src]="scene.CreatorUser?.Thumbnail" />
      }
    </div>
    {{ scene.CreatorUser?.DisplayName ?? 'OWNXR' }}
  </div>
}
@if (scene.Expires && !isCreatorUserEnterpriseOrAdmin) {
  <div class="expiration">{{ 'shared.fields.expiresOn' | translate }}: {{ scene.Expires | date: 'yyyy-MM-dd HH:mm' }}</div>
}
<div class="date">{{ 'shared.fields.lastModified' | translate }}: {{ scene.DateModified | date: 'yyyy-MM-dd' }}</div>
<a class="link" href="{{ baseUrl }}/oxr/space/{{ scene.Id }}" target="_blank">{{ baseUrl }}/oxr/space/{{ scene.Id }}</a>
<div class="icon icon-share" (click)="onShareClick()"></div>
<!-- <div class="username" *ngIf="scene.Plan === scenePlan.Enterprise">
{{ scene.CreatorUser?.DisplayName }}
</div> -->
<!-- <img class="thumbnail" *ngIf="scene.Plan === scenePlan.Enterprise && scene.CreatorUser?.Thumbnail" [src]="scene.CreatorUser?.Thumbnail" /> -->
<!-- <div *ngIf="scene.Expires && !isCreatorUserEnterpriseOrAdmin" class="info expiration">
{{ 'shared.fields.expiresOn' | translate }}: {{ scene.Expires | date: 'yyyy-MM-dd HH:mm' }}
</div>
<div class="info date">{{ 'shared.fields.lastModified' | translate }}: {{ scene.DateModified | date: 'yyyy-MM-dd' }}</div> -->
<!-- <button ui-outlined-button xxs class="upgrade">{{ 'shared.actions.upgrade' | translate }}</button> -->

<section class="aside">
  <div class="title">{{ 'guide.title' | translate }}</div>
  <div class="search">
    <input uiInput sm type="text" [ngModel]="searchText" (ngModelChange)="onSearchChange($event)" />
    <div class="icon icon-search"></div>
  </div>
  @for (item of displayMenuItems; track item) {
    <div class="item-container" [class.expanded]="item.expanded">
      <a [routerLink]="[item.route]" routerLinkActive="active" class="menu-item" (click)="onToggleExpandedState(item)">
        {{ item.title | uppercase }}
      </a>
      @if (item.children.length && item.expanded) {
        <div class="children-container">
          @for (child of item.children; track child) {
            <a [routerLink]="[child.route]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="menu-item child">{{
              child.title | translate
            }}</a>
          }
        </div>
      }
    </div>
  }
</section>
<section #main class="main">
  @switch (activeRoute) {
    @case ('/guide/oxr/sideMenu') {
      <ng-container [ngTemplateOutlet]="oxrSideMenu"></ng-container>
    }
    @case ('/guide/oxr/rightPanel') {
      <ng-container [ngTemplateOutlet]="oxrRightPanel"></ng-container>
    }
    @case ('/guide/oxr/toolbar') {
      <ng-container [ngTemplateOutlet]="oxrToolbar"></ng-container>
    }
    @case ('/guide/market') {
      <ng-container [ngTemplateOutlet]="market"></ng-container>
    }
    @case ('/guide/community') {
      <ng-container [ngTemplateOutlet]="community"></ng-container>
    }
    @case ('/guide/editor') {
      <ng-container [ngTemplateOutlet]="editorHowToUse"></ng-container>
    }
    @case ('/guide/editor/environment') {
      <ng-container [ngTemplateOutlet]="editorEnvironment"></ng-container>
    }
    @case ('/guide/editor/stage') {
      <ng-container [ngTemplateOutlet]="editorStage"></ng-container>
    }
    @case ('/guide/editor/object') {
      <ng-container [ngTemplateOutlet]="editorObject"></ng-container>
    }
    @default {
      <ng-container [ngTemplateOutlet]="oxrHowToUse"></ng-container>
    }
  }
</section>
<ng-template #oxrHowToUse>
  <h1 class="title">{{ 'guide.oxr.howToUse.title' | translate }}</h1>
  <section class="content-container">
    @for (number of ['01', '02', '03', '04', '05']; track number) {
      <div class="content">
        <p class="step">
          STEP <span>{{ number }}</span>
        </p>
        <p class="content-title" [innerHTML]="'guide.oxr.howToUse.' + number | translate"></p>
        <img class="screenshot" srcset="./../../assets/images/guide/oxr/how-to-use/{{number}}-{{ currentLanguage$ | async }}.png 2x" />
      </div>
    }
  </section>
</ng-template>
<ng-template #oxrSideMenu>
  <h1 class="title">{{ 'guide.oxr.sideMenu.title' | translate }}</h1>
  <section class="content-container">
    @for (number of ['01', '02', '03', '04', '05', '06', '07']; track number) {
      <div class="content">
        <p class="step">
          MENU <span>{{ number }}</span>
        </p>
        <p class="content-title" [innerHTML]="'guide.oxr.sideMenu.' + number | translate"></p>
        <img class="screenshot" srcset="./../../assets/images/guide/oxr/side-menu/{{number}}-{{ currentLanguage$ | async }}.png 2x" />
      </div>
    }
  </section>
</ng-template>
<ng-template #oxrRightPanel>
  <h1 class="title">{{ 'guide.oxr.rightPanel.title1' | translate }}</h1>
  <section class="content-container">
    @for (number of ['01', '02']; track number) {
      <div class="content">
        <p class="step">
          STEP <span>{{ number }}</span>
        </p>
        <p class="content-title" [innerHTML]="'guide.oxr.rightPanel.' + number | translate"></p>
        <img
          class="screenshot"
          srcset="./../../assets/images/guide/oxr/right-panel/step-{{number}}-{{ currentLanguage$ | async }}.png 2x"
        />
      </div>
    }
  </section>
  <hr class="horizontal-line" />
  <h1 class="title">{{ 'guide.oxr.rightPanel.title2' | translate }}</h1>
  <section class="content-container">
    @for (number of ['03', '04', '05', '06', '07', '08', '09', '10']; track number) {
      <div class="content">
        <p>
          <span class="step"
            >{{ 'guide.oxr.rightPanel.category.name' | translate }}_<span>{{
              'guide.oxr.rightPanel.category.' + number | translate
            }}</span></span
          >
          @if (number === '09' || number === '10') {
            <span class="comment">{{ 'guide.oxr.rightPanel.' + number + '-comment' | translate }}</span>
          }
        </p>
        <p class="content-title" [innerHTML]="'guide.oxr.rightPanel.' + number | translate"></p>
        <img
          class="screenshot"
          srcset="./../../assets/images/guide/oxr/right-panel/right-panel-{{number}}-{{ currentLanguage$ | async }}.png 2x"
        />
      </div>
    }
  </section>
</ng-template>
<ng-template #oxrToolbar>
  <h1 class="title">{{ 'guide.oxr.toolbar.title1.name' | translate }}</h1>
  <section class="content-container">
    @for (number of ['01', '02']; track number) {
      <div class="content category-step">
        <p class="step">
          STEP <span>{{ number }}</span>
        </p>
        <p class="content-title" [innerHTML]="'guide.oxr.toolbar.title1.' + number | translate"></p>
        <img class="screenshot" srcset="./../../assets/images/guide/oxr/tool-bar/step-{{number}}-{{ currentLanguage$ | async }}.png 2x" />
      </div>
    }
  </section>
  <hr class="horizontal-line" />
  <h1 class="title">{{ 'guide.oxr.toolbar.title2.name' | translate }}</h1>
  <section class="content-container">
    @for (side of ['left', 'right']; track side) {
      <div class="content category-toolbar">
        <p class="step">
          {{ 'guide.oxr.toolbar.title2.category' | translate }} <span>{{ 'guide.oxr.toolbar.title2.' + side | translate }}</span>
        </p>
        <img class="screenshot" srcset="./../../assets/images/guide/oxr/tool-bar/tool-bar-{{side}}-{{ currentLanguage$ | async }}.png 2x" />
      </div>
    }
    @for (number of ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15']; track number) {
      <div class="content category-menu">
        <p class="step">
          MENU <span>{{ number }}</span>
        </p>
        <p class="content-title" [innerHTML]="'guide.oxr.toolbar.title2.' + number | translate"></p>
        @if (!(number === '01' || number === '02' || number === '03' || number === '11' || number === '14' || number === '15')) {
          <img class="screenshot" srcset="./../../assets/images/guide/oxr/tool-bar/menu-{{number}}-{{ currentLanguage$ | async }}.png 2x" />
        }
      </div>
    }
  </section>
</ng-template>
<ng-template #market>
  <h1 class="title">{{ 'guide.market.title' | translate }}</h1>
  <p class="step">1. {{ 'guide.navigateTo' | translate }} https://www.ownxr.com/market/list</p>
  <p class="step">2. {{ 'guide.market.2' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/6b53a047-78a9-4dc2-ac29-eac20c3fc47c/ascreenshot.jpeg?tl_px=0,0&amp;br_px=746,420&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=174,3"
    />
  </p>
  <p class="step">3. {{ 'guide.market.3' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/11bba0f9-0a49-476f-82a3-e4486e6cffe6/ascreenshot.jpeg?tl_px=183,93&amp;br_px=929,513&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=262,139"
    />
  </p>
  <p class="step">4. {{ 'guide.market.4' | translate }}</p>
  <p class="step">5. {{ 'guide.market.5' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/76bd3229-7373-4285-b417-a7ffe4d9973a/ascreenshot.jpeg?tl_px=914,279&amp;br_px=1660,699&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=262,139"
    />
  </p>
  <p class="step">6. {{ 'guide.market.6' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/26cf86fc-5e6f-4d5f-8633-c05aaa4fc484/ascreenshot.jpeg?tl_px=923,424&amp;br_px=1669,844&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=262,139"
    />
  </p>
  <p class="step">7. {{ 'guide.market.7' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/37ea5473-b053-4b17-a405-358eb0f6655b/ascreenshot.jpeg?tl_px=0,89&amp;br_px=746,509&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=9,139"
    />
  </p>
  <p class="step">8. {{ 'guide.market.8' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/d2d5327a-4fa0-46ad-b011-dedeea507f94/ascreenshot.jpeg?tl_px=1173,517&amp;br_px=1919,937&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=438,250"
    />
  </p>
  <p class="step">9. {{ 'guide.market.9' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/6c2530e4-1bb3-43c6-a4e9-2628fc1ddfe8/ascreenshot.jpeg?tl_px=0,175&amp;br_px=746,595&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=10,139"
    />
  </p>
  <p class="step">10. {{ 'guide.market.10' | translate }}</p>
  <br />
</ng-template>
<ng-template #community>
  <h1 class="title">{{ 'guide.community.title' | translate }}</h1>
  <p class="step">1. {{ 'guide.navigateTo' | translate }} https://www.ownxr.com/community/list</p>
  <p class="step">2. {{ 'guide.community.2' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/f48f1513-e98d-44e3-b1d9-610486bd57ff/ascreenshot.jpeg?tl_px=42,0&amp;br_px=788,420&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=262,7"
    />
  </p>
  <p class="step">3. {{ 'guide.community.3' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/2cee61a5-79fe-4e08-9973-8420b18b1170/ascreenshot.jpeg?tl_px=61,363&amp;br_px=807,783&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=262,139"
    />
  </p>
  <p class="step">4. {{ 'guide.community.4' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/97891adc-7de7-448e-bc90-55e564537fa5/ascreenshot.jpeg?tl_px=133,362&amp;br_px=879,782&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=262,139"
    />
  </p>
  <p class="step">5. {{ 'guide.community.5' | translate }}</p>
  <p class="screenshot-container">
    <img
      class="screenshot"
      width="560"
      src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-01-05/b70b639b-530f-4b37-b7fa-1247f12bac28/ascreenshot.jpeg?tl_px=132,362&amp;br_px=878,782&amp;sharp=0.8&amp;width=560.0&amp;wat_scale=50&amp;wat=1&amp;wat_opacity=0.7&amp;wat_gravity=northwest&amp;wat_url=https://colony-labs-public.s3.us-east-2.amazonaws.com/images/watermarks/watermark_default.png&amp;wat_pad=262,139"
    />
  </p>
  <br />
</ng-template>
<ng-template #editorHowToUse>
  <h1 class="title">{{ 'guide.editor.howToUse.title' | translate }}</h1>
  <section class="content-container">
    @for (number of ['01', '02', '03', '04']; track number) {
      <div class="content">
        <p class="step">
          STEP <span>{{ number }}</span>
        </p>
        <p class="content-title" [innerHTML]="'guide.editor.howToUse.' + number | translate"></p>
        <img class="screenshot" srcset="./../../assets/images/guide/editor/how-to-use/{{number}}-{{ currentLanguage$ | async }}.png 2x" />
      </div>
    }
  </section>
</ng-template>
<ng-template #editorEnvironment>
  <h1 class="title">{{ 'guide.editor.environment.title' | translate }}</h1>
  <section class="content-container">
    @for (number of ['01', '02', '03', '04', '05', '06', '07']; track number) {
      <div class="content">
        <p class="step">
          STEP <span>{{ number }}</span>
        </p>
        <p class="content-title" [innerHTML]="'guide.editor.environment.' + number | translate"></p>
        <img class="screenshot" srcset="./../../assets/images/guide/editor/environment/{{number}}-{{ currentLanguage$ | async }}.png 2x" />
      </div>
    }
  </section>
</ng-template>
<ng-template #editorStage>
  <h1 class="title">{{ 'guide.editor.stage.title' | translate }}</h1>
  <section class="content-container">
    @for (number of ['01', '02', '03-1', '03-2', '04', '05', '06', '07']; track number) {
      <div class="content">
        <p class="step">
          STEP <span>{{ number }}</span>
        </p>
        <p class="content-title" [innerHTML]="'guide.editor.stage.' + number | translate"></p>
        <img class="screenshot" srcset="./../../assets/images/guide/editor/stage/{{number}}-{{ currentLanguage$ | async }}.png 2x" />
      </div>
    }
  </section>
</ng-template>
<ng-template #editorObject>
  <h1 class="title">{{ 'guide.editor.object.title' | translate }}</h1>
  <section class="content-container">
    @for (number of ['01', '02', '03-1', '03-2', '03-3', '03-4', '03-5', '04', '05', '06', '07']; track number) {
      <div class="content">
        <p class="step">
          STEP <span>{{ number }}</span>
        </p>
        <p class="content-title" [innerHTML]="'guide.editor.object.' + number | translate"></p>
        <img class="screenshot" srcset="./../../assets/images/guide/editor/object/{{number}}-{{ currentLanguage$ | async }}.png 2x" />
      </div>
    }
  </section>
</ng-template>

<div class="error-page">
  <img [src]="'./../../assets/images/error_img.png'" />
  @if (accountInfo$ | async) {
    <div class="title">{{ 'errorPage.loggedTitle' | translate }}</div>
    <div class="subtitle">{{ 'errorPage.loggedSubtitle' | translate }}</div>
    <div class="btn-group">
      <button button ui-outlined-button [routerLink]="['/']">{{ 'errorPage.mainPage' | translate }}</button>
    </div>
  } @else {
    <div class="title">{{ 'errorPage.title' | translate }}</div>
    <div class="subtitle">{{ 'errorPage.subtitle' | translate }}</div>
    <div class="btn-group">
      <button button ui-outlined-button [routerLink]="['/']">{{ 'errorPage.mainPage' | translate }}</button>
      <button ui-button (click)="loginRedirect()">{{ 'errorPage.login' | translate }}</button>
    </div>
  }
</div>

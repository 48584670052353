<section class="image" (click)="onImageClick()">
  @if (scene.Thumbnail) {
    <img [src]="scene.Thumbnail" />
  }
</section>
@if (!isDescriptionExpanded) {
  <section class="details-collapsed">
    <div class="title">{{ scene.Name }}</div>
    <div class="icon icon-share share" (click)="onShareClick()"></div>
    <div class="thumbnail">
      @if (isCreatedByAdmin || !scene.CreatorUser?.Thumbnail) {
        <div class="icon icon-ownxr"></div>
      }
      @if (!isCreatedByAdmin && scene.CreatorUser?.Thumbnail) {
        <img [src]="scene.CreatorUser?.Thumbnail" />
      }
    </div>
    <div class="username">
      {{ isCreatedByAdmin ? 'OWNXR' : scene.CreatorUser?.Alias ?? 'OWNXR' }}
    </div>
    <div class="description" (click)="toggleDescription()">
      {{ scene.Description }}
    </div>
  </section>
}
@if (isDescriptionExpanded) {
  <section class="details-expanded" (click)="toggleDescription()">
    <div class="description">
      {{ scene.Description }}
    </div>
  </section>
}

import { Account, AccountOptionType } from '@/data/src/lib/models/data/account';
import { AccountService } from '@/data/src/lib/services/account.service';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import isValidDomain from 'is-valid-domain';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClipboardService } from 'ngx-clipboard';
import { DomainMap } from '@/data/src/lib/models/data/domain';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { ListOrderOption } from '@/data/src/lib/models/interfaces/list-order-option';
import { DomainService } from '@/data/src/lib/services/domain.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { DomainType } from '@/data/src/lib/enums/domain';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { UrlService } from '@/data/src/lib/services/url.service';
import { EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
import { environment } from '@/app/src/environments/environment';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';

@UntilDestroy()
@Component({
  selector: 'app-domain-setting-connection',
  templateUrl: './domain-setting-connection.component.html',
  styleUrls: ['./domain-setting-connection.component.scss'],
})
export class DomainSettingConnectionComponent implements OnInit {
  public scenes: XRScene[] = [];
  public endIndex = 16;
  selectedScene: XRScene;
  itemsPresent = false;
  loading = true;
  searchText: string;
  domain = '';
  isGuide = false;
  activatedStage = 0;

  orderingOptions: ListOrderOption[] = [
    { value: '-DateCreated', name: 'newest' },
    { value: 'DateCreated', name: 'oldest' },
    { value: '-DateModified', name: 'modifiedDesc' },
    { value: 'DateModified', name: 'modifiedAsc' },
  ];
  orderBy: ListOrderOption = this.orderingOptions[0];

  stages = [
    { stage: 1, icon: 'img-icon-login.png', description: 'stageDescription1', detail: 'domain.guide.stagesDetail1' },
    { stage: 2, icon: 'img-icon-setting.png', description: 'stageDescription2', detail: 'domain.guide.stagesDetail2' },
    { stage: 3, icon: 'img-icon-search.png', description: 'stageDescription3', detail: 'domain.guide.stagesDetail3' },
  ];

  public takenScenes: DomainMap[] = [];
  account: Account | undefined;
  isValid: boolean = false;
  isAvailable: boolean = false;
  isSwear: boolean = false;
  inputTouched = false;
  validationLoading: boolean = false;
  domainToChangeSpace: DomainMap | undefined;
  plan = ScenePlan;

  isEnterprise = false;
  isCollaborator = false;

  environment = environment;

  constructor(
    private _sceneService: SceneService,
    private elementRef: ElementRef,
    private _domainService: DomainService,
    private _clipboardApi: ClipboardService,
    private _router: Router,
    private _accountService: AccountService,
    private _urlservice: UrlService,
    private _pricingPlanService: PricingPlanService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe(async (currentAccountOption) => {
      if (currentAccountOption) {
        this.loading = true;
        this.isEnterprise = currentAccountOption.Type === AccountOptionType.Enterprise;
        this.isCollaborator = currentAccountOption.Role === EnterpriseRole.Collaborator;
        this.scenes = [];
        this.itemsPresent = false;
        const scenes = await this.getData(currentAccountOption.EnterpriseContract?.EnterpriseId);
        this.scenes = scenes;
        this.itemsPresent = !!this.scenes?.length;
        this.loading = false;
      }
    });

    this._domainService.changeSubject$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.domainToChangeSpace = data;
      if (data) {
        this.onReset();
        this.getInputValue(data.Path);
      }
    });

    this._domainService.data$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.takenScenes = data;
    });

    const parent = (this.elementRef.nativeElement as HTMLElement).parentElement;
    if (parent) {
      parent.onscroll = () => {
        this.updateListIndices(parent);
      };
    }
  }

  async getData(enterpriseId: string | undefined) {
    let filterConditions: ((scene: XRScene) => boolean)[] = [(scene: XRScene) => !scene.isExpired];

    const canConnectToDomainInFreeScene =
      this._pricingPlanService.scenePlans!.Free.PublishSpace &&
      this._pricingPlanService.scenePlans!.Free.ConnectOwnXrSubdomain &&
      this._pricingPlanService.scenePlans!.Free.ConnectOwnedDomain;
    const canConnectToDomainInProScene =
      this._pricingPlanService.scenePlans!.Pro.PublishSpace &&
      this._pricingPlanService.scenePlans!.Pro.ConnectOwnXrSubdomain &&
      this._pricingPlanService.scenePlans!.Pro.ConnectOwnedDomain;

    const query = enterpriseId ? `enterpriseId=${enterpriseId}` : '';
    await Promise.all([this._sceneService.get('', query, undefined, true, true), this._domainService.get(enterpriseId)]);

    if (!canConnectToDomainInFreeScene) {
      filterConditions.push((scene: XRScene) => scene.Plan !== ScenePlan.Free);
    }

    if (!canConnectToDomainInProScene) {
      filterConditions.push((scene: XRScene) => scene.Plan !== ScenePlan.Pro);
    }

    const filteredScenes = this._sceneService.getCached().filter((scene) => filterConditions.every((condition) => condition(scene)));

    return Promise.resolve(filteredScenes);
  }

  updateListIndices(element: HTMLElement) {
    const ratio = Math.round(element.clientWidth / element.clientHeight) * 2;
    this.endIndex = Math.max(Math.round(((element.scrollTop + element.clientHeight) / 520) * 1.5) * ratio, this.endIndex);
  }

  setSelectedScene(scene: XRScene) {
    this.selectedScene = scene;
  }

  onReset() {
    this.inputTouched = false;
    this.validationLoading = false;
    this.isValid = true;
    this.isAvailable = true;
    this.isSwear = false;
  }

  async getInputValue(value: string) {
    if (value !== '') {
      this.inputTouched = true;
      this.validationLoading = true;

      this.isSwear = (await this._sceneService.CheckCommentOfInteraction(value)).includes('*');

      if (!this.isSwear) {
        this.isValid = isValidDomain(value);

        if (this.isValid) {
          this.domain = value;
          await this._domainService.validate(this.domain).then((res) => {
            this.isAvailable = this.domainToChangeSpace === undefined ? res.IsAvailable : true;
          });
        }
      }

      this.validationLoading = false;
    }
  }

  async onClick(stage: string) {
    switch (stage) {
      case 'postDomain':
        const url = new URL('https://' + this.domain);
        this.loading = true;

        if (this.domainToChangeSpace) {
          const domain = {
            Path: this.domainToChangeSpace.Path,
            SceneId: this.selectedScene.Id,
            SslEnabled: true,
            DomainType: this.domainToChangeSpace.DomainType,
            Id: this.domainToChangeSpace.Id,
          };
          await this._domainService.put(domain as DomainMap);
        } else {
          const domain = {
            Path: this.domain,
            SceneId: this.selectedScene.Id,
            SslEnabled: true,
            DomainType: DomainType.Owned,
          };
          this._domainService.post(domain as DomainMap);
        }

        this.loading = false;
        this.isGuide = true;

        break;
      case 'nextStage':
        this.activatedStage += 1;
        if (this.activatedStage == 4) {
          this._router.navigate(['domain', 'owned']);
        }
        break;
    }
  }

  onIconClick(value: string) {
    this._clipboardApi.copy(value);
  }

  convertToMobileUrl(url: string): string {
    return this._urlservice.convertToMobileUrl(url);
  }
}

import { Component, Input, NgZone, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { interval, take } from 'rxjs';
import { environment } from '@/app/src/environments/environment';
import { UserRoles } from '@/data/src/lib/enums/user';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { OxrSettingsService } from '@/ui/src/lib/layout/oxr-settings/oxr-settings.service';
import { InformationComponent } from '@/ui/src/lib/modal/information/information.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';

@UntilDestroy()
@Component({
  selector: 'app-oxr-card-description',
  templateUrl: './oxr-card-description.component.html',
  styleUrls: ['./oxr-card-description.component.scss'],
})
export class OxrCardDescriptionComponent implements OnInit {
  @Input() scene!: XRScene;
  @Input() isCollaborator?: boolean;

  scenePlan = ScenePlan;
  isCreatorUserEnterpriseOrAdmin = false;
  baseUrl = environment.redirectURL;

  constructor(
    private _clipboardApi: ClipboardService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _oxrSettingsService: OxrSettingsService,
    private _ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.isCreatorUserEnterpriseOrAdmin =
      this.scene?.CreatorUser?.UserRoles[0]?.Role === UserRoles.Admin ||
      this.scene?.CreatorUser?.UserRoles[0]?.Role === UserRoles.Enterprise;
  }

  onShareClick(): void {
    this._clipboardApi.copy(`${environment.redirectURL}/view/${this.scene.Id}`);
    const modalRef = this._modalService.open(InformationComponent);

    if (modalRef) {
      modalRef.instance.message = this._translateService.instant('shared.information.linkCopied');

      this._ngZone.runOutsideAngular(() => {
        interval(1000)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            if (modalRef) {
              modalRef.instance.close();
              this._ngZone.run(() => {});
            }
          });
      });
    }
  }

  openSettings(): void {
    this._oxrSettingsService.openPanel(this.scene);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MarketService } from 'projects/data/src/lib/services/market.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss'],
})
export class MarketComponent implements OnInit, OnDestroy {
  cartItems$ = this._marketService.cart$.pipe(
    map((items) => {
      return items.length > 99 ? '99+' : items.length;
    }),
  );

  constructor(private _router: Router, private _marketService: MarketService, private _title: Title) {}

  ngOnInit(): void {
    this._title.setTitle('OWNXR | Market');
  }

  ngOnDestroy(): void {
    this._title.setTitle('OWNXR');
  }

  isActive(url: string) {
    return this._router.isActive(`market/${url}`, {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  navigateTo(url: string) {
    this._router.navigate(['market', url]);
  }
}

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'projects/app/src/environments/environment';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { XRScene } from 'projects/data/src/lib/models/data/scene';
import { AccountOption } from '@/data/src/lib/models/data/account';
import { AccountService } from 'projects/data/src/lib/services/account.service';
import { SceneService } from 'projects/data/src/lib/services/scene.service';
import { toMap } from 'projects/data/src/lib/utils/collection';
import { filter } from 'rxjs';
import { PaginationResData } from '@/data/src/lib/services/scene.service';
import { CommunityListSortType } from '@/data/src/lib/enums/sort-type';
import { UrlService } from '@/data/src/lib/services/url.service';

@UntilDestroy()
@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html',
  styleUrls: ['./community-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CommunityListComponent implements OnInit {
  loading = true;
  searchText: string;

  currentPageScenes: XRScene[] = []; // pagination result
  totalPages: number;
  currentPage: number;

  orderingOptions: CommunityListSortType[] = [
    CommunityListSortType.Recommended,
    CommunityListSortType.Viewed,
    CommunityListSortType.Liked,
    CommunityListSortType.Latest,
    CommunityListSortType.Oldest,
  ];
  orderBy: CommunityListSortType = this.orderingOptions[0];

  likedMap = new Map<string, boolean>();

  constructor(
    private _sceneService: SceneService,
    private _router: Router,
    private _accountService: AccountService,
    private _urlService: UrlService,
    private _elRef: ElementRef,
  ) {
    this._router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.searchText = '';
      });

    this._sceneService.likes$.pipe(untilDestroyed(this)).subscribe((likes) => {
      this.likedMap = toMap(likes);
    });

    this._accountService.activeAccount$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this._sceneService.getLikes();
      }
    });
  }

  ngOnInit() {
    this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe((data: AccountOption | null) => {
      if (data) {
        this.searchText = '';
        this.getPublicScenesByPagination(1);
      }
    });
  }

  changeOrderBy(orderBy: CommunityListSortType): void {
    this.orderBy = orderBy;
    this.getPublicScenesByPagination(1);
  }

  viewScene(item: XRScene) {
    this._sceneService.postViews([item.Id]);
    this._urlService.windowOpenWithPlatform(`${environment.redirectURL}/view/${item.Id}`);
  }

  onSearchTriggered() {
    this.currentPageScenes.length = 0;
    this.getPublicScenesByPagination(1);
  }

  getPublicScenesByPagination(pageNum: number, ignoreCondition = true) {
    if (ignoreCondition || this.currentPage !== pageNum) {
      this.loading = true;

      this._sceneService.getPublicScenesByPagination(this.orderBy, pageNum, 20, this.searchText).then((res: PaginationResData) => {
        if (res) {
          this.currentPage = res.PaginationInfo.Page;
          this.totalPages = res.PaginationInfo.TotalPages;
          this.currentPageScenes = res.SceneViewModelList;
          this.loading = false;
        }
      });
    } else {
      this.onScrollTop();
    }
  }

  onScrollTop() {
    this._elRef.nativeElement.parentElement.scroll({ top: 0, behavior: 'smooth' });
  }
}

import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from '@/data/src/lib/models/data/account';
import { AccountService } from '@/data/src/lib/services/account.service';
import { TooltipPosition } from '@/ui/src/lib/directive/tooltip.directive';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { MobileConfirmationComponent } from '@/ui/src/lib/modal/mobile/m-confirmation/m-confirmation.component';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '@/data/src/lib/services/url.service';

@Component({
  selector: 'm-temp-landing',
  templateUrl: './temp-landing.component.html',
  styleUrl: './temp-landing.component.scss',
})
export class TempLandingComponent {
  @HostBinding('class.landing') get applyLandingHostStyles(): boolean {
    return !this.isMappedDomain;
  }

  account?: Account;
  tooltipPosition = TooltipPosition;
  isMappedDomain = this._urlService.urlTransformResult.isMappedDomain;

  constructor(
    private _modalService: ModalService,
    private _accountService: AccountService,
    private _translateService: TranslateService,
    private _urlService: UrlService,
    private _router: Router,
  ) {
    this.account = this._accountService.account;
  }

  ngOnInit() {}

  async loginRedirect() {
    const message = this._modalService.open(MobileConfirmationComponent);
    message.instance.body = this._translateService.instant('tempLandingPage.confirmModalDescription');
    message.instance.confirmAction = 'shared.confirmation.confirm';
    message.instance.showCancelAction = false;

    if (await message.result) {
      this.account ? this._router.navigate(['community']) : this._accountService.loginRedirect();
    }
  }

  goToCommunity() {
    this._router.navigate(['community']);
  }
}

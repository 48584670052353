import { ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { JoystickManagerOptions, JoystickOutputData } from 'nipplejs';
import { RouteParam } from '@/data/src/lib/enums/route-param';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { LocalStorageService } from '@/data/src/lib/services/local-storage.service';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApplicationService } from '@/view/src/app/app.service';
import { LocalStorageKeys } from '@/data/src/lib/enums/storage-keys';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { MobileInformationComponent } from '@/ui/src/lib/modal/mobile/m-information/m-information.component';
import { UrlService } from '@/data/src/lib/services/url.service';
import { ViewMode } from '@/data/src/lib/view-manager';
import { environment } from '@/mobile/src/environments/environment';
import { take, interval, takeWhile, finalize } from 'rxjs';
import { JoystickComponent, JoystickEvent } from '@/ui/src/lib/components/joystick/joystick.component';

@UntilDestroy()
@Component({
  selector: 'm-community-view',
  templateUrl: './community-view.component.html',
  styleUrls: ['./community-view.component.scss'],
})
export class CommunityViewComponent implements OnInit, OnDestroy {
  scene: XRScene | undefined;
  showGuide = true;
  supportsXR = false;
  private sceneId: string;
  private elem: any;
  private isFullScreen = false;
  plan = ScenePlan;

  favIcon: HTMLLinkElement | null = this._document.querySelector('#appIcon');
  @ViewChild('staticJoystick') staticJoystick: JoystickComponent;
  @ViewChild('dynamicJoystick') dynamicJoystick: JoystickComponent;

  staticOptions: JoystickManagerOptions = {
    mode: 'static',
    multitouch: true,
    position: { left: '50%', top: '50%' },
  };
  dynamicOptions: JoystickManagerOptions = {
    mode: 'dynamic',
    multitouch: true,
    position: { left: '50%', top: '50%' },
  };

  staticOutputData: JoystickOutputData;
  dynamicOutputData: JoystickOutputData;
  os: string;

  constructor(
    private _sceneService: SceneService,
    private _appService: ApplicationService,
    private _router: Router,
    private _activedRoute: ActivatedRoute,
    private _title: Title,
    private _localStorageService: LocalStorageService,
    private _cd: ChangeDetectorRef,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _deviceService: DeviceDetectorService,
    private _urlService: UrlService,
    private _ngZone: NgZone,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
    this.os = this._deviceService.os;
    let showGuide = this._localStorageService.getItem(LocalStorageKeys.SHOW_MOBILE_COMMUNITY_GUIDE);
    if (showGuide !== 'false') {
      this._localStorageService.setItem(LocalStorageKeys.SHOW_MOBILE_COMMUNITY_GUIDE, 'true');
      this.showGuide = true;
    } else {
      this.showGuide = false;
      if (this._translateService.store.translations[this._translateService.currentLang]) {
        this.showLandscapeWarning();
      } else {
        this._translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
          this.showLandscapeWarning();
        });
      }
    }

    this._urlService.urlTransformResult$.pipe(untilDestroyed(this)).subscribe(async (urlTransformResult) => {
      if (!urlTransformResult.isInitialValue) {
        await this._activedRoute.paramMap.pipe(take(1)).subscribe(async (paramMap) => {
          this.sceneId = paramMap.get(RouteParam.Scene) ?? this._urlService.urlTransformResult.sceneId;

          if (!this.sceneId) {
            this._router.navigateByUrl(environment.redirectURL + '/community');
            return;
          }

          try {
            this.scene = await this._sceneService.getPublishedVersion(this.sceneId);
            if (!this.scene) {
              this._router.navigate(['error']);
              return;
            }

            // this.favIcon!.href = this.scene.Favicon ? this.scene.Favicon : '../../assets/oxr-favicon.png';
            this._title.setTitle(this.scene.Plan === ScenePlan.Free ? 'OWNXR | ' + this.scene.Name : this.scene.Name);
            this._cd.markForCheck();
          } catch (err) {
            this._router.navigate(['error']);
          }
        });
      }
    });

    this._appService.xrSupportSubject.pipe(untilDestroyed(this)).subscribe((supportsXR) => {
      this.supportsXR = supportsXR;
    });
  }

  get isPortrait() {
    this._deviceService.setDeviceInfo(); // update info
    return this._deviceService.orientation === 'portrait';
  }

  get scenePlan() {
    return this.scene?.Plan;
  }

  ngOnInit(): void {
    this.elem = this._document.documentElement;
  }

  ngOnDestroy(): void {
    this._title.setTitle('OWNXR');
    this._modalService.close(false, this['modalContainerRef']);
    // this.favIcon!.href = '../../assets/oxr-favicon.png';
  }

  onGuideFinished() {
    this.showGuide = false;
    this.showLandscapeWarning();
  }

  showLandscapeWarning() {
    if (this.isPortrait) {
      const warningModal = this._modalService.open(MobileInformationComponent);
      if (warningModal) {
        warningModal.instance.message = this._translateService.instant('shared.information.plsLandscape');

        this._ngZone.runOutsideAngular(() => {
          interval(500)
            .pipe(
              takeWhile(() => warningModal && this.isPortrait),
              finalize(() => {
                this._modalService.close(true, warningModal.modalContainerRef);
                this._ngZone.run(() => {});
              }),
              untilDestroyed(this),
            )
            .subscribe(() => {});
        });
      }
    }
  }

  setVRMode() {
    this._appService.setViewMode(ViewMode.VR);
  }

  /**
   * @param event
   *
   * xPos: {{ staticOutputData.position.x | number: '3.2-2' }}
   * yPos: {{ staticOutputData.position.y | number: '3.2-2' }}
   * frontXPos: {{ staticOutputData.instance.frontPosition.x | number: '2.5-5' }}
   * frontYPos: {{ staticOutputData.instance.frontPosition.y | number: '2.5-5' }}
   * force: {{ staticOutputData.force }}
   * angle-deg: {{ staticOutputData.angle.degree }}
   * angle-rad: {{ staticOutputData.angle.radian }}
   * direction: {{ staticOutputData.direction ? staticOutputData.direction.angle : '' }}
   * distance: {{ staticOutputData.distance }}
   */
  onMoveStatic(event: JoystickEvent) {
    this.staticOutputData = event.data;
    const force = Math.min(event.data.force, 2);
    this._appService.walk(event.data.vector.x * force, event.data.vector.y * force);
  }

  onLookStart(event: JoystickEvent) {
    this._appService.lookStart(event);
  }

  onLook(event: JoystickEvent) {
    // this.isLocked = event.data.distance > 3;
    this.dynamicOutputData = event.data;
    this._appService.look(event);
  }

  onLookEnd() {
    this._appService.lookEnd();
  }

  onJump() {
    this._appService.jump();
  }

  stopMove() {
    this._appService.walk(0, 0);
  }

  onExit() {
    this._router.navigate(['']);
  }

  toggleFullScreen() {
    if (this.isFullScreen) {
      this.closeFullscreen();
      this.isFullScreen = false;
    } else {
      this.openFullscreen();
      this.isFullScreen = true;
    }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (this._document.exitFullscreen) {
      this._document.exitFullscreen();
    }
  }
}

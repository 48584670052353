<ui-layout>
  <ui-side-bar>
    <ui-side-bar-item
      icon="icon-collection"
      text="sidenav.viewAll"
      [selected]="isActive('list')"
      (click)="navigateTo('list')"
    ></ui-side-bar-item>
    <ui-side-bar-item
      icon="icon-like"
      text="sidenav.favorite"
      [selected]="isActive('liked')"
      (click)="navigateTo('liked')"
    ></ui-side-bar-item>
    <ui-side-bar-item
      icon="icon-cart"
      text="sidenav.cart"
      [badge]="cartItems$ | async"
      [selected]="isActive('cart')"
      (click)="navigateTo('cart')"
    ></ui-side-bar-item>
    <ui-side-bar-item
      icon="icon-owned"
      text="sidenav.owned"
      [selected]="isActive('owned')"
      (click)="navigateTo('owned')"
    ></ui-side-bar-item>
  </ui-side-bar>
  <ui-body>
    <router-outlet></router-outlet>
  </ui-body>
</ui-layout>

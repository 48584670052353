<ui-search-bar [(searchText)]="searchText"></ui-search-bar>

<ui-search-filter [tags]="tags" (tagsChecked)="setCheckedTags($event)">
  <ng-select
    class="small"
    [searchable]="false"
    [clearable]="false"
    placeholder="{{ 'shared.list.orderBy.placeholder' | translate }}"
    [ngModel]="orderBy"
    (ngModelChange)="changeOrderBy($event)"
  >
    @for (option of orderingOptions; track option) {
      <ng-option [value]="option">
        <div class="select-option-wrapper">
          {{ 'shared.list.orderBy.' + option.name | translate }}
        </div>
      </ng-option>
    }
  </ng-select>
</ui-search-filter>
@if (!loading) {
  <div class="market-list__checkbox_group">
    <ui-check-box
      class="selection-checkbox primary sm"
      [checked]="isSelectedAll"
      (checkedChange)="toggleSelectAll($event)"
      [disabled]="!filteredIds.length"
      >{{ 'shared.fields.selectAll' | translate }}</ui-check-box
    >
  </div>
  @if (
    (
      assets
      | filterBy: ['Id'] : ngPipeIds
      | filterBy: ['Type'] : checkedTags
      | filterBy: ['DisplayName', 'Name', 'Description', 'Tags', 'Type', 'Username'] : (searchText | removeComma | removeHash) : false
    )?.length
  ) {
    <ui-card-list>
      @for (
        asset of assets
          | filterBy: ['Id'] : ngPipeIds
          | filterBy: ['Type'] : checkedTags
          | filterBy: ['DisplayName', 'Name', 'Description', 'Tags', 'Type', 'Username'] : (searchText | removeComma | removeHash) : false
          | orderByImpure: orderBy.value;
        track asset
      ) {
        <ui-display-card
          [imageSrc]="asset.Thumbnail"
          [canSelect]="true"
          [isSelected]="isSelected(asset.Id)"
          [id]="asset.Id"
          (selected)="onSelected($event, asset.Id)"
          (imageClick)="openPreview(asset)"
        >
          <app-market-card-description
            [marketItem]="asset"
            [isLiked]="liked.includes(asset.Id)"
            [isInCart]="true"
          ></app-market-card-description>
        </ui-display-card>
      }
      <section class="purchase-container">
        <button ui-button [disabled]="selectedItems.length < 1" (click)="purchase()">
          @if (isLoadingPurchase) {
            <ui-loader sm [loaderColor]="'white'"></ui-loader>
          }
          @if (!isLoadingPurchase) {
            <span>{{ 'market.purchase' | translate }}</span>
          }
        </button>
        <span class="total-price">{{ selectedItemsPrice | number: '2.2-2' }}</span>
        <span class="total-amount">{{ 'market.totalAmount' | translate }} ({{ selectedItems.length }})</span>
      </section>
    </ui-card-list>
  } @else {
    <div class="empty-list">
      <img src="./../../assets/images/cart.png" />
      <div class="description" [innerHtml]="'market.noCartItems' | translate: { username: username }"></div>
    </div>
  }
  <ng-template #empty>
    <div class="empty-list">
      <img src="./../../assets/images/cart.png" />
      <div class="description" [innerHtml]="'market.noCartItems' | translate: { username: username }"></div>
    </div>
  </ng-template>
} @else {
  <ui-loader></ui-loader>
}
@if (showPreview) {
  <app-market-card-preview
    [assetPreview]="assetPreview"
    [marketItem]="asset"
    [isLiked]="liked.includes(asset.Id)"
    [isInCart]="true"
    (closeEvent)="onPreviewClose($event)"
    (removedFromCart)="showPreview = false"
    (selectTagEvent)="onClickTag($event)"
  ></app-market-card-preview>
}

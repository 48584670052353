import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Account } from 'projects/data/src/lib/models/data/account';
import { XRAsset } from 'projects/data/src/lib/models/data/asset';
import { AccountOption } from '@/data/src/lib/models/data/account';
import { MarketItem } from 'projects/data/src/lib/models/data/market';
import { ListOrderOption } from 'projects/data/src/lib/models/interfaces/list-order-option';
import { AccountService } from 'projects/data/src/lib/services/account.service';
import { MarketService } from 'projects/data/src/lib/services/market.service';
import { combineLatest, filter, Subject, take, takeUntil } from 'rxjs';
import { v4 as uuid } from 'uuid';

@UntilDestroy()
@Component({
  selector: 'app-market-cart-list',
  templateUrl: './market-cart-list.component.html',
  styleUrls: ['./market-cart-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MarketCartListComponent implements OnInit, OnDestroy {
  username: string;
  loading = true;
  isLoadingPurchase = false;
  searchText = '';
  tags: string[] = ['Environment', 'Landscape', 'Stage', 'Object'];
  checkedTags: string[] = [];

  selectedItems: string[] = [];

  orderingOptions: ListOrderOption[] = [
    { value: '-DateCreated', name: 'newest' },
    { value: 'DateCreated', name: 'oldest' },
    { value: '-Purchases', name: 'mostSold' },
    { value: '-Likes', name: 'mostLiked' },
    { value: '-Tokens', name: 'highestPrice' },
    { value: 'Tokens', name: 'lowestPrice' },
  ];
  orderBy: ListOrderOption = this.orderingOptions[0];

  asset!: MarketItem;
  assets: MarketItem[] = [];
  assetPreview!: XRAsset | undefined;
  showPreview = false;

  liked: string[] = [];
  cart: string[] = [];

  get selectedItemsPrice(): number {
    let price = 0;
    this.selectedItems.forEach((item) => {
      const asset = this.assets.find((asset) => asset.Id === item);
      if (asset) {
        price = price + +asset.Tokens;
      }
    });
    return price;
  }

  private _destroy$ = new Subject<void>();

  constructor(
    private _marketService: MarketService,
    private _accountService: AccountService,
    private _toastr: ToastrService,
    private _translateService: TranslateService,
  ) {
    this._marketService.likes$.pipe(takeUntil(this._destroy$)).subscribe((likes) => {
      this.liked = likes;
    });

    this._marketService.cart$.pipe(takeUntil(this._destroy$)).subscribe((cart) => {
      this.cart = [...cart];
      this.selectedItems = this.cart;
    });
    this._accountService.activeAccount$
      .pipe(
        filter((account) => !!account),
        take(1),
      )
      .subscribe((res: Account | undefined) => {
        if (res) {
          this.username = res.Username;
        }
        combineLatest([this._marketService.getPublicItems(), this._marketService.getCart(), this._marketService.getLikes()])
          .pipe(take(1))
          .subscribe(([items]) => {
            this.loading = false;
            this.assets = items;
          });
      });
  }

  ngOnInit() {
    this.toggleSelectAll(true);

    this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe((data: AccountOption | null) => {
      if (data) {
        this.searchText = '';
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get ngPipeIds() {
    // added fake id - filter pipe returns full array if no items to filter by
    return [...this.cart, 'fakeId'];
  }

  get filteredIds() {
    const Ids = this.assets
      .filter((item) => this.cart.includes(item.Id) && (!this.checkedTags.length || this.checkedTags.includes(item.Type)))
      .map((i) => i.Id);
    return Ids;
  }

  get isSelectedAll() {
    const Ids = [...this.filteredIds];
    const condition = !!this.selectedItems.length && Ids.every((i) => this.selectedItems.includes(i));
    return condition;
  }

  toggleSelectAll(value) {
    this.selectedItems = [];
    value && this.selectedItems.push(...this.filteredIds);
  }

  setCheckedTags(tags: string[]) {
    if (this.isSelectedAll) {
      this.selectedItems.length = 0;
    }
    this.checkedTags = [...tags];
    if (tags.includes('Environment')) {
      this.checkedTags.push('Background');
    } else {
      this.checkedTags = this.checkedTags.filter((tag) => tag !== 'Background');
    }
  }

  changeOrderBy(orderBy: ListOrderOption): void {
    this.orderBy = orderBy;
  }

  isSelected(id: string): boolean {
    return this.selectedItems.includes(id);
  }

  onSelected(value: boolean, id: string): void {
    if (value) {
      this.selectedItems.push(id);
    } else {
      this.selectedItems = this.selectedItems.filter((item) => item !== id);
    }
  }

  purchase(): void {
    const currentAccountOption = this._accountService.getCurrentAccount();
    this.isLoadingPurchase = true;
    this._marketService.purchase(uuid(), this.selectedItems, currentAccountOption?.EnterpriseContract?.EnterpriseId).then((result) => {
      if (result) {
        const message = this._translateService.instant('shared.information.purchaseSuccess');
        this._toastr.info(message, '');
        this._marketService.getCart();
      } else {
        const message = this._translateService.instant('shared.information.purchaseFail');
        this._toastr.info(message, '');
      }

      this.isLoadingPurchase = false;
    });
  }

  async openPreview(marketItem: MarketItem) {
    // this.assetPreview = await this._marketService.preview(marketItem.Id);
    this.asset = marketItem;
    await this._marketService.postViews([marketItem]);
    this.showPreview = true;
  }

  onPreviewClose(event) {
    this.showPreview = event;
  }

  onClickTag(event: string) {
    if (event.startsWith('#')) {
      event = event.replace('#', '');
    }
    this.searchText = event.trim();
  }
}

import { ChangeDetectionStrategy, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageKeys } from '@/data/src/lib/enums/storage-keys';
import { LocalStorageService } from '@/data/src/lib/services/local-storage.service';

@Component({
  selector: 'm-community-view-control-guide',
  templateUrl: './community-view-control-guide.component.html',
  styleUrls: ['./community-view-control-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityViewControlGuideComponent implements OnInit {
  dontShowAgain = false;
  pageNumber = 1;
  os = this._deviceService.os;

  @Output() finished = new EventEmitter<void>();

  constructor(private _router: Router, private _localStorageService: LocalStorageService, private _deviceService: DeviceDetectorService) {}

  get isPortrait() {
    this._deviceService.setDeviceInfo(); // update info
    return this._deviceService.orientation === 'portrait';
  }

  async ngOnInit() {}

  async navigatPage(p) {
    this.pageNumber += p;

    if (this.pageNumber > 3) {
      this.pageNumber = 3;
      this.dontShowAgain ? 'false' : 'true';
      this._localStorageService.setItem(LocalStorageKeys.SHOW_MOBILE_COMMUNITY_GUIDE, this.dontShowAgain ? 'false' : 'true');
      this.finished.emit();
    }
  }
}

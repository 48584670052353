import { ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/app/src/environments/environment';
import { MarketItem } from 'projects/data/src/lib/models/data/market';
import { MarketService } from 'projects/data/src/lib/services/market.service';
import { toastConfigExtension } from 'projects/ui/src/lib/components/toast/toast-config-extension';
import { InformationComponent } from 'projects/ui/src/lib/modal/information/information.component';
import { ModalService } from 'projects/ui/src/lib/modal/modal.service';
import { Subject, interval, take, takeUntil } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-market-card-description',
  templateUrl: './market-card-description.component.html',
  styleUrls: ['./market-card-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MarketCardDescriptionComponent implements OnInit, OnDestroy {
  @Input() marketItem!: MarketItem;
  @Input() isLiked!: boolean | undefined;
  @Input() isInCart!: boolean | undefined;
  @Input() isPurchased!: boolean;

  private _destroy = new Subject<void>();

  constructor(
    private _marketService: MarketService,
    private _clipboardApi: ClipboardService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _toastr: ToastrService,
    private _router: Router,
    private _ngZone: NgZone,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  toggleLike() {
    let message = '';

    if (this.isLiked) {
      this._marketService.deleteLike(this.marketItem.Id).then(() => {
        this.marketItem.Likes = this.marketItem.Likes - 1;
        message = this._translateService.instant('shared.information.removedLike');
        this._toastr.info(message, '', toastConfigExtension({ imageSrc: this.marketItem.Thumbnail }));
      });
    } else {
      this._marketService.postLikes([this.marketItem.Id]).then(() => {
        this.marketItem.Likes = this.marketItem.Likes + 1;
        message = this._translateService.instant('shared.information.savedLike');
        const actionLabel = this._translateService.instant('shared.actions.checkNow');
        this._toastr
          .info(
            message,
            '',
            toastConfigExtension({
              enableHtml: true,
              imageSrc: this.marketItem.Thumbnail,
              showExtraAction: true,
              extraActionLabel: actionLabel,
            }),
          )
          .onAction.pipe(takeUntil(this._destroy))
          .subscribe(() => {
            this._router.navigate(['/', 'market', 'liked']);
          });
      });
    }
  }

  toggleCart(): void {
    let message;

    if (this.isInCart) {
      this._marketService.deleteCart(this.marketItem.Id).then(() => {
        message = this._translateService.instant('shared.information.removedFromCart');
        this._toastr.info(message, '', toastConfigExtension({ imageSrc: this.marketItem.Thumbnail }));
      });
    } else {
      this._marketService.postCart([this.marketItem.Id]).then(() => {
        message = this._translateService.instant('shared.information.addedToCart');
        const actionLabel = this._translateService.instant('shared.actions.checkNow');
        this._toastr
          .info(
            message,
            '',
            toastConfigExtension({
              enableHtml: true,
              imageSrc: this.marketItem.Thumbnail,
              showExtraAction: true,
              extraActionLabel: actionLabel,
            }),
          )
          .onAction.pipe(takeUntil(this._destroy))
          .subscribe(() => {
            this._router.navigate(['/', 'market', 'cart']);
          });
      });
    }
  }

  onShareClick(): void {
    this._clipboardApi.copy(`${environment.redirectURL}/market/list/shared?assetId=${this.marketItem.AssetId}`);
    const modalRef = this._modalService.open(InformationComponent);

    if (modalRef) {
      modalRef.instance.message = this._translateService.instant('shared.information.linkCopied');

      this._ngZone.runOutsideAngular(() => {
        interval(1000)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            if (modalRef) {
              modalRef.instance.close();
              this._ngZone.run(() => {});
            }
          });
      });
    }
  }
}

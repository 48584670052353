import { IndividualConfig } from 'ngx-toastr';

export interface IndividualConfigExtended extends IndividualConfig {
  extraActionLabel: string;
  showExtraAction: boolean;
  toastIcon: string;
  imageSrc: string;
}

/**
 * extend IndividualConfig with custom properties
 * these properties will be available in toast component through {{options.property}}
 */
export const toastConfigExtension = (config: Partial<IndividualConfigExtended>): Partial<IndividualConfig> =>
  config as Partial<IndividualConfig>;

// usage example

// const toastrConfig = toastConfigExtension({
//   extraActionLabel: 'shared.message.alert',
//   showExtraAction: true,
// });

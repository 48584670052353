import { StripeSubscriptionState } from '@/data/src/lib/enums/payment';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { OrderPipe } from 'projects/data/src/lib/pipes/order.pipe';
import { LanguageCode } from 'projects/data/src/lib/services/account.service';
import { getUserPaymentsResult, PaymentStripeService } from 'projects/data/src/lib/services/payment-stripe.service';
import { AccountPanelService } from 'projects/ui/src/lib/layout/account-panel/account-panel.service';
import { takeUntil, Subject, map } from 'rxjs';

export interface purchasedPlan {
  orderId: string;
  plan: ScenePlan;
  expiredDate: Date;
  price: number;
  paymentMethod: string;
  count: number;
}
@Component({
  selector: 'app-oxr-subscription-manage',
  templateUrl: './oxr-subscription-manage.component.html',
  styleUrls: ['./oxr-subscription-manage.component.scss'],
  providers: [OrderPipe, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrSubscriptionManageComponent implements OnInit {
  currentLanguage = LanguageCode.Korean;
  private _destroy = new Subject<void>();
  userPayments: getUserPaymentsResult;
  loading = true;
  stripeSubscriptionState = StripeSubscriptionState;

  constructor(
    private _cd: ChangeDetectorRef,
    private _accountPanelService: AccountPanelService,
    private _paymentStripeService: PaymentStripeService,
    private _orderPipe: OrderPipe,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _datePipe: DatePipe,
  ) {
    this._accountPanelService.currentLang$.pipe(takeUntil(this._destroy)).subscribe((lang) => (this.currentLanguage = lang));
    this._paymentStripeService.getUserPayments().then((result) => {
      if (result.ScenesAndPayments) {
        const orderBy = this._orderPipe.transformInner(result.ScenesAndPayments, 'ScenePlan', 'Expires', true);
        this.userPayments = result;
        this.userPayments.ScenesAndPayments = orderBy;
      }
      this.loading = false;
      this._cd.detectChanges();
    });
  }

  async ngOnInit(): Promise<void> {}

  onResubscribe(index: number) {
    if (
      !(
        this.userPayments.ScenesAndPayments[index].Subscription.status === 'active' &&
        !this.userPayments.ScenesAndPayments[index].Subscription.cancel_at_period_end
      )
    ) {
      this._paymentStripeService.RenewSubscription(this.userPayments.ScenesAndPayments[index].Subscription.id).then((res) => {
        if (res) {
          const default_payment_method = this.userPayments.ScenesAndPayments[index].Subscription.default_payment_method;
          this.userPayments.ScenesAndPayments[index].Subscription = res;
          this.userPayments.ScenesAndPayments[index].Subscription.default_payment_method = default_payment_method;
          this._cd.detectChanges();
        }
      });
    }
  }

  onUnsubscribe(index: number) {
    if (
      this.userPayments.ScenesAndPayments[index].Subscription.status === 'active' &&
      !this.userPayments.ScenesAndPayments[index].Subscription.cancel_at_period_end
    ) {
      const modal = this._modalService.open(ConfirmationComponent);
      modal.instance.title = this._translateService.instant('shared.confirmation.notice');
      modal.instance.body = this.userPayments.ScenesAndPayments[index].Subscription.discount
        ? this._translateService.instant('oxr.subscription.guide2')
        : this._translateService.instant('oxr.subscription.guide1', {
            expiredDate: this._datePipe.transform(
              this.getDateWithSeconds(
                this.userPayments.ScenesAndPayments[index].Subscription.cancel_at
                  ? this.userPayments.ScenesAndPayments[index].Subscription.canceled_at
                  : this.userPayments.ScenesAndPayments[index].Subscription.current_period_end,
              ),
              'YYYY.MM.dd',
            ),
          });
      modal.instance.confirmAction = 'oxr.subscription.cancel';
      modal.instance.cancelAction = 'shared.confirmation.cancel';
      modal.result.then((result) => {
        if (result) {
          this._paymentStripeService
            .cancelSubscription(
              this.userPayments.ScenesAndPayments[index].Subscription.id,
              this.userPayments.ScenesAndPayments[index].Subscription.discount ? true : false,
            )
            .then((res) => {
              const default_payment_method = this.userPayments.ScenesAndPayments[index].Subscription.default_payment_method;
              this.userPayments.ScenesAndPayments[index].Subscription = res;
              this.userPayments.ScenesAndPayments[index].Subscription.default_payment_method = default_payment_method;
              this._cd.detectChanges();
            });
        }
      });
    }
  }

  returnNextPaymentDate(current_period_end: number) {
    const date = this.getDateWithSeconds(current_period_end);
    date.setDate(date.getDate() + 1);
    return date;
  }

  returnDiscountAmount(index: number) {
    if (this.userPayments.ScenesAndPayments[index].Subscription.discount) {
      const amount = this.userPayments.ScenesAndPayments[index].ScenePlanPurchase.Amount;
      const discount = amount * (this.userPayments.ScenesAndPayments[index].Subscription.discount.coupon.percent_off / 100);
      return amount - discount;
    } else {
      return this.userPayments.ScenesAndPayments[index].ScenePlanPurchase.Amount;
    }
  }

  getDateWithSeconds(seconds: number) {
    var t = new Date(1970, 0, 1);
    t.setSeconds(seconds);
    return t;
  }
}

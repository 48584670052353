import { Component, Inject, OnInit } from '@angular/core';
import { AccountService } from '@/data/src/lib/services/account.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  currentLanguage$ = this._accountService.language$;

  constructor(
    private _accountService: AccountService,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  ngOnInit(): void {}

  onClickLink(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (target.tagName === 'LI') {
      const scrollTarget = this._document.getElementsByClassName(target.className)[1];
      scrollTarget.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DataModule } from 'projects/data/src/public-api';
import { UiModule } from 'projects/ui/src/public-api';
import { MobileSharedModule } from '../shared.module';
import { CommunityItemComponent } from './community-list/community-item/community-item.component';
import { CommunityListComponent } from './community-list/community-list.component';
import { CommunityViewControlGuideComponent } from './community-view-control-guide/community-view-control-guide.component';
import { CommunityViewComponent } from './community-view/community-view.component';
import { CommunityComponent } from './community.component';
import { MobileComponentsModule } from '../components/m-components.module';
import { ViewModule } from '@/view/src/app/module/view.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [UiModule, ViewModule, MobileSharedModule, DataModule, RouterModule, MobileComponentsModule, ScrollingModule],
  declarations: [
    CommunityComponent,
    CommunityViewControlGuideComponent,
    CommunityListComponent,
    CommunityItemComponent,
    CommunityViewComponent,
  ],
  exports: [CommunityComponent, CommunityViewControlGuideComponent, CommunityListComponent, CommunityItemComponent, CommunityViewComponent],
})
export class MobileCommunityModule {}

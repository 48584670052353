import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventType, InteractionStatus } from '@azure/msal-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, forkJoin, take } from 'rxjs';
import * as uuid from 'uuid';
import { BetaWelcomeComponent } from '@/ui/src/lib/modal/beta-welcome/beta-welcome.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { ReleaseLogComponent } from '@/ui/src/lib/modal/release-log/release-log.component';
import { Account } from '@/data/src/lib/models/data/account';
import { AccountService } from '@/data/src/lib/services/account.service';
import { ReleaseLogService } from '@/data/src/lib/services/release-log.service';
import { UserSettingsService } from '@/data/src/lib/services/user-settings.service';
import { LocalStorageService } from '@/data/src/lib/services/local-storage.service';
import { LocalStorageKeys } from '@/data/src/lib/enums/storage-keys';
import { UrlService } from '@/data/src/lib/services/url.service';
import { EnterpriseService } from '@/data/src/lib/services/enterprise.service';
import { syncObservableHandler } from '@/data/src/lib/utils/async-utils';

const guideSettingObject = {
  space: {
    toolsFunctionDisabled: false,
  },
};
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  canShowHeader = false;
  account: Account | null | undefined = undefined;

  isDesktop: boolean;
  isLoading = true;

  constructor(
    private _router: Router,
    private _msalService: MsalService,
    private _msalBroadcastService: MsalBroadcastService,
    private _urlService: UrlService,
    private _accountService: AccountService,
    private _settingsService: UserSettingsService,
    private _modalService: ModalService,
    private _releaseLogService: ReleaseLogService,
    private _enterpriseService: EnterpriseService,
    private _localStorageService: LocalStorageService,
    public vcRef: ViewContainerRef,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  ngOnInit() {
    /**Login */
    this._msalBroadcastService.msalSubject$
      .pipe(
        filter((msal) => msal.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msal.eventType === EventType.LOGIN_SUCCESS),
        untilDestroyed(this),
        take(1),
      )
      .subscribe(async () => {
        const msalAccountInfo = this._msalService.instance.getAllAccounts()[0];
        const msalActiveAccountInfo = this._msalService.instance.getActiveAccount();

        if (msalAccountInfo && !msalActiveAccountInfo) {
          this._msalService.instance.setActiveAccount(msalAccountInfo);
        }
      });

    /**Interaction is complete */
    this._msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        untilDestroyed(this),
        take(1),
      )
      .subscribe(async () => {
        const msalAccountInfo = this._msalService.instance.getAllAccounts()[0];
        const msalActiveAccountInfo = this._msalService.instance.getActiveAccount();

        if (msalAccountInfo && !msalActiveAccountInfo) {
          this._msalService.instance.setActiveAccount(msalAccountInfo);
        }

        //**Handling when not logged in */
        if (!msalAccountInfo) {
          await this._accountService.setInitialLanguage();
          await this._accountService.setRegion();
          this.isLoading = false;
          this.showModal();
          return;
        }

        /**Handling when logged in */
        this.account = await this._accountService.getActiveAccount(true);

        if (!this.account) {
          /**When logging in for the first time after signing up */
          await syncObservableHandler(
            this._accountService.createAccount({
              Username: uuid.v4().slice(0, 19),
              DisplayName: msalAccountInfo.username,
            }),
          ).then((_account) => {
            this.account = _account;
          });
        }

        forkJoin([this._enterpriseService.getOwnedEnterprises(), this._releaseLogService.get(true)])
          .pipe(untilDestroyed(this))
          .subscribe(async ([enterpriseAccounts, releaseLogs]) => {
            await this._accountService.setInitialLanguage();
            await this._accountService.setRegion();

            /* Get guide setting, if no setting - create it */
            const guideSetting = await this._settingsService.getSettingByName('guide', JSON.stringify(guideSettingObject), false);
            /* Get releaseLog settings, if no settings - create them */
            const showReleaseLogSetting = await this._settingsService.getSettingByName('ShowReleaseLogOnEveryLogin', 'true', false);
            const lastSeenReleaseLogSetting = await this._settingsService.getSettingByName(
              'LastSeenReleaseLog',
              '2022-01-01T00:00:00Z',
              false,
            );

            if (
              this.account!.Server &&
              !this._urlService.urlTransformResult.isMappedDomain &&
              ((showReleaseLogSetting.Value === 'true' && releaseLogs?.length) ||
                (new Date().getUTCDay() === 5 &&
                  releaseLogs.some((log) => new Date(log.DisplayDate) > new Date(lastSeenReleaseLogSetting.Value))) ||
                (new Date().getUTCDate() - new Date(lastSeenReleaseLogSetting.Value).getUTCDate() > 6 &&
                  releaseLogs.some((log) => new Date(log.DisplayDate) > new Date(lastSeenReleaseLogSetting.Value))))
            ) {
              if (!this._router.url.includes('success') && !this._router.url.includes('failure')) {
                this._modalService.open(ReleaseLogComponent, { closeOnBackdropClick: false });
              }
            }

            if (!this.account!.Server) {
              // Temporary Page to set server after sign up
              this._router.navigate(['login/finishRegistration']);
              this.isLoading = false;
              return;
            }

            if (this._router.url === '/' && !this._urlService.urlTransformResult.isMappedDomain) {
              this._router.navigate(['oxr']);
            }

            this.showModal();
            this.isLoading = false;
          });
      });

    this._router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.canShowHeader = this._urlService.urlTransformResult.isMappedDomain
          ? false
          : !event.url.includes('login') &&
            !event.url.includes('view') &&
            !(event.url.includes('shared') && event.url.includes('community'));
      }
    });
  }

  showModal() {
    //BetaWelcomeComponent
    if (
      !this._document.location.href.includes('view') &&
      !this._document.location.href.includes('success') &&
      !this._document.location.href.includes('failure')
    ) {
      let showWelcomePopup = this._localStorageService.getItem(LocalStorageKeys.SHOW_DESKTOP_WELCOME);
      if (showWelcomePopup === null) {
        this._localStorageService.setItem(LocalStorageKeys.SHOW_DESKTOP_WELCOME, 'true');
        showWelcomePopup = this._localStorageService.getItem(LocalStorageKeys.SHOW_DESKTOP_WELCOME);
      }

      if (!this._urlService.urlTransformResult.isMappedDomain) {
        if (showWelcomePopup === 'true') {
          const modal = this._modalService.open(BetaWelcomeComponent, { closeOnBackdropClick: false });
          modal.instance.modalContainerRef = modal.modalContainerRef;
        }
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.scss'],
})
export class FailureComponent implements OnInit {
  constructor(private _location: Location) {}

  ngOnInit(): void {}

  onClick() {
    this._location.back();
  }
}

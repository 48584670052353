@if (!loading) {
  @if (domains.length) {
    <div class="data">
      <div class="title">{{ 'domain.myDomain' | translate }}</div>
      <ui-domain-list [domains]="domains"></ui-domain-list>
      @if (!connectingDomains.length) {
        <div class="connection">
          <div class="name">{{ 'domain.domainConnectionStatus' | translate }}</div>
          <div class="contents">{{ 'domain.noneConnectingDomains' | translate }}</div>
        </div>
      }
      @if (connectingDomains.length) {
        @for (connectingDomain of connectingDomains; track connectingDomain) {
          <div class="connection">
            <div class="name">{{ 'domain.domainConnectionStatus' | translate }}</div>
            <div
              class="contents"
              [innerHTML]="
                'domain.connectionStateGuide'
                  | translate: { domain: connectingDomain.Path, date: returnGuideDate(connectingDomain.DateCreated!) | date: 'MM/dd/EEE' }
              "
            ></div>
            <div class="stage-wrapper">
              <div class="stage">
                <div class="wrapper">
                  <div class="icon icon-yes"></div>
                </div>
                <div class="text">{{ 'domain.requestingConnection' | translate }}</div>
              </div>
              <div class="bar"></div>
              <div class="stage">
                @if (connectingDomain.Status === domainStatus.Connecting) {
                  <ui-loader [loaderColor]="'green'"></ui-loader>
                }
                @if (connectingDomain.Status === domainStatus.Connected) {
                  <div class="wrapper">
                    <div class="icon icon-yes"></div>
                  </div>
                }
                <div class="text">{{ 'domain.checkingDomain' | translate }}</div>
              </div>
              @if (connectingDomain.Status === domainStatus.Connecting) {
                <div class="bar-grey"></div>
              }
              @if (connectingDomain.Status === domainStatus.Connected) {
                <div class="bar"></div>
              }
              <div class="stage">
                @if (connectingDomain.Status === domainStatus.Connecting) {
                  <div class="empty"></div>
                }
                <!-- <ui-loader [loaderColor]="'green'" *ngIf="connectingDomain.State === domainStatus.Owned"></ui-loader> -->
                @if (connectingDomain.Status === domainStatus.Connected) {
                  <div class="wrapper">
                    <div class="icon icon-yes"></div>
                  </div>
                }
                <div class="text">{{ 'domain.spaceLinkInProgress' | translate }}</div>
              </div>
            </div>
          </div>
        }
      }
    </div>
  }
  @if (!domains.length) {
    <div class="none-data">
      <div class="title">{{ 'domain.myDomain' | translate }}</div>
      <img src="../../../assets/images/img-icon-document.png" />
      <div class="description">{{ 'domain.noneDomain' | translate }}</div>
      <button ui-button (click)="onClick()">{{ 'domain.findDomain' | translate }}</button>
    </div>
  }
} @else {
  <ui-loader></ui-loader>
}

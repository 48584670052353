import { ChangeDetectionStrategy, HostListener, ElementRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { XRScene } from 'projects/data/src/lib/models/data/scene';
import { SceneService } from 'projects/data/src/lib/services/scene.service';
import { filter } from 'rxjs';
import { Observable } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { MDropdownPosition } from '../../components/m-dropdown/m-dropdown.component';
import { ListOrderOption } from '@/data/src/lib/models/interfaces/list-order-option';

@UntilDestroy()
@Component({
  selector: 'm-community-list',
  templateUrl: './community-list.component.html',
  styleUrls: ['./community-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityListComponent {
  dropdownPositions = MDropdownPosition;
  scenes$: Observable<XRScene[]> = this._sceneService.publicScenes$.pipe(filter((value) => !!value));
  searchControl = new FormControl('');
  sortDropdownOpened = false;
  filterText = '';
  orderingOptions: ListOrderOption[] = [
    { value: ['RecommendedSortOrder', '-Views'], name: 'recommended' },
    { value: '-Views', name: 'mostViewed' },
    { value: '-DateCreated', name: 'newest' },
    { value: 'DateCreated', name: 'oldest' },
    { value: '-Likes', name: 'mostLiked' },
  ];
  orderBy: ListOrderOption = this.orderingOptions[0];

  previousScroll = 0;
  scrollingUp = false;

  @HostListener('scroll', ['$event.target']) onHostScroll(hostEl) {
    if (this.previousScroll !== hostEl.scrollTop) {
      if (hostEl.scrollTop > 0) {
        this.scrollingUp = this.previousScroll > hostEl.scrollTop;
      } else {
        this.scrollingUp = false;
      }
      this.previousScroll = hostEl.scrollTop;
    }
  }

  constructor(
    private _elRef: ElementRef,
    private _sceneService: SceneService,
    private _router: Router,
    private _cd: ChangeDetectorRef,
  ) {
    this._sceneService.getPublicScenes(true);
    this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe((inputValue) => {
      this.filterText = inputValue ?? '';
      this._cd.markForCheck();
    });
  }

  viewScene(sceneId: string) {
    this._router.navigate(['/', 'view', sceneId]);
  }

  onOrderBy(option: ListOrderOption, event: MouseEvent, sort: any) {
    event.stopPropagation();
    this.orderBy = option;
    sort?.click();
  }

  onScrollTop() {
    this._elRef.nativeElement.scroll({ top: 0, behavior: 'smooth' });
    this._cd.detectChanges();
  }
}

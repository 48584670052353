import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, forkJoin, take } from 'rxjs';
import * as uuid from 'uuid';
import { LocalStorageKeys } from '@/data/src/lib/enums/storage-keys';
import { AccountService } from '@/data/src/lib/services/account.service';
import { ReleaseLogService } from '@/data/src/lib/services/release-log.service';
import { UrlService } from '@/data/src/lib/services/url.service';
import { Account } from '@/data/src/lib/models/data/account';
import { syncObservableHandler } from '@/data/src/lib/utils/async-utils';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  canShowHeader = false;
  localStorageKey = LocalStorageKeys;
  isDesktop: boolean;
  isLoading = true;
  account: Account | null | undefined = undefined;

  constructor(
    private _router: Router,
    private _msalService: MsalService,
    private _msalBroadcastService: MsalBroadcastService,
    private _urlService: UrlService,
    private _accountService: AccountService,
    private _releaseLogService: ReleaseLogService,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  ngOnInit() {
    /* Login */
    this._msalBroadcastService.msalSubject$
      .pipe(
        filter((msal) => msal.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msal.eventType === EventType.LOGIN_SUCCESS),
        untilDestroyed(this),
        take(1),
      )
      .subscribe(async () => {
        const msalAccountInfo = this._msalService.instance.getAllAccounts()[0];
        const msalActiveAccountInfo = this._msalService.instance.getActiveAccount();

        if (msalAccountInfo && !msalActiveAccountInfo) {
          this._msalService.instance.setActiveAccount(msalAccountInfo);
        }
      });

    /**Interaction is complete */
    this._msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        untilDestroyed(this),
        take(1),
      )
      .subscribe(async () => {
        const msalAccountInfo = this._msalService.instance.getAllAccounts()[0];
        const msalActiveAccountInfo = this._msalService.instance.getActiveAccount();

        if (msalAccountInfo && !msalActiveAccountInfo) {
          this._msalService.instance.setActiveAccount(msalAccountInfo);
        }

        //**Handling when not logged in */
        if (!msalAccountInfo) {
          await this._accountService.setInitialLanguage();
          await this._accountService.setRegion();
          this.isLoading = false;
          return;
        }

        /**Handling when logged in */
        this.account = await this._accountService.getActiveAccount(true);

        if (!this.account) {
          /**When logging in for the first time after signing up */
          await syncObservableHandler(
            this._accountService.createAccount({
              Username: uuid.v4().slice(0, 19),
              DisplayName: msalAccountInfo.username,
            }),
          ).then((_account) => {
            this.account = _account;
          });
        }

        forkJoin([this._releaseLogService.get(true)])
          .pipe(untilDestroyed(this))
          .subscribe(async ([releaseLogs]) => {
            await this._accountService.setInitialLanguage();
            await this._accountService.setRegion();

            if (!this.account!.Server) {
              // Temporary Page to set server after sign up
              this._router.navigate(['signUp']);
              this.isLoading = false;
              return;
            }

            if (this._router.url === '/' && !this._urlService.urlTransformResult.isMappedDomain) {
              this._router.navigate(['community']);
            }

            this.isLoading = false;
          });
      });

    this._router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.canShowHeader = this._urlService.urlTransformResult.isMappedDomain
          ? false
          : !event.url.includes('view') &&
            !event.url.includes('signUp') &&
            !event.url.includes('termsOfUse') &&
            !event.url.includes('privacyPolicy') &&
            !event.url.includes('subscription') &&
            !event.url.includes('error') &&
            !(event.url.includes('scene') && event.url.includes('community'));
      }
    });
  }
}

<ui-layout>
  <ui-side-bar>
    <ui-side-bar-item
      icon="icon-search"
      text="domain.domainSearch"
      [selected]="isActive('search')"
      (click)="navigateTo('search')"
    ></ui-side-bar-item>
    <ui-side-bar-item
      icon="icon-domain"
      text="domain.myDomain"
      [selected]="isActive('owned')"
      (click)="navigateTo('owned')"
    ></ui-side-bar-item>
    <ui-side-bar-item
      icon="icon-page-connection"
      text="domain.pageConnection"
      [selected]="isActive('connection')"
      (click)="navigateTo('connection')"
    ></ui-side-bar-item>
  </ui-side-bar>
  <ui-body>
    <router-outlet></router-outlet>
  </ui-body>
</ui-layout>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oxr-pricing',
  templateUrl: './oxr-pricing.component.html',
  styleUrls: ['./oxr-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrPricingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

<section class="pricing-table" *ngIf="scenePlans">
  <table>
    <thead>
      <tr class="pricing-header">
        @for (subscriptionPlan of subscriptionPlans; track $index) {
          <th class="header-item">
            @if (subscriptionPlan.title) {
              <div class="header-item-wrapper">
                <p class="icon icon-pricing-plan sm" [ngStyle]="{ backgroundColor: subscriptionPlan.color }"></p>
                <span class="header-title">{{ subscriptionPlan.title }}</span>
              </div>
            }
          </th>
        }
      </tr>
    </thead>

    <tbody>
      @for (subscriptionPlanOption of subscriptionPlanOptions; track $index) {
        <tr class="feature-row">
          <td class="feature-name">
            <div class="feature-name-wrapper">
              <p class="icon sm" [ngClass]="subscriptionPlanOption.icon"></p>
              {{ subscriptionPlanOption.name | translate }}
            </div>
          </td>
          <!-- Display values ​​by plan according to options -->
          @for (subscriptionPlan of subscriptionPlans; track $index) {
            @if (subscriptionPlan.title !== '') {
              <td class="feature-detail">
                @if (returnType(subscriptionPlanOption[subscriptionPlan.title.toLowerCase()]) === 'boolean') {
                  <div class="icon-description-wrapper">
                    <p
                      class="icon"
                      [ngClass]="{
                        'icon-yes': subscriptionPlanOption[subscriptionPlan.title.toLowerCase()] === true,
                        'icon-no': subscriptionPlanOption[subscriptionPlan.title.toLowerCase()] === false
                      }"
                      [ngStyle]="{
                        backgroundColor:
                          subscriptionPlanOption[subscriptionPlan.title.toLowerCase()] === true ? subscriptionPlan.color : 'var(--grey-200)'
                      }"
                    ></p>
                    @if (subscriptionPlanOption[subscriptionPlan.title.toLowerCase()]) {
                      {{
                        subscriptionPlanOption[subscriptionPlan.title.toLowerCase() + 'Description']
                          | translate
                            : {
                                spaceRestoreParameter: getScenePlanProperty(
                                  subscriptionPlan.title === scenePlanEnum.Pro ? scenePlanEnum.Pro : scenePlanEnum.Free,
                                  subscriptionPlanOption.requiredDataProperty
                                )
                              }
                      }}
                    }
                  </div>
                } @else {
                  {{
                    subscriptionPlanOption[subscriptionPlan.title.toLowerCase()]
                      | translate
                        : {
                            useOfUploadedAssetsParameter: getScenePlanProperty(
                              subscriptionPlan.title === scenePlanEnum.Pro ? scenePlanEnum.Pro : scenePlanEnum.Free,
                              subscriptionPlanOption.requiredDataProperty
                            )
                          }
                  }}
                }
              </td>
            }
          }
        </tr>
      }
    </tbody>
  </table>
</section>

<section class="package">
  <div class="header-feature-wrapper">
    <div class="package-header">
      <div class="package-icon-wrapper">
        <p class="package-icon icon icon-pricing-plan" [ngStyle]="{ backgroundColor: subscriptionPlanCard.color }"></p>
        <p class="package-title">{{ subscriptionPlanCard.title | translate }}</p>
      </div>

      <div class="package-price-wrapper">
        <p class="package-price">{{ subscriptionPlanCard.price }}</p>
        <p class="package-price-description">{{ subscriptionPlanCard.priceDescription | translate }}</p>
      </div>
    </div>

    <div class="package-features">
      <article class="feature-item-sub" *ngIf="subscriptionPlanCard.subfeatures">
        <p class="feature-description" [ngStyle]="{ color: subscriptionPlanCard.color }">
          {{ subscriptionPlanCard.subfeatures | translate }}
        </p>
      </article>
      @for (feature of subscriptionPlanCard.features; track $index) {
        <article class="feature-item">
          <div class="feature-icon icon icon-yes xxs" [ngStyle]="{ backgroundColor: subscriptionPlanCard.color }"></div>
          <div class="feature-description">{{ feature | translate }}</div>
        </article>
      }
    </div>
  </div>

  <footer class="package-footer">
    <button
      ui-button
      class="button-description"
      [ngStyle]="{
        backgroundColor: subscriptionPlanCard.title === 'Free' ? '#666F78' : subscriptionPlanCard.color,
        color: subscriptionPlanCard.title === 'Free' ? 'var(--grey-300)' : 'var(--grey-100)'
      }"
      (click)="onButtonClick.emit()"
    >
      {{ subscriptionPlanCard.buttonDescription | translate }}
    </button>
  </footer>
</section>

import { CollaboratorInvitationPayload, EnterpriseRoleNumeric } from '@/data/src/lib/models/data/enterprise';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { EnterpriseService } from '@/data/src/lib/services/enterprise.service';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';

@Component({
  selector: 'app-collaborator-invitation',
  templateUrl: './collaborator-invitation.component.html',
  styleUrls: ['./collaborator-invitation.component.scss'],
})
export class CollaboratorInvitationComponent implements OnInit {
  dialog = false;
  isLoading = false;
  _disabled = false;

  @Input() enterpriseId!: string;
  @Input() set disabled(val: boolean) {
    this._disabled = val;
  }
  @Output() onInvite = new EventEmitter<boolean>();

  options = [
    { value: EnterpriseRoleNumeric.Owner, name: 'owner' },
    { value: EnterpriseRoleNumeric.Collaborator, name: 'collaborator' },
  ];
  type: EnterpriseRoleNumeric = EnterpriseRoleNumeric.Owner;
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private _enterpriseService: EnterpriseService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {}

  onTypeChange(value: EnterpriseRoleNumeric) {
    this.type = value;
  }
  reset() {
    this.type = EnterpriseRoleNumeric.Owner;
    this.form.reset();
  }

  open() {
    this.dialog = true;
  }

  close() {
    this.reset();
    this.dialog = false;
  }

  async invite() {
    this.isLoading = true;
    if (this.form.controls.email.value) {
      const payload: CollaboratorInvitationPayload = {
        enterpriseId: this.enterpriseId,
        role: this.type,
        receiverEmail: this.form.controls.email.value,
      };

      await this._enterpriseService.inviteCollaborator(payload).catch((error: HttpErrorResponse) => {
        if (error.error === 'User is already an enterprise user') {
          const message = this._modalService.open(ConfirmationComponent);
          message.instance.title = this._translateService.instant('shared.confirmation.notice');
          message.instance.body = this._translateService.instant('shared.confirmation.enterpriseUserExists');
          message.instance.confirmAction = 'shared.confirmation.confirm';
          message.instance.showCancelAction = false;
        } else if (error.error === 'The limit for enterprise user is reached!') {
          const message = this._modalService.open(ConfirmationComponent);
          message.instance.title = this._translateService.instant('shared.confirmation.notice');
          message.instance.body = this._translateService.instant('shared.confirmation.enterpriseUserExceed');
          message.instance.confirmAction = 'shared.confirmation.confirm';
          message.instance.showCancelAction = false;
        }
        return;
      });

      this.onInvite.emit(true);
    }
    this.isLoading = false;
    this.close();
  }
}

import { Injectable } from '@angular/core';

import { DataCacheService } from 'projects/data/src/lib/services/data-cache.service';

export interface ExitWithoutSavingInterface {
  dataCache: DataCacheService;
  exitWithoutSaving(): Promise<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class ExitWithoutSavingGuard {
  canDeactivate(component: ExitWithoutSavingInterface): Promise<boolean> | boolean {
    if (!component.dataCache.isDirty) {
      return true;
    }

    return component.exitWithoutSaving();
  }
}

@if (currentPage === firstPage) {
  <div class="icon icon-circle-arrow-left-grey"></div>
}
@if (currentPage !== firstPage) {
  <div class="icon icon-circle-arrow-left" (click)="clickLeftSide()"></div>
}
@if (currentPage === lastPage) {
  <div class="icon icon-circle-arrow-right-grey"></div>
}
@if (currentPage !== lastPage) {
  <div class="icon icon-circle-arrow-right" (click)="clickRightSide()"></div>
}
<div class="scroll-button">
  <div class="icon icon-circle-arrow-up" (click)="scrollTo('top')"></div>
  <div class="icon icon-circle-arrow-down" (click)="scrollTo('bottom')"></div>
</div>
<section class="manual-img-content">
  <img class="manual-img" src="./../../../assets/images/manual/{{ currentPage }}-{{ currentLanguage$ | async }}.png" alt="" />
</section>
<section class="footer">
  <div class="disable-reminder">
    <ui-check-box class="checkbox primary xs" [checked]="isChecked" (checkedChange)="onShowConfirmationModal($event)">
      {{ 'guideModal.dontShowAgain' | translate }}
    </ui-check-box>
  </div>
  <div class="close-manual" (click)="onCloseManual()">
    {{ 'oxr.information.closeManual' | translate }}
    <div class="icon icon-eye-off"></div>
  </div>
</section>

import { privateFieldReplacer } from '../../utils/json-utils';
import { XRAsset } from './asset';
import { IModel, ModelType } from './base';
import { AssetType } from './asset';

/**
 * The history of purchases, likes and views of the active users marketed items
 */
export class MarketHistory {
  constructor(
    public Purchases: { Date: Date; Tokens: number }[],
    public Likes: { Date: Date }[],
    public Views: { Date: Date }[],
    public AssetType: AssetType,
    public MarketId: string,
    public Thumbnail: string,
    public Name: string,
    public NumberOfLikes: string,
  ) {}
}

/**
 * Defines a marketed asset
 */
export class MarketItem implements IModel {
  public _categoryName!: string;
  private _assetType!: AssetType;
  private _likes!: number;
  private _purchases!: number;
  private _views!: number;
  private _length!: number;
  constructor(
    public Id: string,
    public AssetId: string,
    public CategoryId: string,
    public Name: string,
    public Description: string,
    public Tags: string,
    public Tokens: number,
    public Thumbnail: string,
    public DateCreated: string,
    public DateModified: string,
    public Username: string,
    public DisplayName: string,
    public UserThumbnail: string,
    public DisplayToMarket: boolean,
    Type?: AssetType,
    Likes = 0,
    Purchases = 0,
    Views = 0,
    Length = 0,
  ) {
    this._assetType = Type ?? AssetType.Undefined;
    this._likes = Likes;
    this._purchases = Purchases;
    this._views = Views;
    this._length = Length;
  }

  get ModelType(): ModelType {
    return ModelType.Market;
  }

  get Likes(): number {
    return this._likes;
  }

  get Length(): number {
    return this._length;
  }
  get Purchases(): number {
    return this._purchases;
  }
  get Views(): number {
    return this._views;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set Likes(value: number) {
    this._likes = value;
  }

  get Type(): AssetType {
    return this._assetType;
  }

  toJson(): string {
    return JSON.stringify(this, privateFieldReplacer);
  }
  setId(id: string): void {
    this.Id = id;
  }

  /**
   * Creates a market item
   * @param asset asset to market
   * @returns the market item
   */
  static create(asset: XRAsset): MarketItem {
    return new MarketItem('', asset.Id, (asset as any).CategoryId, asset.Name, '', '', 0, asset.Thumbnail, '', '', '', '', '', true);
  }
}

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { UrlService } from '@/data/src/lib/services/url.service';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { OrderPipe } from '@/data/src/lib/pipes/order.pipe';
import { getUserPaymentsResult, PaymentStripeService } from '@/data/src/lib/services/payment-stripe.service';

export interface purchasedPlan {
  orderId: string;
  paymentDate: Date;
  plan: ScenePlan;
  count: number;
  price: number;
  state: string;
}

@Component({
  selector: 'app-oxr-purchase-history',
  templateUrl: './oxr-purchase-history.component.html',
  styleUrls: ['./oxr-purchase-history.component.scss'],
  providers: [OrderPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrPurchaseHistoryComponent implements OnInit {
  userPayments: getUserPaymentsResult;
  loading = true;

  constructor(
    private _cd: ChangeDetectorRef,
    private _router: Router,
    private _paymentStripeService: PaymentStripeService,
    private _orderPipe: OrderPipe,
    private _urlService: UrlService,
  ) {
    this._paymentStripeService.getUserPayments().then((result) => {
      if (result.ScenesAndPayments) {
        const orderBy = this._orderPipe.transformInner(result.ScenesAndPayments, 'ScenePlan', 'Expires', true);
        this.userPayments = result;
        this.userPayments.ScenesAndPayments = orderBy;
      }
      this.loading = false;
      this._cd.detectChanges();
    });
  }

  ngOnInit(): void {}

  onRedirect() {
    this._router.navigate(['oxr', 'subscriptionManagement']);
  }

  onClick(invoiceId: string) {
    this._paymentStripeService.getInvoice(invoiceId).then((res) => {
      this._urlService.windowOpenWithPlatform(res.invoice_pdf);
    });
  }

  getTotalAmount() {
    let totalAmount = 0;
    if (this.userPayments.ScenesAndPayments) {
      this.userPayments.ScenesAndPayments.forEach((e) => {
        if (e.Subscription.status === 'active' && !e.Subscription.cancel_at_period_end) {
          totalAmount += e.ScenePlanPurchase.Amount;
        }
      });
    }
    return totalAmount;
  }

  getDateWithSeconds(seconds: number) {
    var t = new Date(1970, 0, 1);
    t.setSeconds(seconds);
    return t;
  }

  returnDiscountAmount(index: number) {
    if (this.userPayments.ScenesAndPayments[index].Subscription.discount) {
      const amount = this.userPayments.ScenesAndPayments[index].ScenePlanPurchase.Amount;
      const discount = amount * (this.userPayments.ScenesAndPayments[index].Subscription.discount.coupon.percent_off / 100);
      return amount - discount;
    } else {
      return this.userPayments.ScenesAndPayments[index].ScenePlanPurchase.Amount;
    }
  }
}

import { Component } from '@angular/core';
import { SubscriptionPlan, SubscriptionPlanOption } from '@/data/src/lib/models/data/subscription';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';
import { convertBytesToGBOrMBOrKB } from '@/data/src/lib/utils/convert-utils';
import { ScenePlan, ScenePlanDetail } from '@/data/src/lib/enums/pricing-plan';
import { ScenePlanModel } from '@/data/src/lib/models/data/pricing-plan';

@Component({
  selector: 'app-subscription-plan-detail',
  templateUrl: './subscription-plan-detail.component.html',
  styleUrl: './subscription-plan-detail.component.scss',
})
export class SubscriptionPlanDetailComponent {
  scenePlanEnum = ScenePlan;

  scenePlans: ScenePlanModel | undefined = undefined;

  subscriptionPlans: SubscriptionPlan[] = [
    { title: '', color: '' },
    { title: ScenePlan.Free, color: '#82F029' },
    { title: ScenePlan.Pro, color: '#F08400' },
    { title: ScenePlan.Enterprise, color: '#FF4085' },
  ];

  subscriptionPlanOptions: SubscriptionPlanOption[] = [];

  constructor(private _pricingPlanService: PricingPlanService) {
    this.scenePlans = this._pricingPlanService.scenePlans;

    this.subscriptionPlanOptions = [
      {
        icon: 'icon-box',
        name: 'subscriptionPlan.planDetail.numberOfSpaceLimit',
        free: 'subscriptionPlan.planDetail.free.numberOfSpaceLimit',
        pro: 'subscriptionPlan.planDetail.pro.numberOfSpaceLimit',
        enterprise: 'subscriptionPlan.planDetail.enterprise.numberOfSpaceLimit',
      },
      {
        icon: 'icon-plus-square',
        name: 'subscriptionPlan.planDetail.spaceExpirationDate',
        free: '-',
        pro: 'subscriptionPlan.planDetail.pro.spaceExpirationDate',
        enterprise: 'subscriptionPlan.planDetail.enterprise.spaceExpirationDate',
      },
      {
        icon: 'icon-recover',
        name: 'subscriptionPlan.planDetail.spaceRestore',
        free: this.getScenePlanProperty(ScenePlan.Free, ScenePlanDetail.IsSavePointEnabled),
        pro: this.getScenePlanProperty(ScenePlan.Pro, ScenePlanDetail.IsSavePointEnabled),
        enterprise: true,
        freeDescription: 'subscriptionPlan.planDetail.free.spaceRestore',
        proDescription: 'subscriptionPlan.planDetail.pro.spaceRestore',
        enterpriseDescription: 'subscriptionPlan.planDetail.enterprise.spaceRestore',
        requiredDataProperty: ScenePlanDetail.SavepointPeriod,
      },
      {
        icon: 'icon-collaborate',
        name: 'subscriptionPlan.planDetail.concurrentEditing',
        free: false,
        pro: false,
        enterprise: true,
      },
      {
        icon: 'icon-database',
        name: 'subscriptionPlan.planDetail.mediaStorage',
        free: convertBytesToGBOrMBOrKB(this.getScenePlanProperty(ScenePlan.Free, ScenePlanDetail.MediaStorageCapacity)),
        pro: convertBytesToGBOrMBOrKB(this.getScenePlanProperty(ScenePlan.Pro, ScenePlanDetail.MediaStorageCapacity)),
        enterprise: 'subscriptionPlan.planDetail.enterprise.mediaStorage',
      },
      {
        icon: 'icon-image',
        name: 'subscriptionPlan.planDetail.mediaLibrarySharing',
        free: false,
        pro: true,
        enterprise: true,
      },
      {
        icon: 'icon-object',
        name: 'subscriptionPlan.planDetail.useOfUploadedAssets',
        free: this.getScenePlanProperty(ScenePlan.Free, ScenePlanDetail.UploadAssetUsageLimit)
          ? 'subscriptionPlan.planDetail.free.useOfUploadedAssets'
          : true,
        pro: this.getScenePlanProperty(ScenePlan.Pro, ScenePlanDetail.UploadAssetUsageLimit)
          ? 'subscriptionPlan.planDetail.pro.useOfUploadedAssets'
          : true,
        enterprise: true,
        requiredDataProperty: ScenePlanDetail.UploadAssetUsageLimit,
      },
      {
        icon: 'icon-star',
        name: 'subscriptionPlan.planDetail.useContentToSpace',
        free: this.getScenePlanProperty(ScenePlan.Free, ScenePlanDetail.RestrictedContentWithinSpaceUsage)
          ? 'subscriptionPlan.planDetail.free.useContentToSpace'
          : true,
        pro: this.getScenePlanProperty(ScenePlan.Pro, ScenePlanDetail.RestrictedContentWithinSpaceUsage)
          ? 'subscriptionPlan.planDetail.pro.useContentToSpace'
          : true,
        enterprise: true,
      },
      {
        icon: 'icon-tool',
        name: 'subscriptionPlan.planDetail.customizedAsset',
        free: false,
        pro: false,
        enterprise: true,
      },
      {
        icon: 'icon-publish',
        name: 'subscriptionPlan.planDetail.publishSpace',
        free: true,
        pro: true,
        enterprise: true,
      },
      {
        icon: 'icon-message-square',
        name: 'subscriptionPlan.planDetail.liveChat',
        free: true,
        pro: true,
        enterprise: true,
      },
      {
        icon: 'icon-link-2',
        name: 'subscriptionPlan.planDetail.connectSubdomain',
        free: false,
        pro: true,
        enterprise: true,
      },
      {
        icon: 'icon-link-2',
        name: 'subscriptionPlan.planDetail.connectExistingDomain',
        free: false,
        pro: true,
        enterprise: true,
      },
      {
        icon: 'icon-LOGO',
        name: 'subscriptionPlan.planDetail.removeOwnxrAd',
        free: false,
        pro: true,
        enterprise: true,
      },
    ];
  }

  returnType(value: any) {
    return typeof value;
  }

  getScenePlanProperty(scenePlan: ScenePlan, property: ScenePlanDetail | undefined) {
    try {
      return this.scenePlans![scenePlan][property];
    } catch (error) {
      return undefined;
    }
  }
}

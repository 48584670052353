import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, filter, Subject, take, takeUntil } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { XRAsset } from 'projects/data/src/lib/models/data/asset';
import { MarketItem } from 'projects/data/src/lib/models/data/market';
import { AccountOption } from '@/data/src/lib/models/data/account';
import { ListOrderOption } from 'projects/data/src/lib/models/interfaces/list-order-option';
import { AccountService } from 'projects/data/src/lib/services/account.service';
import { MarketService } from 'projects/data/src/lib/services/market.service';
import { ModalService } from 'projects/ui/src/lib/modal/modal.service';
import { Account } from 'projects/data/src/lib/models/data/account';

@UntilDestroy()
@Component({
  selector: 'app-market-liked-list',
  templateUrl: './market-liked-list.component.html',
  styleUrls: ['./market-liked-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MarketLikedListComponent implements OnInit, OnDestroy {
  username: string;
  loading = true;
  searchText = '';
  tags: string[] = ['Environment', 'Landscape', 'Stage', 'Object'];
  checkedTags: string[] = [];

  orderBy: ListOrderOption = { value: '-Likes', name: 'mostLiked' };

  asset!: MarketItem;
  assets: MarketItem[] = [];
  assetPreview!: XRAsset | undefined;
  showPreview = false;

  liked: string[] = [];
  cart: string[] = [];
  owned: string[] = [];

  selectedItems: string[] = [];

  assetFilters: string[] = ['owned', 'notInCart', 'inCart'];
  checkedAssetTags: string[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private _marketService: MarketService,
    private _accountService: AccountService,
    private _modalService: ModalService,
  ) {
    this._marketService.likes$.pipe(takeUntil(this._destroy$)).subscribe((likes) => {
      this.liked = [...likes];
    });

    this._marketService.owned$.pipe(takeUntil(this._destroy$)).subscribe((owned) => {
      this.owned = [...owned];
    });

    this._marketService.cart$.pipe(takeUntil(this._destroy$)).subscribe((cart) => {
      this.cart = [...cart];
    });

    this._accountService.activeAccount$
      .pipe(
        filter((account) => !!account),
        take(1),
      )
      .subscribe((res: Account | undefined) => {
        if (res) {
          this.username = res.Username;

          const currentAccountOption = this._accountService.getCurrentAccount();
          combineLatest([
            this._marketService.getPublicItems(),
            this._marketService.getCart(),
            this._marketService.getLikes(),
            this._marketService.getOwned(currentAccountOption?.EnterpriseContract?.EnterpriseId),
          ])
            .pipe(take(1))
            .subscribe(([items]) => {
              this.assets = items;
              this.loading = false;
            });
        }
      });
  }

  ngOnInit() {
    this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe((data: AccountOption | null) => {
      if (data) {
        this.searchText = '';
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get filteredIds() {
    let idConditionList: string[] = [];

    if (!this.checkedAssetTags.length) {
      idConditionList = [...this.liked];
    } else {
      if (this.checkedAssetTags.includes('notInCart')) {
        const purchasedAndOwned = [...this.cart, ...this.owned];
        idConditionList.push(...this.liked.filter((i) => !purchasedAndOwned.includes(i)));
      }

      if (this.checkedAssetTags.includes('owned')) {
        idConditionList.push(...this.liked.filter((i) => this.owned.includes(i)));
      }

      if (this.checkedAssetTags.includes('inCart')) {
        idConditionList.push(...this.liked.filter((i) => this.cart.includes(i)));
      }
    }
    const Ids = this.assets
      .filter((item) => {
        const filterByIds = idConditionList.includes(item.Id);
        const filterByTypes = !this.checkedTags.length || this.checkedTags.includes(item.Type);
        return filterByIds && filterByTypes;
      })
      .map((i) => i.Id);
    return Ids;
  }

  get ngPipeIds() {
    // added fake id - filter pipe returns full array if no items to filter by
    return [...this.filteredIds, 'fakeId'];
  }

  get isSelectedAll() {
    const Ids = [...this.filteredIds];
    const condition = !!this.selectedItems.length && Ids.every((i) => this.selectedItems.includes(i));
    return condition;
  }

  toggleSelectAll(value) {
    if (value) {
      this.selectedItems = [...this.filteredIds];
    } else {
      this.selectedItems = [];
    }
  }

  setCheckedTags(tags: string[]) {
    if (this.isSelectedAll) {
      this.selectedItems = [];
    }
    this.checkedTags = [...tags];
    if (tags.includes('Environment')) {
      this.checkedTags.push('Background');
    } else {
      this.checkedTags = this.checkedTags.filter((tag) => tag !== 'Background');
    }
  }

  changeOrderBy(orderBy: ListOrderOption): void {
    this.orderBy = orderBy;
  }

  async openPreview(marketItem: MarketItem) {
    // this.assetPreview = await this._marketService.preview(marketItem.Id);
    this.asset = marketItem;
    await this._marketService.postViews([marketItem]);
    this.showPreview = true;
  }

  onPreviewClose(event: boolean) {
    this.showPreview = event;
  }

  onClickTag(event: string) {
    if (event.startsWith('#')) {
      event = event.replace('#', '');
    }
    this.searchText = event.trim();
  }

  onSelected(value: boolean, id: string): void {
    if (value) {
      this.selectedItems.push(id);
    } else {
      this.selectedItems = this.selectedItems.filter((item) => item !== id);
    }
  }

  isSelected(id: string): boolean {
    return this.selectedItems.includes(id);
  }
  setAssetCheckedTags(tags: string[]) {
    if (this.isSelectedAll) {
      this.selectedItems.length = 0;
    }
    this.checkedAssetTags = [...tags];
  }

  resetAllFilters() {
    if (this.isSelectedAll) {
      this.selectedItems.length = 0;
    }
    this.checkedAssetTags = [];
    this.checkedTags = [];
  }
}

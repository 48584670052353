import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentService } from 'projects/data/src/lib/services/payment.service';
import { CardRequestPayment, QueryParams } from 'projects/data/src/lib/models/data/payment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss'],
})
export class PaymentResultComponent implements OnInit {
  private _cardRequestPayment: CardRequestPayment;
  public queryParams: QueryParams;
  public error: HttpErrorResponse;
  public isMatchedAmount = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _paymentService: PaymentService,
    private _router: Router,
    private _ngZone: NgZone,
  ) {
    this._cardRequestPayment = this._paymentService.getCardRequestPayment();

    this._activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = this._paymentService.parsingParams(params);
    });
  }

  ngOnInit(): void {
    if (this.queryParams.amount) {
      if (this.queryParams.amount == this._cardRequestPayment?.Amount) {
        this._paymentService
          .requestPaymentApproval(this.queryParams)
          .then((res) => {
            // Using success result, save the payment data

            this._ngZone.runOutsideAngular(() => {
              interval(1500)
                .pipe(take(1), untilDestroyed(this))
                .subscribe(() => {
                  this._router.navigate([this._cardRequestPayment.RedirectSuccessUrl]);
                  this._ngZone.run(() => {});
                });
            });
          })
          .catch((res) => {
            this.error = res;

            this._ngZone.runOutsideAngular(() => {
              interval(1500)
                .pipe(take(1), untilDestroyed(this))
                .subscribe(() => {
                  this._router.navigate([this._cardRequestPayment.RedirectFailUrl]);
                  this._ngZone.run(() => {});
                });
            });
          });
      } else {
        this.isMatchedAmount = false;
      }
    } else {
      this._ngZone.runOutsideAngular(() => {
        interval(1000)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            this._router.navigate([this._cardRequestPayment.RedirectFailUrl]);
            this._ngZone.run(() => {});
          });
      });
    }
  }
}

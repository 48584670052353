<div class="container">
  <div class="sub-container">
    <div class="subscription">
      <div class="title">{{ 'oxr.subscription.subscription' | translate }}</div>
      @if (!loading) {
        <div class="purchase-summary">
          <div class="left">
            <div class="first-line">
              {{ 'oxr.subscription.subscribedSpace' | translate }}
              <button ui-outlined-button (click)="onRedirect()">{{ 'oxr.subscription.management' | translate }}</button>
            </div>
            <div class="second-line">{{ userPayments.ScenesAndPayments.length }} {{ 'oxr.subscription.ea' | translate }}</div>
          </div>
          <div class="right">
            <div class="first-line">{{ 'oxr.subscription.totalAmount' | translate }}</div>
            <div class="second-line">${{ getTotalAmount() }}</div>
          </div>
        </div>
        <div class="purchase-list">
          <div class="header">
            <div class="col1">{{ 'oxr.subscription.paymentDate' | translate }}</div>
            <div class="col2">{{ 'oxr.subscription.plan' | translate }}</div>
            <div class="col3">{{ 'oxr.subscription.quantity' | translate }}</div>
            <div class="col4">{{ 'oxr.subscription.amountOfPayment' | translate }}</div>
            <div class="col5">{{ 'oxr.subscription.state' | translate }}</div>
            <div class="col6">{{ 'oxr.subscription.downloadInvoice' | translate }}</div>
          </div>
          @for (scenesAndPayment of userPayments.ScenesAndPayments; track scenesAndPayment; let i = $index) {
            <div class="body">
              <div class="col1">{{ getDateWithSeconds(scenesAndPayment.Subscription.start_date) | date: 'YYYY.MM.dd' }}</div>
              <div class="col2">{{ scenesAndPayment.ScenePlan.Plan | uppercase }}</div>
              <div class="col3">{{ 1 }}</div>
              <div class="col4">${{ returnDiscountAmount(i) }}</div>
              <div class="col5">{{ scenesAndPayment.Subscription.status | uppercase }}</div>
              <div class="col6" (click)="onClick(scenesAndPayment.Subscription.latest_invoice)">
                {{ 'oxr.subscription.downloadInvoice' | translate }}
              </div>
            </div>
          }
        </div>
      } @else {
        <ui-loader></ui-loader>
      }
    </div>
    <div class="payment">
      <div class="title">{{ 'oxr.subscription.generalPayment' | translate }}</div>
      <div class="empty">{{ 'oxr.subscription.noHistory' | translate }}</div>
    </div>
  </div>
</div>

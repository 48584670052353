import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomainMap } from '@/data/src/lib/models/data/domain';
import { DomainService } from 'projects/data/src/lib/services/domain.service';
import { PaymentService } from 'projects/data/src/lib/services/payment.service';
import { DomainStatus } from '@/data/src/lib/enums/domain';
import { interval, skip, switchMap } from 'rxjs';
import { AccountOption } from '@/data/src/lib/models/data/account';
import { AccountService } from '@/data/src/lib/services/account.service';

@UntilDestroy()
@Component({
  selector: 'app-domain-owned-list',
  templateUrl: './domain-owned-list.component.html',
  styleUrls: ['./domain-owned-list.component.scss'],
})
export class DomainOwnedListComponent implements OnInit, OnDestroy {
  domains: DomainMap[] = [];
  connectingDomains: DomainMap[] = [];
  domainStatus = DomainStatus;
  loading: boolean = true;
  currentAccountOption: AccountOption | null;

  constructor(
    private _router: Router,
    private _domainService: DomainService,
    private _accountService: AccountService,
    private _ngZone: NgZone,
    public paymentService: PaymentService,
  ) {
    this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe(async (currentAccountOption: AccountOption | null) => {
      this.getData(currentAccountOption);
      this.currentAccountOption = currentAccountOption;
    });

    this._ngZone.runOutsideAngular(() => {
      interval(60000)
        .pipe(
          skip(1),
          switchMap(() => this.getData(this.currentAccountOption)),
          untilDestroyed(this),
        )
        .subscribe(() => {
          this._ngZone.run(() => {});
        });
    });
  }

  ngOnDestroy(): void {
    this.connectingDomains = [];
  }

  setConnectingDomains() {
    this.connectingDomains = this.domains.filter((e) => e.Status === DomainStatus.Connecting);
  }

  async ngOnInit(): Promise<void> {}

  onClick() {
    this._router.navigate(['domain', 'search']);
  }

  returnGuideDate(domainDateCreated: string) {
    let result = new Date(domainDateCreated);
    result.setDate(result.getDate() + 2);
    return result;
  }

  async getData(currentAccountOption: AccountOption | null) {
    if (currentAccountOption) {
      this.loading = true;
      this.domains = await this._domainService.get(currentAccountOption.EnterpriseContract?.EnterpriseId);
      this.setConnectingDomains();
      this.loading = false;
    }
  }
}

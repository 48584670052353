import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { base64ToFile } from 'ngx-image-cropper';
import { interval, take } from 'rxjs';
import { Access, FileStorageType } from '@/data/src/lib/enums/access';
import { RouteParam } from '@/data/src/lib/enums/route-param';
import { Permission } from '@/data/src/lib/enums/permission';
import { IAsset, XRAsset, XRAssetVersion } from '@/data/src/lib/models/data/asset';
import { MarketItem } from '@/data/src/lib/models/data/market';
import { AssetService } from '@/data/src/lib/services/asset.service';
import { DataCacheService } from '@/data/src/lib/services/data-cache.service';
import { FileService } from '@/data/src/lib/services/file.service';
import { MarketService } from '@/data/src/lib/services/market.service';
// import { UserAssetService } from '@/data/src/lib/services/user-asset.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { ModalRef, ModalService } from '@/ui/src/lib/modal/modal.service';
import { ApplicationService, AssetStatus } from '@/view/src/app/app.service';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { TooltipPosition } from '@/ui/src/lib/directive/tooltip.directive';
import {
  ElementType,
  MAX_POLYGONS,
  MAX_VERSIONS,
  TElement,
  TEnvironmentElement,
  TObjectElement,
  TransformationMode,
  ViewTool,
} from '@/data/src/lib/view-manager';
import {
  THUMBNAIL_CANVAS_HEIGHT,
  THUMBNAIL_CANVAS_WIDTH,
  THUMBNAIL_HEIGHT,
  THUMBNAIL_WIDTH,
} from '../../oxr/oxr-space/oxr-space.component';
import { EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
import { FileType, MediaType } from '@/data/src/lib/enums/file-type';
import { ExitWithoutSavingInterface } from '@/data/src/lib/guards/exit-without-saving.guard';
import { AssetType, IAssetVersion } from '@/data/src/lib/models/data/asset';
import { NEW_ASSET_ID } from '@/data/src/lib/apiv2';
import { MediaUploadComponent } from '@/ui/src/lib/modal/media-upload/media-upload.component';

enum AssetsMenuComponent {
  None,
  Controls,
  Lights,
}

@UntilDestroy()
@Component({
  selector: 'app-editor-view',
  templateUrl: './editor-view.component.html',
  styleUrls: ['./editor-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EditorViewComponent implements ExitWithoutSavingInterface, OnInit, OnDestroy {
  @ViewChild('viewCanvas', { static: false }) viewCanvas: any;
  @ViewChild('uncroppedImage', { static: false }) uncroppedImage: any;
  @ViewChild('captureZone', { static: false }) captureZone: any;

  public assetVersionElement?: TElement;
  public environmentElement?: TEnvironmentElement;
  public marketItem: MarketItem | undefined;
  public asset: XRAsset | undefined;
  public assetVersion: XRAssetVersion | undefined;

  AssetType = AssetType;
  tools = ViewTool;
  toolTipPositions = TooltipPosition;
  currentTransformationMode = TransformationMode.Translate;
  showCaptureZone = false;
  showContextMenu = false;
  showCroppedImage = false;
  isLoading = false;
  image: any;
  croppedImage: any;
  saveAfterThumbnail = false;

  isCreating = false;
  isOwner = false;

  assetOptions = [
    {
      name: this._translateService.instant('sidenav.environment'),
      description: this._translateService.instant('mediaUpload.environmentDescription'),
      assetType: AssetType.Environment,
      limitSize: 3145728, // 3mb
      extensions: '.jpg, .jpeg, .png',
    },
    {
      name: this._translateService.instant('sidenav.object'),
      description: this._translateService.instant('mediaUpload.objectDescription'),
      assetType: AssetType.Object,
      limitSize: 10485760, // 10mb
      extensions: '.glb',
    },
  ];

  private modalRef: ModalRef<MediaUploadComponent>;

  constructor(
    public dataCache: DataCacheService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _accountService: AccountService,
    private _marketService: MarketService,
    private _assetService: AssetService,
    // private _userAssetService: UserAssetService,
    private _route: ActivatedRoute,
    private _appService: ApplicationService,
    private _router: Router,
    private _fileService: FileService,
    private _cd: ChangeDetectorRef,
    private _ngZone: NgZone,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  get activeToolName() {
    return this._appService.getViewManager()?.tool?.name;
  }

  get assetsMenu() {
    return AssetsMenuComponent;
  }

  get assetType() {
    return this.asset?.Type;
  }

  get isAssetObject() {
    return this.asset?.Type === AssetType.Object;
  }

  ngOnInit(): void {
    this._route.paramMap.pipe(untilDestroyed(this)).subscribe(async (params) => {
      const assetId = params.get(RouteParam.Asset);

      if (assetId) {
        this.isCreating = assetId === NEW_ASSET_ID;
        const currentAccountOption = this._accountService.currentAccountOption.getValue();
        if (this.isCreating) {
          this.promptAssetUpload();
        } else {
          this.asset = await this._appService.apiv2.getAssetById(assetId, undefined, true);
          if (!this.asset) {
            this._appService.showNotification('editor.creatingAsset.assetNotFound', 'error');
            this._router.navigate(['editor', 'list']);
            return;
          }
          this._appService.setActiveModel(this.asset);
          this._appService.setSelectedAssetVersion(this.asset.versions[0]);
        }

        this._cd.detectChanges();

        // if (!this.isCreating) {
        //   this.marketItem = await this._marketService.getByAssetId(assetId);
        //   this._userAssetService.getByAssetId(assetId).then((data) => {
        //     this.isOwner =
        //       currentAccountOption?.Role === EnterpriseRole.Owner ||
        //       !!data.some((item) => item.UserId === this._accountService.getAccountId() && item.Permission === Permission.Owner);
        //   });
        // } else {
        //   this.isOwner = true;
        // }
      }
    });

    this._appService.assetStatusSubject.pipe(untilDestroyed(this)).subscribe((map) => {
      if (!!map.size && [...map.values()].some((status) => status === AssetStatus.Ready)) {
        this._ngZone.runOutsideAngular(() => {
          interval(100)
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
              this._appService.getViewManager()?.recountPolygons();
              this._ngZone.run(() => {});
            });
        });
      }
    });

    // this._appService.assetSizeSubject.pipe(untilDestroyed(this)).subscribe(([x, y, z]) => {
    //   if (!this.asset) {
    //     return;
    //   }
    //   // this.asset.ExtentsX = x;
    //   // this.asset.ExtentsY = y;
    //   // this.asset.ExtentsZ = z;
    // });

    this._appService.selectedAssetVersionSubject.pipe(untilDestroyed(this)).subscribe((assetVersion) => {
      this.assetVersion = assetVersion;
      this._cd.detectChanges();
    });

    this._appService.viewElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.environmentElement = elements.find(({ type }) => type === ElementType.Environment) as TEnvironmentElement;

      this._ngZone.runOutsideAngular(() => {
        interval(100)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            switch (this.asset?.Type) {
              case AssetType.Environment:
                this.assetVersionElement = this.environmentElement;
                break;
              case AssetType.Object:
                this.assetVersionElement = this._appService.getViewElements(ElementType.Object)?.[0] as TObjectElement;
                this._appService.getViewManager()?.recountPolygons();
                break;
              default:
                break;
            }
            this.updateAssetVersion();
            this._ngZone.run(() => {});
          });
      });

      this._cd.detectChanges();
    });

    this._appService.polygonCountSubject.pipe(untilDestroyed(this)).subscribe((count) => {
      if (this.isCreating && this.isAssetObject && count > MAX_POLYGONS) {
        this.asset = undefined;
        this._appService.apiv2.clearNewAsset();
        this.promptAssetUpload(this._translateService.instant('editor.creatingAsset.polygonLimit'));
      }
      this._cd.detectChanges();
    });

    this._appService.setViewElements([]);
    this._appService.openPanelsMenu.next(true);
  }

  updateAssetVersion() {
    this.assetVersionElement &&
      this.assetVersion &&
      this._appService.updateAssetVersion({
        Id: this.assetVersion.Id,
        V: this.assetVersion.V,
        AssetId: this.assetVersion.AssetId,
        Name: this.assetVersion.Name,
        Type: this.assetVersion.Type,
        Thumbnail: this.assetVersion.Thumbnail,
        Parameters: {
          ...(this.asset?.Type === AssetType.Object
            ? {
                position: (this.assetVersionElement as TObjectElement).parameters.position,
                quaternion: (this.assetVersionElement as TObjectElement).parameters.quaternion,
                scaling: (this.assetVersionElement as TObjectElement).parameters.scaling,
                environment: {
                  intensity: this.environmentElement?.parameters.intensity,
                  level: this.environmentElement?.parameters.level,
                  rotation: this.environmentElement?.parameters.rotation,
                  tint: this.environmentElement?.parameters.tint,
                },
              }
            : {
                intensity: (this.assetVersionElement as TEnvironmentElement).parameters.intensity,
                tint: (this.assetVersionElement as TEnvironmentElement).parameters.tint,
                level: (this.assetVersionElement as TEnvironmentElement).parameters.level,
                rotation: (this.assetVersionElement as TEnvironmentElement).parameters.rotation,
              }),
        },
      } as IAssetVersion);
  }

  onThumbnailRetake() {
    this.image = null;
    this.showCroppedImage = false;
    this.showCaptureZone = true;
    this._cd.detectChanges();
  }

  exitCaptureMode() {
    this.showCaptureZone = false;
  }

  async onThumbnailSave() {
    if (!this.asset) {
      return;
    }
    this.isLoading = true;
    this._appService.showNotification('editor.creatingAsset.savingAsset', 'error');
    const { url } = await this._fileService.uploadFile(
      new File([base64ToFile(this.croppedImage)], ''),
      FileStorageType.Public,
      undefined,
      `Thumbnail-${this.asset.Type}`,
      true,
      FileType.Image,
      undefined,
      Access.Public,
      MediaType.Thumbnail,
    );
    (this.asset as XRAsset).Thumbnail = url;
    this.showCroppedImage = false;
    if (this.saveAfterThumbnail) {
      this.saveAfterThumbnail = false;
      await this.onSave();
    }
    this.isLoading = false;
    this._cd.detectChanges();
  }

  captureScreen() {
    this.uncroppedImage.nativeElement.onload = () => {
      const canvas = this._document.createElement('canvas');
      canvas.width = THUMBNAIL_CANVAS_WIDTH;
      canvas.height = THUMBNAIL_CANVAS_HEIGHT;
      const ctx = canvas.getContext('2d');
      const srcImg = this.uncroppedImage.nativeElement;
      ctx?.drawImage(
        srcImg,
        (srcImg.width - THUMBNAIL_WIDTH) / 2,
        (srcImg.height - THUMBNAIL_HEIGHT) / 2,
        THUMBNAIL_WIDTH,
        THUMBNAIL_HEIGHT,
        0,
        0,
        THUMBNAIL_CANVAS_WIDTH,
        THUMBNAIL_CANVAS_HEIGHT,
      );

      this._ngZone.runOutsideAngular(() => {
        interval(100)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            this.uncroppedImage.nativeElement.onload = null;
            this.croppedImage = canvas.toDataURL();
            this.showCaptureZone = false;
            this.showCroppedImage = true;
            this._ngZone.run(() => {});
          });
      });
    };

    this.image = this.viewCanvas.viewCanvasRef.nativeElement.toDataURL();
  }

  onThumbnail() {
    this._appService.getViewManager()?.tool?.interact();
    this.showCaptureZone = true;
    this.saveAfterThumbnail = false;
  }

  onPreview() {
    this._appService.refreshView();
  }

  async onSave() {
    if (this.isCreating) {
      this._appService.getViewManager()?.tool?.interact();
      if (!this.croppedImage) {
        this.showCaptureZone = true;
        this.saveAfterThumbnail = true;
        return;
      }
      await this._appService.publishNewAsset();
    } else {
      await this._appService.saveAsset(this.asset as XRAsset);
    }
    this._router.navigate(['editor', 'list']);
  }

  async onSaveAs() {
    if (this.isCreating || !this.asset?.versions?.length || !this.assetVersion) {
      return;
    }
    if (this.asset.versions.length > MAX_VERSIONS) {
      this._appService.showNotification('editor.creatingAsset.maxVersions', 'error');
      return;
    }
    await this._appService.apiv2.publishNewAssetVersion(
      this.assetVersion,
      this._appService.getViewElements() as (TEnvironmentElement | TObjectElement)[],
    );
    this._router.navigate(['editor', 'list']);
  }

  onClose() {
    this._router.navigate(['editor', 'list']);
  }

  exitWithoutSaving(): Promise<boolean> {
    const modal = this._modalService.open(ConfirmationComponent);
    modal.instance.title = this._translateService.instant('shared.confirmation.warning');
    modal.instance.body = this._translateService.instant('shared.confirmation.exitWithoutSavingUpload');
    modal.instance.confirmAction = this._translateService.instant('shared.confirmation.exit');
    return modal.result.then((result: boolean) => {
      // if (result && this.isCreating) {
      //   this._assetService.remove(this.asset as XRAsset);
      // }
      return Promise.resolve(result);
    });
  }

  promptAssetUpload(error?: string) {
    this.modalRef = this._modalService.open(MediaUploadComponent, { closeOnBackdropClick: false, closeOnDestroy: false });

    if (this.modalRef) {
      this.modalRef.instance.modalRef = this._modalService;
      this.modalRef.instance.data = this.assetOptions;
      this.modalRef.instance.kind = AssetType.Object;
      this.modalRef.instance.showMyCom = false;
      this.modalRef.instance.layoutSize = 'md';
      this.modalRef.instance.buttonName = this._translateService.instant('shared.actions.continue');
      if (error) {
        this.modalRef.instance.error = error;
      }
      this.modalRef.result.then(async (result) => {
        if (result) {
          const uploadedFile = result[0];
          const selectedData = result[1];
          this.asset = this._appService.apiv2.createNewAsset(uploadedFile, {
            Name: uploadedFile?.name,
            Type: selectedData.assetType,
          } as IAsset);
          this._cd.detectChanges();
          await this._appService.importGeometryFromFile(this.asset.file);
          this._appService.setActiveModel(this.asset);
          this._appService.setSelectedAssetVersion(this.asset.versions[0]);
          return;
        }
        this._router.navigate(['editor', 'list']);
      });
    }
  }

  ngOnDestroy(): void {
    this._appService.apiv2.clearNewAsset();
  }
}

import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { SubscriptionPlanCard } from '@/data/src/lib/models/data/subscription';
import { AccountService } from '@/data/src/lib/services/account.service';
import { PaymentStripeService, SessionResult } from '@/data/src/lib/services/payment-stripe.service';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { ContactFormComponent } from '@/ui/src/lib/modal/contact-form/contact-form.component';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrl: './subscription-plan.component.scss',
})
export class SubscriptionPlanComponent implements OnInit {
  subscriptionPlanCards: SubscriptionPlanCard[] = [
    {
      title: 'Free',
      price: '$ 0',
      priceDescription: '',
      subfeatures: '',
      features: [
        'subscriptionPlan.planCard.free.features.providingFreeSpace',
        'subscriptionPlan.planCard.free.features.useOfUploadedAssets',
        'subscriptionPlan.planCard.free.features.publishSpaceLink',
        'subscriptionPlan.planCard.free.features.shareSpaceWithTheCommunity',
        'subscriptionPlan.planCard.free.features.liveChatWithinTheSpace',
      ],
      buttonDescription: 'subscriptionPlan.planCard.free.buttonDescription',
      color: '#82F029',
    },
    {
      title: 'Pro',
      price: '$ 50',
      priceDescription: 'subscriptionPlan.planCard.pro.priceDescription',
      subfeatures: 'subscriptionPlan.planCard.pro.subfeatures',
      features: [
        'subscriptionPlan.planCard.pro.features.spaceHistoryCheckAndRestore',
        'subscriptionPlan.planCard.pro.features.unlimitedUseOfUploadedAssets',
        'subscriptionPlan.planCard.pro.features.removeOwnxrAd',
        'subscriptionPlan.planCard.pro.features.connectSubdomainDomain',
        'subscriptionPlan.planCard.pro.features.unlimitedUseOfInSpaceOptions',
      ],
      buttonDescription: 'subscriptionPlan.planCard.pro.buttonDescription',
      color: '#F08400',
    },
    {
      title: 'Enterprise',
      price: '',
      priceDescription: 'subscriptionPlan.planCard.enterprise.priceDescription',
      subfeatures: 'subscriptionPlan.planCard.enterprise.subfeatures',
      features: [
        'subscriptionPlan.planCard.enterprise.features.multiUserConcurrentEditing',
        'subscriptionPlan.planCard.enterprise.features.shareMediaLibrary',
        'subscriptionPlan.planCard.enterprise.features.assetCustomization',
      ],
      buttonDescription: 'subscriptionPlan.planCard.enterprise.buttonDescription',
      color: '#FF4085',
    },
  ];

  showDetail = false;

  constructor(
    private _router: Router,
    private _modalService: ModalService,
    private _accountService: AccountService,
    private _paymentStripeService: PaymentStripeService,
    private _elementRef: ElementRef,
    @Inject(WINDOW) private readonly _window: Window,
  ) {}

  ngOnInit(): void {}

  async onClick(name: string) {
    switch (name) {
      case 'Experience':
        this._router.navigateByUrl('oxr/owned');
        break;

      case 'StripeCheckout':
        if (!this._accountService.account) {
          this._router.navigateByUrl('oxr/owned');
        }
        await this._paymentStripeService
          .redirectStripeCheckout()
          .then((sessionResult: SessionResult) => (this._window.document.location.href = sessionResult.RedirectUrl));
        break;

      case 'Inquiry':
        const modal = this._modalService.open(ContactFormComponent, { closeOnBackdropClick: false });
        if (modal) {
          modal.instance.modalRef = this._modalService;
        }
        break;
    }
  }

  scrollToTop() {
    const topSection = this._elementRef.nativeElement.querySelector('.package.top');
    if (topSection) {
      topSection.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

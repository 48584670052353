<div class="panel-default" [formGroup]="form">
  <div class="panel-body">
    <div class="company-logo">
      <div class="icon icon-ownxr"></div>
      <img class="logo" src="./../../../assets/images/img-icon-ownxr-logo.png" />
    </div>
    <div class="title">{{ 'signUp.title' | translate }}</div>
    <div class="entry">
      <div class="entry-item">
        <input formControlName="Username" uiInput placeholder="{{ 'shared.fields.username' | translate }}" autofocus />
        @if (!usernameError) {
          <div class="icon icon-yes"></div>
        }
        <ul class="information">
          <li
            [ngClass]="{
              error: usernameExitstsError
            }"
          >
            {{ 'signUp.information.information1' | translate }}
          </li>
          <li
            [ngClass]="{
              error: usernameMaxlengthError
            }"
          >
            {{ 'signUp.information.information2' | translate }}
          </li>
          <li [ngClass]="{ error: usernamePatternError }">
            {{ 'signUp.information.information3' | translate }}
          </li>
          <li>
            {{ 'signUp.information.information4' | translate }}
          </li>
        </ul>
      </div>
      <div class="entry-item">
        <ng-select
          formControlName="Server"
          [searchable]="false"
          [clearable]="false"
          placeholder="{{ 'shared.fields.selectServer' | translate }}"
          dropdownPosition="bottom"
        >
          <ng-option value="kr">
            <div class="select-option-wrapper">
              {{ 'shared.fields.server.kr' | translate }}
            </div>
          </ng-option>
          <ng-option value="us">
            <div class="select-option-wrapper">
              {{ 'shared.fields.server.us' | translate }}
            </div>
          </ng-option>
        </ng-select>
        <ul class="information">
          <li class="error">{{ 'signUp.information.information5' | translate }}</li>
        </ul>
      </div>
    </div>
    <button class="save" [disabled]="!form.valid || hasServer" (click)="onSave()">
      @if (loading) {
        <ui-loader sm [loaderColor]="'white'"></ui-loader>
      }
      @if (!loading) {
        <span>{{ 'shared.actions.save' | translate }}</span>
      }
    </button>
  </div>
</div>

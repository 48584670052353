<!-- Select Scene Layout -->
@if (!selectedScene) {
  <div class="title">{{ 'domain.menuCard.mappingDomainWithSpace' | translate }}</div>
  <div class="subtitle">{{ 'domain.whichSpaceConnect' | translate }}</div>
  <ui-search-bar class="select-scene-search" [(searchText)]="searchText" placeholder="{{ 'shared.fields.findSpaceToConnect' | translate }}">
  </ui-search-bar>
  @if (!loading) {
    @if (itemsPresent) {
      @if ((scenes | filterBy: ['Name', 'Tags', 'Username'] : searchText : false)?.length) {
        <ui-card-list>
          @for (scene of scenes | filterBy: ['Name', 'Tags', 'Username'] : searchText : false | orderByImpure: orderBy.value; track scene) {
            <app-domain-display-card
              [imageSrc]="scene.Thumbnail"
              [scenePlan]="scene.Plan"
              [isExpired]="scene.isExpired"
              [id]="scene.Id"
              [spaceName]="scene.Name"
              (imageClick)="setSelectedScene(scene)"
              [isFreeLock]="scene.Plan === plan.Free"
              [takenScenes]="takenScenes"
              [scene]="scene"
              [isCollaborator]="isCollaborator"
              [isPublic]="scene.IsPublished"
              [isEnterprise]="isEnterprise"
            >
              <app-community-card-description [scene]="scene"></app-community-card-description>
            </app-domain-display-card>
          }
        </ui-card-list>
      } @else {
        <div class="empty-list">
          <img src="./../../assets/images/magnifying-glass-color.png" />
          <div class="description">{{ 'community.noResultItems' | translate }}</div>
        </div>
      }
      <ng-template #empty>
        <div class="empty-list">
          <img src="./../../assets/images/magnifying-glass-color.png" />
          <div class="description">{{ 'community.noResultItems' | translate }}</div>
        </div>
      </ng-template>
    }
  } @else {
    <ui-loader></ui-loader>
  }
}
<!-- Enter Domain Layout -->
@if (selectedScene && !loading && !isGuide) {
  <div class="title">{{ 'domain.menuCard.mappingDomainWithSpace' | translate }}</div>
  <div class="subtitle" [innerHTML]="'domain.connectSpacenameAndOwnDomain' | translate: { spacename: selectedScene.Name }"></div>
  <div class="domain-layout-search-wrapper">
    <ui-search-bar
      class="domain-layout-search"
      [(searchText)]="domainToChangeSpace ? domainToChangeSpace.Path : searchText"
      placeholder="{{ 'domain.enterOwnedDomain' | translate }}"
      (searchTextChange)="onReset()"
      (searchTextChangeDebounce)="getInputValue($event)"
      [error]="!validationLoading && inputTouched && (!isValid || !isAvailable)"
      [onDebounceTime]="500"
      [disabled]="domainToChangeSpace ? true : false"
    >
    </ui-search-bar>
    @if (validationLoading) {
      <ui-loader></ui-loader>
    }
    @if (!validationLoading && inputTouched && isSwear) {
      <div class="error-message">{{ 'domain.invalidWord' | translate }}</div>
    }
    @if (!validationLoading && inputTouched && !isValid) {
      <div class="error-message">{{ 'domain.inputKeyword3' | translate }}</div>
    }
    @if (!validationLoading && inputTouched && !isAvailable) {
      <div class="error-message">{{ 'domain.exist' | translate }}</div>
    }
  </div>
  <div class="button-wrapper">
    <button
      ui-button
      (click)="onClick('postDomain')"
      [disabled]="validationLoading || !inputTouched || isSwear || !isValid || !isAvailable"
    >
      {{ 'shared.actions.next' | translate }}
    </button>
  </div>
}
<!-- First Loading Layout -->
@if (selectedScene && loading && !isGuide) {
  <div class="title">{{ 'domain.menuCard.mappingDomainWithSpace' | translate }}</div>
  <ui-loader></ui-loader>
}
<!-- Guide Start Layout -->
@if (selectedScene && !loading && isGuide && activatedStage == 0) {
  <div class="title">{{ 'domain.menuCard.mappingDomainWithSpace' | translate }}</div>
  <div class="guide">
    <div class="left-side">
      <div class="main-title">{{ 'domain.guide.timeTaken' | translate }}</div>
      <div class="sub-title">{{ 'domain.guide.applicationTimeRequired' | translate }}</div>
      <div class="time">5{{ 'domain.guide.minute' | translate }}</div>
      <div class="sub-title">{{ 'domain.guide.connectionPreparationTime' | translate }}</div>
      <div class="sub-sub-title">{{ 'domain.guide.maximum' | translate }}</div>
      <div class="time">48{{ 'domain.guide.hour' | translate }}</div>
    </div>
    <div class="right-side">
      <div class="main-title">{{ 'domain.guide.applicationProcess' | translate }}</div>
      <div class="domain-name">{{ domain }}</div>
      <div class="stages">
        @for (stage of stages; track stage; let i = $index) {
          <div class="stage-wrapper">
            <div class="number-wrapper">
              <div class="number">{{ stage.stage }}</div>
            </div>
            <img
              class="icon"
              [ngClass]="{ first: stage.stage == 1, second: stage.stage == 2, third: stage.stage == 3, fourth: stage.stage == 4 }"
              [src]="'./../../../../assets/images/' + stage.icon"
            />
            <div class="stage-title">{{ stage.stage }} {{ 'domain.guide.stage' | translate }}</div>
            <div class="stage-description" [innerHTML]="'domain.guide.' + stage.description | translate"></div>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="button-wrapper none-height">
    <button ui-button (click)="onClick('nextStage')">
      {{ 'guideModal.start' | translate }}
    </button>
  </div>
}
<!-- Guide Stage Description Layout -->
@if (activatedStage > 0 && activatedStage < 4) {
  <div class="title">{{ 'domain.menuCard.mappingDomainWithSpace' | translate }}</div>
  <div class="stage-body">
    <div class="top-side">
      <div class="stages">
        @for (stage of stages; track stage; let i = $index) {
          <div class="stage-wrapper" [ngClass]="{ activated: activatedStage == stage.stage }">
            <div class="number-wrapper">
              <div class="number">{{ stage.stage }}</div>
            </div>
            <img
              class="icon"
              [ngClass]="{ first: stage.stage == 1, second: stage.stage == 2, third: stage.stage == 3, fourth: stage.stage == 4 }"
              [src]="'./../../../../assets/images/' + stage.icon"
            />
            <div class="stage-description" [innerHTML]="'domain.guide.' + stage.description | translate"></div>
            <div class="state" [ngClass]="{ activated: activatedStage == stage.stage }"></div>
          </div>
        }
      </div>
    </div>
    <div class="bottom-side">
      <div class="main-title">{{ 'domain.howToConnect' | translate }}</div>
      <div class="stage-detail" [innerHTML]="stages[activatedStage - 1].detail | translate: { domain: domain }"></div>
      <!-- <div class="input-wrapper" [ngClass]="{ 'margin-plus': activatedStage == 3 }"> -->
      @if (activatedStage == 3) {
        <div class="input-wrapper">
          <table class="connection-info">
            <thead>
              <tr>
                <td class="column-1">Name</td>
                <td class="column-2">Type</td>
                <td class="column-2">Value</td>
                <td class="column-2">TTL</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="flex-wrapper">
                    {{ domain }}
                    <div class="icon icon-copy sm" (click)="onIconClick(domain)"></div>
                  </div>
                </td>
                <td>CNAME</td>
                <td>
                  <div class="flex-wrapper">
                    <!-- {{ environment.desktopFrontDoorEndpoint }} -->
                    <!-- <div class="icon icon-copy sm" (click)="onIconClick(environment.desktopFrontDoorEndpoint)"></div> -->
                  </div>
                </td>
                <td>3600</td>
              </tr>
              <tr>
                <td>
                  <div class="flex-wrapper">
                    {{ convertToMobileUrl(domain) }}
                    <div class="icon icon-copy sm" (click)="onIconClick(convertToMobileUrl(domain))"></div>
                  </div>
                </td>
                <td>CNAME</td>
                <td>
                  <div class="flex-wrapper">
                    <!-- {{ environment.mobileFrontDoorEndpoint }} -->
                    <!-- <div class="icon icon-copy sm" (click)="onIconClick(environment.mobileFrontDoorEndpoint)"></div> -->
                  </div>
                </td>
                <td>3600</td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </div>
  </div>
  <div class="button-wrapper none-height">
    <button ui-button (click)="onClick('nextStage')">
      @if (activatedStage < 3) {
        {{ 'shared.actions.next' | translate }}<span>&nbsp;({{ activatedStage }}/3)</span>
      } @else {
        {{ 'domain.completion' | translate }}
      }
    </button>
  </div>
}

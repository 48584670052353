import { Inject, Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageKeys, PlatformDetail, SessionStorageKeys } from '../enums/storage-keys';
import { environment } from '@/app/src/environments/environment';
import { SessionStorageService } from './session-storage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UrlService, UrlTransformResult } from './url.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { PricingPlanService } from './pricing-plan.service';
import { take } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  isDesktop: boolean;
  urlTransformResult: UrlTransformResult;

  constructor(
    private _accountService: AccountService,
    private _localStorageService: LocalStorageService,
    private _sessionStorageService: SessionStorageService,
    private _deviceService: DeviceDetectorService,
    private _urlService: UrlService,
    private _pricingPlanService: PricingPlanService,
    @Inject(DOCUMENT) private readonly _document: Document,
    private _route: ActivatedRoute,
  ) {
    this._urlService.urlTransformResult$.pipe(untilDestroyed(this)).subscribe((urlTransformResult) => {
      if (!urlTransformResult.isInitialValue && urlTransformResult.shouldRedirect) {
        this._document.location.href = urlTransformResult.transformedUrl;
      }
    });
  }

  async initializeApp() {
    return await new Promise<void>((resolve) => {
      this._route.queryParams.subscribe(async (params) => {
        //0.Set initial Region for API region
        await this._accountService.setRegion();

        //1.Check Platform
        const platform = params[SessionStorageKeys.PLATFORM];

        if (platform) {
          this._sessionStorageService.setItem(SessionStorageKeys.PLATFORM, platform);
        } else {
          const isDesktop = this._deviceService.isTablet() && this._deviceService.isDesktop() ? false : this._deviceService.isDesktop();
          this._sessionStorageService.setItem(SessionStorageKeys.PLATFORM, isDesktop ? PlatformDetail.DESKTOP : PlatformDetail.MOBILE);
        }

        this.isDesktop = this._sessionStorageService.getItem(SessionStorageKeys.PLATFORM) === PlatformDetail.DESKTOP ? true : false;

        //2.Transform Url
        await this._urlService.transformUrlBasedOnConditions(this._document.location.href, this.isDesktop);

        //3.Check Version
        const REGISTERED_APP_VERSION = this._localStorageService.getItem(LocalStorageKeys.VERSION);
        const CURRENT_APP_VERSION = environment.version;
        console.log('REGISTERED_APP_VERSION : ' + REGISTERED_APP_VERSION);
        console.log('CURRENT_APP_VERSION : ' + CURRENT_APP_VERSION);

        if (REGISTERED_APP_VERSION !== CURRENT_APP_VERSION) {
          this._localStorageService.clear();
          this._localStorageService.setItem(LocalStorageKeys.VERSION, CURRENT_APP_VERSION);
        }

        //4.Set Hotjar and Google tracking
        if (environment.production) {
          const hotjarScriptEl = this._document.createElement('script');
          hotjarScriptEl.innerHTML = `
                (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:2951484,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
          this._document.head.appendChild(hotjarScriptEl);

          const googleScriptEl1 = this._document.createElement('script');
          googleScriptEl1.async = true;
          googleScriptEl1.src = 'https://www.googletagmanager.com/gtag/js?id=G-R40QM6CB3P';
          this._document.head.appendChild(googleScriptEl1);

          const googleScriptEl2 = this._document.createElement('script');
          googleScriptEl2.innerHTML = `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-R40QM6CB3P');
            `;
          this._document.head.appendChild(googleScriptEl2);
        }

        //5.Load initial data
        await this._pricingPlanService.getPricingPlan().pipe(take(1)).subscribe();

        resolve();
      });
    });
  }
}

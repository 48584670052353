<div class="guide-wrapper">
  <div class="header">
    <ui-check-box class="selection-checkbox secondary sm" small [checked]="dontShowAgain" (checkedChange)="dontShowAgain = !dontShowAgain">
      {{ 'shared.actions.dontShowAgain' | translate }}
    </ui-check-box>
    @if (pageNumber > 1 && os === 'Android') {
      <div class="guide-fullscreen-container">
        @if (pageNumber === 2) {
          <div class="button-note fullscreen-note">
            <div class="icon icon-right-up-indicator"></div>
            <div class="note-text">
              {{ 'shared.actions.fullScreen' | translate }}
            </div>
          </div>
        }
        <div class="icon icon-fullscreen" [class.disabled]="pageNumber !== 2"></div>
      </div>
    }
  </div>
  <div class="inner-zone">
    @if (pageNumber === 1) {
      <div class="step-1">
        <div class="control-move-icon"></div>
        <div class="description">
          {{ 'community.guide.description' | translate }}
        </div>
        <div class="control-rotation-icon"></div>
      </div>
    }
    @if (pageNumber === 2) {
      <div class="description" [innerHTML]="'community.guide.optimizedForLandscape' | translate"></div>
    }
    @if (pageNumber === 3) {
      <div class="step-3">
        <div class="description flex-center">
          {{ 'community.guide.guideMessage' | translate }}
        </div>
        <div class="icon icon-tools-2 md"></div>
        <div class="circle"></div>
      </div>
    }
  </div>
  <div class="footer">
    <!-- Pagination -->
    <div class="guide-pagination">
      <button class="previous-button" ui-text-button (click)="navigatPage(-1)" [disabled]="pageNumber === 1">
        <i class="icon icon-arrow-right flip"></i>
        {{ 'shared.actions.previous' | translate }}
      </button>
      <span class="page-number"> {{ pageNumber }}/3 </span>
      <button class="next-button" ui-text-button (click)="navigatPage(1)" [disabled]="pageNumber > 3">
        {{ (pageNumber == 3 ? 'community.guide.completion' : 'shared.actions.next') | translate }}
        <i class="icon icon-arrow-right"></i>
      </button>
    </div>
  </div>
  <!-- Joystick -->
  @if (pageNumber > 1) {
    <div class="guide-joystick-container">
      @if (pageNumber === 2) {
        <div class="button-note joystick-note">
          <div class="icon icon-right-down-indicator invert"></div>
          <div class="note-text">
            {{ 'shared.actions.move' | translate }}
          </div>
        </div>
      }
      <div class="icon-joystick-2" [class.disabled]="pageNumber !== 2"></div>
    </div>
  }
  <!-- Jump -->
  @if (pageNumber > 1) {
    <div class="guide-jump-container">
      @if (pageNumber === 2) {
        <div class="button-note jump-note">
          <div class="icon icon-right-down-indicator"></div>
          <div class="note-text">
            {{ 'shared.actions.jump' | translate }}
          </div>
        </div>
      }
      <i class="icon icon-jump" [class.disabled]="pageNumber !== 2"></i>
    </div>
  }
</div>

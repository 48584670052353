<button ui-button primary md (click)="open()" [disabled]="_disabled">{{ 'collaborator.invitation' | translate }}</button>
@if (dialog) {
  <div class="collaborator-modal-wrapper">
    <div class="collaborator-modal">
      <i class="close-button icon icon-close" (click)="isLoading ? null : close()"></i>
      <div class="header">
        <div class="title">{{ 'collaborator.inviteCollaboratorTitle' | translate }}</div>
        <div class="subtitle">{{ 'collaborator.inviteCollaboratorSubtitle' | translate }}</div>
      </div>
      <div class="body">
        <div class="form">
          <ng-select [searchable]="false" [clearable]="false" [ngModel]="type" (ngModelChange)="onTypeChange($event)" [bindValue]="'value'">
            @for (option of options; track option) {
              <ng-option [value]="option.value">
                <div class="select-option-wrapper">
                  {{ 'collaborator.' + option.name | translate }}
                </div>
              </ng-option>
            }
          </ng-select>
          <div [formGroup]="form">
            <input uiInput placeholder="{{ 'collaborator.emailPlaceholder' | translate }}" formControlName="email" type="text" />
          </div>
          <button ui-button primary class="action-button" (click)="invite()" [disabled]="isLoading || form.invalid">
            @if (!isLoading) {
              {{ 'collaborator.invite' | translate }}
            } @else {
              <ui-loader sm loaderColor="white"></ui-loader>
            }
          </button>
        </div>
      </div>
    </div>
  </div>
}

import 'zone.js/plugins/task-tracking';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, NgZone } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClipboardModule } from 'ngx-clipboard';
import { QuillModule } from 'ngx-quill';
import { interval, take } from 'rxjs';
import { ToastrModule } from 'ngx-toastr';
import { toastrConfiguration } from '@/app/src/app/app.module';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { ViewModule } from '@/view/src/app/module/view.module';
import { AppInitializerService } from '@/data/src/lib/services/app-initializer.service';
import { DataModule } from '@/data/src/public-api';
import { MsalLocaleGuard } from '@/data/src/lib/guards/msalLocaleGuard.guard';
import { UiModule } from '@/ui/src/public-api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MobileCommunityModule } from './community/community.module';
import { MobileComponentsModule } from './components/m-components.module';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { CustomInterceptor } from './custom-interceptor';
import { MobileSharedModule } from './shared.module';
import { environment } from '../environments/environment';
import { TempLandingComponent } from './temp-landing/temp-landing.component';

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: environment.redirectURL,
      postLogoutRedirectUri: environment.redirectURL,
      knownAuthorities: [environment.b2cPolicies.authorityDomain],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['openid'],
    },
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[] | null>();

  for (const region of environment.apiConfig.uri) {
    protectedResourceMap.set(region, environment.apiConfig.scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function initializeApp(_appInitializerService: AppInitializerService) {
  return () => _appInitializerService.initializeApp();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, SignUpComponent, ErrorPageComponent, TempLandingComponent],
  imports: [
    MobileSharedModule,
    AppRoutingModule,
    DataModule,
    UiModule,
    ViewModule,
    MobileComponentsModule,
    MobileCommunityModule,
    ClipboardModule,
    ToastrModule.forRoot(toastrConfiguration),
    QuillModule.forRoot(),
    MsalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AppInitializerService],
    },
    MsalService,
    MsalLocaleGuard,
    MsalBroadcastService,
    AppInitializerService,
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    provideHttpClient(withFetch()),
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(private _ngZone: NgZone) {
    if (!environment.production) {
      this.trackingAsyncTasks();
    }
  }

  /**
   * Angular Universal SSR (Server Side Rendering) hangs when some asynchronous task in our app is not completed.
   * Since version 16, Angular SSR no longer counts outgoing HTTP requests as macrotasks in Zone.js, but uses an internal http interceptor function instead.
   */
  trackingAsyncTasks() {
    /**
     * CONFIGURE how long to wait (in milliseconds)
     * before the pending tasks are dumped to the console.
     */
    const WAIT_MS = 2000;

    console.log(`⏳ ... Wait ${WAIT_MS} ms to dump pending tasks ... ⏳`);

    // Run the debugging `interval` code outside of
    // the Angular Zone, so it's not considered as
    // yet another pending Zone Task:
    this._ngZone.runOutsideAngular(() => {
      interval(WAIT_MS)
        .pipe(take(1))
        .subscribe(() => {
          // Access the NgZone's internals - TaskTrackingZone:
          const TaskTrackingZone = (this._ngZone as any)._inner._parent._properties.TaskTrackingZone;

          // Print to the console all pending tasks
          // (micro tasks, macro tasks and event listeners):
          console.debug('👀 Pending tasks in NgZone: 👀');
          console.debug({
            // eventTasks: TaskTrackingZone.getTasksFor('eventTask'),
            microTasks: TaskTrackingZone.getTasksFor('microTask'),
            macroTasks: TaskTrackingZone.getTasksFor('macroTask'),
          });

          // Advice how to find the origin of Zone tasks:
          console.debug(`👀 For every pending Zone Task listed above investigate the stacktrace in the property 'creationLocation' 👆`);
        });
    });
  }
}

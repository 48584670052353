import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CommunityComponent implements OnInit, OnDestroy {
  constructor(private _router: Router, private _title: Title) {}

  ngOnInit(): void {
    this._title.setTitle('OWNXR | Community');
  }

  ngOnDestroy(): void {
    this._title.setTitle('OWNXR');
  }

  isActive(url: string) {
    return this._router.isActive(`community/${url}`, {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  navigateTo(url: string) {
    this._router.navigate(['community', url]);
  }
}

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, Subject, take, takeUntil } from 'rxjs';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { ListOrderOption } from '@/data/src/lib/models/interfaces/list-order-option';
import { AccountOption } from '@/data/src/lib/models/data/account';
import { AccountService } from '@/data/src/lib/services/account.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { environment } from '@/app/src/environments/environment';
import { UrlService } from '@/data/src/lib/services/url.service';

@UntilDestroy()
@Component({
  selector: 'app-community-liked-list',
  templateUrl: './community-liked-list.component.html',
  styleUrls: ['./community-liked-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CommunityLikedListComponent implements OnInit, OnDestroy {
  username: string;
  loading = true;
  searchText: string;
  scenes: XRScene[] = [];
  orderBy: ListOrderOption = { value: '-Likes', name: 'mostLiked' };

  likes: string[] = [];

  private _destroy$ = new Subject<void>();

  constructor(private _sceneService: SceneService, private _accountService: AccountService, private _urlService: UrlService) {
    this._sceneService.likes$.pipe(takeUntil(this._destroy$)).subscribe((likes) => {
      // added fake id - filter pipe returns full array if no items to filter by
      this.likes = [...likes, 'fakeId'];
    });
    this._accountService.activeAccount$.pipe(takeUntil(this._destroy$)).subscribe((res) => {
      if (res) {
        this.username = res.Username;

        combineLatest([this._sceneService.getPublicScenes(), this._sceneService.getLikes()])
          .pipe(take(1))
          .subscribe(([scenes]) => {
            this.loading = false;
            this.scenes = scenes.filter((e) => !e.isExpired);
          });
      }
    });
  }

  ngOnInit(): void {
    this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe((data: AccountOption | null) => {
      if (data) {
        this.searchText = '';
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  changeOrderBy(orderBy: ListOrderOption): void {
    this.orderBy = orderBy;
  }

  viewScene(item: XRScene) {
    this._sceneService.postViews([item.Id]);
    this._urlService.windowOpenWithPlatform(`${environment.redirectURL}/view/${item.Id}`);
  }
}

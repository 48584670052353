import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { AccountService } from '@/data/src/lib/services/account.service';
import { DomainService } from '@/data/src/lib/services/domain.service';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { GuideModalComponent } from '@/ui/src/lib/modal/guide/guide.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { SettingData } from '@/ui/src/lib/layout/domain-card-description/domain-card-description.component';

@UntilDestroy()
@Component({
  selector: 'app-domain-search',
  templateUrl: './domain-search.component.html',
  styleUrls: ['./domain-search.component.scss'],
})
export class DomainSearchComponent implements OnInit {
  searchText = '';
  plan = ScenePlan;
  untakenCount = 0;
  takenCount = 0;
  loading = true;

  settingData1: SettingData = {
    description: 'domain.menuCard.domainSettingDescription',
    mainTitle: 'domain.menuCard.domainSetting',
    outButtonTitle: 'username.ownxr.com',
    overButtonTitle: this.untakenCount > 0 ? 'domain.menuCard.setting' : 'domain.menuCard.subscribe',
    tooltip: 'domain.menuCard.domainSettingTooltip',
    index: '0',
  };

  settingData2: SettingData = {
    description: 'domain.menuCard.buyNewDomainDescription',
    mainTitle: 'domain.menuCard.buyNewDomain',
    outButtonTitle: 'username.com',
    overButtonTitle: 'domain.menuCard.search',
    tooltip: 'domain.menuCard.buyNewDomainTooltip',
    index: '1',
  };

  settingData3: SettingData = {
    description: 'domain.menuCard.mappingDomainWithSpaceDescription',
    mainTitle: 'domain.menuCard.mappingDomainWithSpace',
    outButtonTitle: 'exsisting.domain.com',
    overButtonTitle: 'domain.menuCard.connect',
    tooltip: 'domain.menuCard.mappingDomainWithSpaceTooltip',
    index: '2',
  };

  public scenes: XRScene[] = [];

  constructor(
    private _accountService: AccountService,
    private _router: Router,
    private _sceneService: SceneService,
    private _domainService: DomainService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _pricingPlanService: PricingPlanService,
  ) {}

  async ngOnInit() {
    await this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe(async (currentAccountOption) => {
      this.loading = true;
      const calculatedData = await this.getData(currentAccountOption?.EnterpriseContract?.EnterpriseId);
      this.untakenCount = calculatedData.untakenCount;
      this.takenCount = calculatedData.takenCount;
      this.settingData1.overButtonTitle = this.untakenCount > 0 ? 'domain.menuCard.setting' : 'domain.menuCard.subscribe';
      this.loading = false;
    });
  }

  async getData(enterpriseId: string | undefined) {
    let filterConditions: ((scene: XRScene) => boolean)[] = [(scene: XRScene) => !scene.isExpired];

    const canConnectToDomainInFreeScene =
      this._pricingPlanService.scenePlans!.Free.PublishSpace &&
      this._pricingPlanService.scenePlans!.Free.ConnectOwnXrSubdomain &&
      this._pricingPlanService.scenePlans!.Free.ConnectOwnedDomain;
    const canConnectToDomainInProScene =
      this._pricingPlanService.scenePlans!.Pro.PublishSpace &&
      this._pricingPlanService.scenePlans!.Pro.ConnectOwnXrSubdomain &&
      this._pricingPlanService.scenePlans!.Pro.ConnectOwnedDomain;

    const query = enterpriseId ? `enterpriseId=${enterpriseId}` : '';
    await Promise.all([this._sceneService.get('', query, undefined, true, true), this._domainService.get(enterpriseId)]);

    if (!canConnectToDomainInFreeScene) {
      filterConditions.push((scene: XRScene) => scene.Plan !== ScenePlan.Free);
    }

    if (!canConnectToDomainInProScene) {
      filterConditions.push((scene: XRScene) => scene.Plan !== ScenePlan.Pro);
    }

    const filteredScenes = this._sceneService.getCached().filter((scene) => filterConditions.every((condition) => condition(scene)));

    return Promise.resolve({
      untakenCount: filteredScenes.length - this._domainService.getCached().length,
      takenCount: this._domainService.getCached().length,
    });
  }

  onClick(index: string) {
    switch (index) {
      case '0':
        if (this.untakenCount > 0) {
          this._router.navigate([this._router.url, 'setting', 'ownxr']);
        } else {
          const modal = this._modalService.open(GuideModalComponent);
          if (modal) {
            modal.instance.message = this._translateService.instant('domain.menuCard.menuCardGuide1');
            modal.instance.showCheckbox = false;
            modal.result.then(() => {});
          }
        }
        break;
      case '1':
        // this._router.navigate([this._router.url, 'setting', 'buy']);
        break;
      case '2':
        // this._router.navigate([this._router.url, 'setting', 'connection']);
        break;
    }
  }
}

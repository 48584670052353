import { TranslateService } from '@ngx-translate/core';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { LocalStorageKeys } from '@/data/src/lib/enums/storage-keys';
import { LocalStorageService } from '@/data/src/lib/services/local-storage.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-oxr-manual',
  templateUrl: './oxr-manual.component.html',
  styleUrls: ['./oxr-manual.component.scss'],
})
export class OxrManualComponent implements OnInit {
  isChecked = false;
  firstPage = 1;
  lastPage = 4;
  currentPage: number;
  currentLanguage$ = this._accountService.language$;

  @Output() closeManual = new EventEmitter();
  @Output() noShowAgain = new EventEmitter();

  constructor(
    private _accountService: AccountService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  ngOnInit() {
    this.currentPage = this.firstPage;
  }

  clickLeftSide() {
    this.currentPage > this.firstPage ? (this.currentPage = this.currentPage - 1) : null;
    this.scrollTo('top');
  }

  clickRightSide() {
    this.currentPage < this.lastPage ? (this.currentPage = this.currentPage + 1) : null;
    this.scrollTo('top');
  }

  onShowConfirmationModal(event: boolean) {
    if (event) {
      const modal = this._modalService.open(ConfirmationComponent, { closeOnBackdropClick: false });
      if (modal) {
        modal.instance.title = this._translateService.instant('shared.information.information');
        modal.instance.confirmAction = this._translateService.instant('shared.confirmation.yes');
        modal.instance.cancelAction = this._translateService.instant('shared.confirmation.cancel');
        modal.instance.body = this._translateService.instant('shared.confirmation.deleteManualPopupConfirmationMessage');

        modal.result.then((confirmed) => {
          if (confirmed) {
            this._localStorageService.setItem(LocalStorageKeys.NEVER_SHOW_AGAIN_EDIT_MANUAL, this.isChecked ? 'false' : 'true');
            this.noShowAgain.emit();
          } else {
            this.isChecked = false;
          }
        });
      }
    }
  }

  onCloseManual() {
    this.closeManual.emit();
  }

  scrollTo(position: string) {
    const target = this._document.getElementsByClassName('manual-img-content')[0];

    if (position === 'top') {
      target.scroll({ top: 0, behavior: 'smooth' });
    } else {
      target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    }
  }
}

<ui-search-bar [(searchText)]="searchText"> </ui-search-bar>

<ui-search-filter [tags]="tags" [singleSelection]="true" (tagsChecked)="setCheckedTags($event)">
  <ng-select
    class="small"
    [searchable]="false"
    [clearable]="false"
    placeholder="{{ 'shared.list.orderBy.placeholder' | translate }}"
    [ngModel]="orderBy"
    (ngModelChange)="changeOrderBy($event)"
    [disabled]="loading"
  >
    <ng-option *ngFor="let option of orderingOptions" [value]="option">
      <div class="select-option-wrapper">
        {{ 'shared.list.orderBy.' + option.name | translate }}
      </div>
    </ng-option>
  </ng-select>
</ui-search-filter>

<ng-container *ngIf="!loading; else loader">
  <ui-card-list
    *ngIf="
      (assets | filterBy: ['Type'] : checkedTags | filterBy: ['Name', 'Type'] : (searchText | removeComma | removeHash) : false)?.length
    "
  >
    <ui-display-card [isNewCard]="true" [canCreate]="true" (imageClick)="createNewAsset()"> </ui-display-card>
    <ng-container *ngIf="!!assets.length">
      <ui-display-card
        *ngFor="
          let asset of assets
            | filterBy: ['Type'] : checkedTags
            | filterBy: ['Name', 'Type'] : (searchText | removeComma | removeHash) : false
            | orderByImpure: orderBy.value
        "
        [imageSrc]="asset.Thumbnail"
        (imageClick)="viewAsset(asset)"
      >
        <app-editor-card-description
          [asset]="asset"
          [isOwner]="isOwner(asset.Id)"
          [isSold]="isSold(asset.Id)"
        ></app-editor-card-description>
      </ui-display-card>

      <ng-template #empty>
        <div class="empty-list">
          <img src="./../../assets/images/magnifying-glass-color.png" />
          <div class="description">{{ 'community.noResultItems' | translate }}</div>
        </div>
      </ng-template>
    </ng-container>
  </ui-card-list>
</ng-container>

<ng-template #loader>
  <ui-loader></ui-loader>
</ng-template>

@if (queryParams.amount) {
  <div class="title">
    {{ (error ? 'payment.approvalRequestFailed' : 'payment.requestingApproval') | translate }}
    @if (error) {
      <div class="error-description">
        <div class="status">{{ error.status }}</div>
        <div class="error-message">{{ error.error.code }}</div>
        <div class="error-message">{{ error.error.message }}</div>
      </div>
    }
  </div>
}
@if (!queryParams.amount) {
  <div class="title">
    {{ 'payment.paymentRequestFailed' | translate }}
    <div class="error-description">
      <div class="error-message">{{ queryParams.code }}</div>
      <div class="error-message">{{ queryParams.message }}</div>
    </div>
  </div>
}
<ui-loader class="loader"></ui-loader>

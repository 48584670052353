import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss'],
})
export class DomainComponent implements OnInit, OnDestroy {
  constructor(private _router: Router, private _title: Title) {}

  ngOnInit(): void {
    this._title.setTitle('OWNXR | Domain');
  }

  ngOnDestroy(): void {
    this._title.setTitle('OWNXR');
  }

  isActive(url: string) {
    return this._router.isActive(`domain/${url}`, {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  navigateTo(url: string) {
    this._router.navigate(['domain', url]);
  }
}

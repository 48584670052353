import { environment } from '@/app/src/environments/environment';
import { Account, AccountOption, AccountOptionType } from '@/data/src/lib/models/data/account';
import {
  EnterpriseInvitation,
  EnterpriseRole,
  EnterpriseRoleNumeric,
  EnterpriseUser,
  InvitationStatus,
} from '@/data/src/lib/models/data/enterprise';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { AccountService } from '@/data/src/lib/services/account.service';
import { EnterpriseService } from '@/data/src/lib/services/enterprise.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { UrlService } from '@/data/src/lib/services/url.service';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { InformationComponent } from '@/ui/src/lib/modal/information/information.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, forkJoin, interval, map, take } from 'rxjs';
export interface ContextMenuConfig<T> {
  options: Array<{ name: string; shortcut: string; value: string }>;
  data: {
    show: boolean;
    style: any;
    element: any;
    data?: T;
  };
}
@UntilDestroy()
@Component({
  selector: 'app-oxr-collaborator',
  templateUrl: './oxr-collaborator.component.html',
  styleUrls: ['./oxr-collaborator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrCollaboratorComponent implements OnInit {
  limitNumber: number | undefined = undefined; // undefined means unlimited.
  isLoading = true;
  currentLanguage = this._translateService.currentLang;
  accountInfo: Account | undefined;

  enterpriseUsers: EnterpriseUser[] = [];
  enterpriseAccount: AccountOption | null = null;
  invitations: EnterpriseInvitation[] = [];
  enterpriseScenes: XRScene[] = [];

  selectedUserIds: string[] = [];

  data = [...Array(100).keys()];

  selectedAll = false;

  selfContextMenu: ContextMenuConfig<EnterpriseUser> = {
    options: [{ name: 'shared.actions.leave', shortcut: '', value: 'leave' }],
    data: {
      show: false,
      style: {
        position: 'fixed',
        right: '',
        top: '',
      },
      element: null,
      data: undefined,
    },
  };

  tableRowContextMenu: ContextMenuConfig<EnterpriseUser | string> = {
    options: [{ name: 'shared.actions.delete', shortcut: '', value: 'delete' }],
    data: {
      show: false,
      style: {
        position: 'fixed',
        right: '',
        top: '',
      },
      element: null,
      data: undefined,
    },
  };

  pendingListContextMenu: ContextMenuConfig<EnterpriseInvitation> = {
    options: [{ name: 'collaborator.reinvite', shortcut: '', value: 'reinvite' }],
    data: {
      show: false,
      style: {
        position: 'fixed',
        right: '',
        top: '',
      },
      element: null,
      data: undefined,
    },
  };

  spaceFilterId: string = '';

  // Getters
  get disableInvitation() {
    if (!this.limitNumber) {
      return false;
    }
    return this.enterpriseUsers.length >= this.limitNumber ? true : false;
  }

  get invitedCollaborators() {
    return this.enterpriseUsers.length || 0;
  }

  get pendingInvitations() {
    if (this.invitations.length) {
      return this.invitations.filter((item) => item.InvitationStatus === InvitationStatus.Send);
    }
    return [];
  }

  get isOwner() {
    return this.enterpriseAccount?.Role === EnterpriseRole.Owner || false;
  }

  get filteredUsers() {
    if (this.spaceFilterId) {
      return this.enterpriseUsers.filter((user) =>
        user.EnterpriseScenes.some((enterpriseScene) => enterpriseScene.EnterpriseScene.Scene.Id === this.spaceFilterId),
      );
    }
    return this.enterpriseUsers;
  }

  constructor(
    private _router: Router,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _enterpriseService: EnterpriseService,
    private _accountService: AccountService,
    private _accountPanelService: AccountPanelService,
    private _urlService: UrlService,
    private _sceneService: SceneService,
    private _cd: ChangeDetectorRef,
    private _ngZone: NgZone,
  ) {
    this._accountPanelService.currentLang$.pipe(untilDestroyed(this)).subscribe((lang) => (this.currentLanguage = lang));
    this._accountService.activeAccount$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.accountInfo = data;
    });
  }

  ngOnInit(): void {
    this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe(async (data) => {
      if (data && data.Type === AccountOptionType.Enterprise) {
        this.isLoading = true;
        this._cd.detectChanges();

        this.enterpriseAccount = data;

        this.limitNumber = data.EnterpriseContract?.NumberOfCollaborators;

        if (data.Role === EnterpriseRole.Owner) {
          this.tableRowContextMenu.options = [{ name: 'shared.actions.delete', shortcut: '', value: 'delete' }];
        } else {
          this.tableRowContextMenu.options = [{ name: 'shared.actions.leave', shortcut: '', value: 'leave' }];
        }

        if (data.Role === EnterpriseRole.Owner) {
          forkJoin([
            this.getEnterpriseUsers(data.EnterpriseContract?.EnterpriseId).pipe(untilDestroyed(this)),
            this.getInvitations(data.EnterpriseContract?.EnterpriseId).pipe(untilDestroyed(this)),
            this._sceneService.get('', `enterpriseId=${data.EnterpriseContract?.EnterpriseId}`, undefined, true, true),
          ]).subscribe(async ([enterpriseUsers, enterpriseInvitations, enterpriseScenes]) => {
            this.enterpriseScenes = enterpriseScenes;

            await this.getInvitedScenesByUserIds(
              data.EnterpriseContract?.EnterpriseId,
              enterpriseUsers.map((e) => e.UserId),
            ).then(() => {
              this.isLoading = false;
              this._cd.detectChanges();
            });
          });
        } else {
          this.getEnterpriseUsers(data.EnterpriseContract?.EnterpriseId)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              this.isLoading = false;
              this._cd.detectChanges();
            });
        }
      } else {
        this._router.navigateByUrl('oxr/owned');
      }
    });
  }

  getInvitations(enterpriseId): Observable<EnterpriseInvitation[]> {
    return this._enterpriseService.getPendingCollaboratorInvitations(enterpriseId).pipe(
      map((res) => {
        this.invitations = res.sort((a, b) => (a.DateCreated >= b.DateCreated ? -1 : 1));
        return this.invitations;
      }),
      catchError(() => {
        this.invitations = [];
        return [];
      }),
    );
  }

  getEnterpriseUsers(enterpriseId) {
    return this._enterpriseService.getEnterpriseUsers(enterpriseId).pipe(
      map((res) => {
        this.enterpriseUsers =
          res
            .map((enterpriseUser) => ({ ...enterpriseUser, EnterpriseScenes: [] }))
            .sort((a, b) => {
              if (a.Role === EnterpriseRole.Owner) return -1;
              if (b.Role === EnterpriseRole.Owner) return 1;
              return 0;
            }) || [];
        return this.enterpriseUsers;
      }),
      catchError(() => {
        this.enterpriseUsers = [];
        return [];
      }),
    );
  }

  async getInvitedScenesByUserIds(enterpriseId, userIds: string[]) {
    const response = await this._enterpriseService.getInvitedScenesByUserIds(enterpriseId, userIds);
    if (response.length) {
      this.enterpriseUsers.map((user) => {
        const matchedResults = response.filter((entperiseScene) => entperiseScene.UserId === user.UserId);
        if (matchedResults.length > 0) {
          user.EnterpriseScenes = matchedResults;
        }
      });
    }
  }

  toggleContextMenu(event, menu, newData: EnterpriseUser | EnterpriseInvitation | string) {
    if (this[menu]) {
      const { data } = this[menu];
      if (data.show) {
        data.show = false;
      }
      if (data.data !== newData) {
        data.data = newData;
        data.element = event.target;
        data.show = true;
      }
      if (data.show) {
        data.style = {
          position: 'fixed',
          top: event.pageY + 12 + 'px',
          right: `calc(100% - ${event.pageX}px)`,
        };
      } else {
        data.data = null;
      }
      this[menu].data = { ...data };
    }
  }

  onContextMenuSelect(context, menu) {
    const { value } = context;

    const { data } = menu.data;

    switch (value) {
      case 'delete':
        if (data) {
          this.removeUsers(data);
        }
        break;
      case 'leave':
        if (data) {
          this.leaveEnterprise();
        }
        break;
      case 'reinvite':
        this.reInvite(data);
        break;
      default:
    }
    this.closeRowContextMenu(menu);
  }

  closeRowContextMenu(menu, event?) {
    if (event && event?.target) {
      const isTheSameNode = event.target.isSameNode(menu.data.element);
      if (isTheSameNode) return;
    }
    if (menu && menu.data.show) {
      menu.data.show = false;
      menu.data.data = null;
      menu.data.style = {
        position: 'fixed',
        right: '',
        top: '',
      };
    }
  }

  toggleDropdown(event, forceClose = false) {
    if (forceClose) {
      const elements = event.target.querySelector('.dropdown-header.show');
      elements?.classList.remove('show');
      return;
    }
    const parent = event.target.closest('.dropdown-header');
    !!parent && parent.classList.toggle('show');
  }

  openConfirmModal(title, body) {
    const modalRef = this._modalService.open(ConfirmationComponent);

    if (modalRef) {
      modalRef.instance.title = title;
      modalRef.instance.body = body;
      modalRef.instance.confirmAction = this._translateService.instant('shared.confirmation.confirm');
      modalRef.instance.cancelAction = this._translateService.instant('shared.confirmation.cancel');
    }
    return modalRef;
  }

  openInforModal(message, closeAfter = 1000) {
    const modalRef = this._modalService.open(InformationComponent);

    if (modalRef) {
      modalRef.instance.classList = 'collaborator-info-modal';
      modalRef.instance.message = message;

      this._ngZone.runOutsideAngular(() => {
        interval(closeAfter)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            if (modalRef) {
              modalRef.instance.close();
              this._ngZone.run(() => {});
            }
          });
      });
    }
  }

  // Context Menu Handlers

  leaveEnterprise() {
    const modal = this.openConfirmModal(
      this._translateService.instant('shared.confirmation.info'),
      this._translateService.instant('collaborator.leaveGroupConfirmation', {
        groupName: this.enterpriseAccount?.Name,
      }),
    );

    if (modal) {
      modal.result.then(async (result) => {
        if (result) {
          this._accountService.currentAccountOption.next({
            OptionId: `${AccountOptionType.Individual}`,
            Type: AccountOptionType.Individual,
            Id: '',
          });
          await this._enterpriseService.leaveEnterprise(this.enterpriseAccount?.Id);
          this._accountService.setCurrentAccount(`${AccountOptionType.Individual}`);
        }
      });
    }
  }

  removeUsers(payload: EnterpriseUser | string) {
    let users: EnterpriseUser[] = [];
    if (typeof payload === 'string' && payload === 'all') {
      users = this.enterpriseUsers.filter(
        (u) => this.selectedUserIds.includes(u.UserId) && u.UserId !== this.accountInfo?.Id && u.Role !== EnterpriseRole.Owner,
      );
    } else {
      users = [payload as EnterpriseUser];
    }
    if (users && users.length) {
      const user = users[0];
      const body =
        users.length > 1
          ? this._translateService.instant('collaborator.removeMultipleUserConfirm', {
              userName: user?.User?.DisplayName || '',
              n: users.length - 1,
            })
          : this._translateService.instant('collaborator.removeUserConfirm', {
              userName: user?.User?.DisplayName || '',
            });
      const modal = this.openConfirmModal(this._translateService.instant('shared.confirmation.info'), body);

      if (modal) {
        modal.result.then(async (result) => {
          if (result) {
            const response = await this._enterpriseService.removeCollaborators(
              this.enterpriseAccount?.Id,
              users.map((u) => u.UserId),
            );
            if (response) {
              const msg =
                users.length > 1
                  ? this._translateService.instant('collaborator.multipleUserRemoveSuccessMessage', {
                      userName: user?.User?.DisplayName || '',
                      n: users.length - 1,
                    })
                  : this._translateService.instant('collaborator.userRemoveSuccessMessage', {
                      userName: user?.User?.DisplayName || '',
                    });
              this.enterpriseUsers = this.enterpriseUsers.filter((u) => !users.find((rm) => rm.UserId === u.UserId));
              const index = this.selectedUserIds.findIndex((id) => id === user.UserId);
              this.selectedUserIds.splice(index, 1);
              this.openInforModal(msg, 1000);
              this._cd.detectChanges();
            }
          }
        });
      }
    }
  }

  async reInvite(data) {
    if (data) {
      const { EnterpriseId: enterpriseId, Role, ReceiverEmail: receiverEmail } = data;
      const response = await this._enterpriseService.inviteCollaborator({
        enterpriseId,
        role: EnterpriseRoleNumeric[Role as keyof typeof EnterpriseRole],
        receiverEmail,
      });
      if (response) {
        this.openInforModal(this._translateService.instant('collaborator.inviteResendSuccess', { userName: receiverEmail }), 3000);
        this.getInvitations(enterpriseId)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this._cd.detectChanges();
          });
      }
    }
  }

  onInviteCollaborator(isSuccess) {
    isSuccess &&
      this.enterpriseAccount &&
      this.getInvitations(this.enterpriseAccount.Id)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this._cd.detectChanges();
        });
  }

  selectSpaceFilter(spaceId) {
    if (this.spaceFilterId !== spaceId) {
      this.spaceFilterId = spaceId === 'all' ? '' : spaceId;
    }
  }

  trackByFn(index, item) {
    return item.Id;
  }

  selectUser(user: EnterpriseUser) {
    const index = this.selectedUserIds.findIndex((id) => id === user.UserId);
    if (index === -1) {
      this.selectedUserIds.push(user.UserId);
    } else {
      this.selectedUserIds.splice(index, 1);
    }
    if (this.selectedUserIds.length === this.enterpriseUsers.length) {
      this.selectedAll = true;
    } else {
      this.selectedAll = false;
    }
  }

  selectAllChange() {
    if (this.selectedAll) {
      this.selectedAll = false;
      this.selectedUserIds = [];
    } else {
      this.selectedUserIds = this.enterpriseUsers.map((u) => u.UserId);
      this.selectedAll = true;
    }
  }

  openSpace(spaceId) {
    this._urlService.windowOpenWithPlatform(environment.redirectURL + '/oxr/space/' + spaceId);
  }

  onChangeGroupName(changedGroupName) {
    this.enterpriseAccount!.Name = changedGroupName;
    this._cd.detectChanges();
  }
}

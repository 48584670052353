<div class="title" title="{{ asset.Name }}">{{ asset.Name }}</div>

<div class="counts">
  <div class="icon icon-view"></div>
  <!-- {{ (asset.Views | number: '4.0-0' | removeComma) || '0000' }} -->
  <div class="icon icon-like"></div>
  <!-- {{ (asset.Likes | number: '4.0-0' | removeComma) || '0000' }} -->
</div>

<div class="button-group">
  <button class="edit-button" [disabled]="!isOwner" ui-button xs (click)="edit($event, true)">
    {{ 'shared.actions.edit' | translate }}
  </button>

  @if (isSold) {
    <button class="delete-button disabled" ui-outlined-button xs>
      {{ 'editor.sold' | translate }}
    </button>
  } @else {
    <button class="delete-button" [disabled]="!isOwner" ui-outlined-button xs (click)="delete()">
      {{ 'shared.actions.delete' | translate }}
    </button>
  }
</div>

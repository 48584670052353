import { Component, Input, OnInit, Output, EventEmitter, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from '@/app/src/environments/environment';
import { AssetType, TObjectParameters, XRAsset } from '@/data/src/lib/models/data/asset';
import { MarketItem } from '@/data/src/lib/models/data/market';
import { MarketService } from '@/data/src/lib/services/market.service';
import { ElementType } from '@/data/src/lib/view-manager';
import { toastConfigExtension } from '@/ui/src/lib/components/toast/toast-config-extension';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { InformationComponent } from '@/ui/src/lib/modal/information/information.component';
import { ApplicationService } from '@/view/src/app/app.service';
import { interval, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-market-card-preview',
  templateUrl: './market-card-preview.component.html',
  styleUrls: ['./market-card-preview.component.scss'],
})
export class MarketCardPreviewComponent implements OnInit {
  @Input() marketItem!: MarketItem;
  @Input() isLiked!: boolean | undefined;
  @Input() isInCart!: boolean | undefined;
  @Input() isPurchased!: boolean;

  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() removedFromCart: EventEmitter<void> = new EventEmitter();
  @Output() selectTagEvent: EventEmitter<string> = new EventEmitter();
  zommStatus = 'zoomOut';
  canClose = true;

  private _assetPreview: XRAsset | undefined;
  private _viewManagerReady = false;

  @Input() set assetPreview(asset: XRAsset | undefined) {
    this._assetPreview = asset;
    if (!this.viewManagerReady || !this.assetPreview?.versions?.length) {
      return;
    }

    this.assetPreview.Type === AssetType.Environment
      ? this._appService.setEnvironmentElement(this.assetPreview.versions[0])
      : this._appService.setViewElements(
          [
            // IDs are not UUID to avoid unnecessary function calls
            {
              id: this.assetPreview.Id,
              type: ElementType[this.assetPreview.Type],
              name: this.assetPreview.Name,
              parameters: { assetVersionId: this.assetPreview.versions[0].Id },
            },
            ...((this.assetPreview.versions[0].Parameters as TObjectParameters).environment
              ? [
                  {
                    id: this.assetPreview.versions[0].Id,
                    type: ElementType.Environment,
                    name: 'Environment',
                    parameters: (this.assetPreview.versions[0].Parameters as TObjectParameters).environment,
                  },
                ]
              : []),
          ],
          true,
        );
  }

  get assetPreview() {
    return this._assetPreview;
  }

  set viewManagerReady(flag: boolean) {
    this._viewManagerReady = flag;
    if (this.viewManagerReady) {
      this.assetPreview = this._assetPreview;
    }
  }

  get viewManagerReady() {
    return this._viewManagerReady;
  }

  constructor(
    private _appService: ApplicationService,
    private _marketService: MarketService,
    private _toastr: ToastrService,
    private _translateService: TranslateService,
    private _router: Router,
    private _clipboardApi: ClipboardService,
    private _modalService: ModalService,
    private _ngZone: NgZone,
  ) {
    this._appService.viewManagerSubject.pipe(untilDestroyed(this)).subscribe((manager) => {
      if (manager) {
        this.viewManagerReady = true;
      }
    });
  }

  ngOnInit(): void {}

  toggleLike() {
    let message = '';

    if (this.isLiked) {
      this._marketService.deleteLike(this.marketItem.Id).then(() => {
        this.marketItem.Likes = this.marketItem.Likes - 1;
        message = this._translateService.instant('shared.information.removedLike');
        this._toastr.info(message, '', toastConfigExtension({ imageSrc: this.marketItem.Thumbnail }));
        this.isLiked = false;
      });
    } else {
      this._marketService.postLikes([this.marketItem.Id]).then(() => {
        this.marketItem.Likes = this.marketItem.Likes + 1;
        message = this._translateService.instant('shared.information.savedLike');
        const actionLabel = this._translateService.instant('shared.actions.checkNow');
        this._toastr
          .info(
            message,
            '',
            toastConfigExtension({
              enableHtml: true,
              imageSrc: this.marketItem.Thumbnail,
              showExtraAction: true,
              extraActionLabel: actionLabel,
            }),
          )
          .onAction.pipe(untilDestroyed(this))
          .subscribe(() => {
            this.canClose = true;
            this._router.navigate(['/', 'market', 'liked']);
          });
        this.isLiked = true;
      });
    }
  }

  toggleCart(): void {
    let message;

    if (this.isInCart) {
      this._marketService.deleteCart(this.marketItem.Id).then(() => {
        message = this._translateService.instant('shared.information.removedFromCart');
        this._toastr.info(message, '', toastConfigExtension({ imageSrc: this.marketItem.Thumbnail }));
        this.isInCart = false;
        this.removedFromCart.emit();
      });
    } else {
      this.canClose = false;
      this._marketService.postCart([this.marketItem.Id]).then(() => {
        message = this._translateService.instant('shared.information.addedToCart');
        const actionLabel = this._translateService.instant('shared.actions.checkNow');
        this._toastr
          .info(
            message,
            '',
            toastConfigExtension({
              enableHtml: true,
              imageSrc: this.marketItem.Thumbnail,
              showExtraAction: true,
              extraActionLabel: actionLabel,
            }),
          )
          .onAction.pipe(untilDestroyed(this))
          .subscribe(() => {
            this.canClose = true;
            this._router.navigate(['/', 'market', 'cart']);
          });
        this.isInCart = true;
      });
    }
  }

  setZoomStatus(value: string) {
    this.canClose = false;
    this.zommStatus = value;
  }

  onShareClick(): void {
    this._clipboardApi.copy(`${environment.redirectURL}/market/list/shared?assetId=${this.marketItem.AssetId}`);
    const modalRef = this._modalService.open(InformationComponent);

    if (modalRef) {
      modalRef.instance.message = this._translateService.instant('shared.information.linkCopied');

      this._ngZone.runOutsideAngular(() => {
        interval(1000)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            if (modalRef) {
              modalRef.instance.close();
              this._ngZone.run(() => {});
            }
          });
      });
    }
  }

  onSelectTag(event: string) {
    this.selectTagEvent.emit(event);
    this.onClose();
  }

  onClose() {
    if (this.canClose) {
      this.closeEvent.emit(false);
    }
    this.canClose = true;
  }
}

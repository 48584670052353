import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AccountService } from 'projects/data/src/lib/services/account.service';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, tap } from 'rxjs';

interface MenuChild {
  title: string;
  route: string;
}

interface MenuItem {
  title: string;
  expanded: boolean;
  route: string;
  children: MenuChild[];
}

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class GuideComponent implements OnInit, OnDestroy {
  @ViewChild('main') mainEl: ElementRef<HTMLElement>;

  currentLanguage$ = this._accountService.language$;

  searchText = '';
  activeRoute = '';
  menuItems: MenuItem[] = [
    {
      title: 'oxr',
      expanded: true,
      route: '/guide/oxr',
      children: [
        { title: 'guide.oxr.howToUse.sidenav', route: '/guide/oxr' },
        { title: 'guide.oxr.sideMenu.sidenav', route: '/guide/oxr/sideMenu' },
        { title: 'guide.oxr.rightPanel.sidenav', route: '/guide/oxr/rightPanel' },
        { title: 'guide.oxr.toolbar.sidenav', route: '/guide/oxr/toolbar' },
      ],
    },
    {
      title: 'market',
      expanded: false,
      route: '/guide/market',
      children: [
        // { title: 'Market child 1', route: '/guide/market/child1' },
        // { title: 'Market child 2', route: '/guide/market/child2' },
      ],
    },
    {
      title: 'community',
      expanded: false,
      route: '/guide/community',
      children: [
        // { title: 'Community child 1', route: '/guide/community/child1' },
        // { title: 'Community child 2', route: '/guide/community/child2' },
      ],
    },
    {
      title: 'editor',
      expanded: false,
      route: '/guide/editor',
      children: [
        { title: 'guide.editor.howToUse.sidenav', route: '/guide/editor' },
        { title: 'guide.editor.environment.sidenav', route: '/guide/editor/environment' },
        { title: 'guide.editor.stage.sidenav', route: '/guide/editor/stage' },
        { title: 'guide.editor.object.sidenav', route: '/guide/editor/object' },
      ],
    },
    // {
    // title: 'domain',
    // expanded: false,
    // route: '/guide/domain',
    // children: [
    // { title: 'Domain child 1', route: '/guide/domain/child1' },
    // { title: 'Domain child 2', route: '/guide/domain/child2' },
    // ],
    // },
  ];

  displayMenuItems = this.menuItems;

  constructor(private _route: ActivatedRoute, private _router: Router, private _accountService: AccountService, private _title: Title) {}

  ngOnInit(): void {
    this._title.setTitle('OWNXR | Guide');

    const url = this._route['_routerState'].snapshot.url;
    this.activeRoute = url;
    const foundItem = this.displayMenuItems.find((item) => item.route === url || item.children?.some((child) => child.route === url));

    foundItem && this.onToggleExpandedState(foundItem);

    this._router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        tap((e) => {
          this.activeRoute = e.url;
          if (this.mainEl?.nativeElement) {
            this.mainEl.nativeElement.scrollTo({ top: 0 });
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._title.setTitle('OWNXR');
  }

  onSearchChange(value: string) {
    if (!value) {
      this.displayMenuItems = this.menuItems;
    } else {
      this.displayMenuItems = this.menuItems.filter(
        (item) =>
          item.title.toLowerCase().includes(value.toLowerCase()) ||
          item.children?.some((child) => child.title.toLowerCase().includes(value.toLowerCase())),
      );
      if (value.length > 2 && this.displayMenuItems.length < 3) {
        this.displayMenuItems.forEach((item) => {
          if (!item.title.toLowerCase().includes(value.toLowerCase())) {
            item.expanded = true;
          }
        });
      }
    }
  }

  onToggleExpandedState(item: MenuItem) {
    this.menuItems.forEach((menuItem) => (menuItem.expanded = false));
    item.expanded = !item.expanded;
  }
}

import { AccountService } from '@/data/src/lib/services/account.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsOfUse',
  templateUrl: './termsOfUse.component.html',
  styleUrls: ['./termsOfUse.component.scss'],
})
export class TermsOfUseComponent implements OnInit {
  currentLanguage$ = this._accountService.language$;

  constructor(private _accountService: AccountService) {}

  ngOnInit(): void {}
}

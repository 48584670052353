import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnterpriseService } from '@/data/src/lib/services/enterprise.service';
import { EnterpriseInvitation, EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
import { AccountService } from '@/data/src/lib/services/account.service';
import { AccountOption, AccountOptionType } from '@/data/src/lib/models/data/account';
import { SceneService } from '@/data/src/lib/services/scene.service';

@Component({
  selector: 'app-oxr',
  templateUrl: './oxr.component.html',
  styleUrls: ['./oxr.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OxrComponent implements OnInit {
  pendingInvitations: EnterpriseInvitation[] = [];
  currentAccount: AccountOption | null = null;
  isEnterpriseCollaborator: boolean;
  hasScenes: boolean;
  tooltipContent: string;

  constructor(
    private router: Router,
    private _accountService: AccountService,
    private _enterpriseService: EnterpriseService,
    private _sceneService: SceneService,
  ) {
    this.getInvitations();
  }

  ngOnInit(): void {
    this._accountService.currentAccountOption$.subscribe((acc) => {
      this.currentAccount = acc;
      this.isEnterpriseCollaborator = acc?.Role === EnterpriseRole.Collaborator;
      this.changeTooltip();
    });

    this._sceneService.data$.subscribe((scene) => {
      this.hasScenes = !!scene.length;
      this.changeTooltip();
    });
  }

  async getInvitations() {
    const result = await this._enterpriseService.getMyCollaboratorInvitations();
    this.pendingInvitations = result;
  }

  get isEnterpriseAccount() {
    return this.currentAccount && this.currentAccount.Type === AccountOptionType.Enterprise;
  }

  isActive(url: string) {
    if (url === 'dashboard') {
      if (this.isEnterpriseCollaborator || !this.hasScenes) return false;
    }
    return this.router.isActive(`oxr/${url}`, {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  navigateTo(url: string) {
    if (url === 'dashboard') {
      if (this.isEnterpriseCollaborator || !this.hasScenes) return;
    }
    this.router.navigate(['oxr', url]);
  }

  changeTooltip() {
    this.tooltipContent = '';

    if (!this.hasScenes) {
      this.tooltipContent = 'oxr.dashboard.createSceneTooltip';
    }
    if (!!this.isEnterpriseCollaborator) {
      this.tooltipContent = 'oxr.dashboard.accessTooltip';
    }
  }
}

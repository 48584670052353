@if (!setSubdomain) {
  <div class="title">{{ 'domain.connectOwnxrDomain' | translate }}</div>
  <div class="subtitle">{{ 'domain.whichSpaceConnect' | translate }}</div>
  <ui-search-bar [(searchText)]="searchText" placeholder="{{ 'shared.fields.findSpaceToConnect' | translate }}"> </ui-search-bar>
  @if (!loading) {
    @if (itemsPresent) {
      @if ((scenes | filterBy: ['Name', 'Tags', 'Username'] : searchText : false)?.length) {
        <ui-card-list>
          @for (scene of scenes | filterBy: ['Name', 'Tags', 'Username'] : searchText : false | orderByImpure: orderBy.value; track scene) {
            <app-domain-display-card
              [imageSrc]="scene.Thumbnail"
              [scenePlan]="scene.Plan"
              [isExpired]="scene.isExpired"
              [id]="scene.Id"
              [spaceName]="scene.Name"
              (imageClick)="setSelectedScene(scene)"
              [isFreeLock]="scene.Plan === plan.Free"
              [takenScenes]="takenScenes"
              [scene]="scene"
              [isCollaborator]="isCollaborator"
              [isPublic]="scene.IsPublished"
              [isEnterprise]="isEnterprise"
            >
              <app-community-card-description [scene]="scene"></app-community-card-description>
            </app-domain-display-card>
          }
        </ui-card-list>
      } @else {
        <div class="empty-list">
          <img src="./../../assets/images/magnifying-glass-color.png" />
          <div class="description">{{ 'community.noResultItems' | translate }}</div>
        </div>
      }
      <ng-template #empty>
        <div class="empty-list">
          <img src="./../../assets/images/magnifying-glass-color.png" />
          <div class="description">{{ 'community.noResultItems' | translate }}</div>
        </div>
      </ng-template>
    }
  } @else {
    <ui-loader></ui-loader>
  }
}
@if (setSubdomain && selectedScene) {
  <div class="title" [innerHTML]="'domain.connectSpacenameAndOwnxr' | translate: { spacename: selectedScene.Name }"></div>
  <div class="subtitle">{{ 'domain.inputKeyword2' | translate }}</div>
  <ui-auto-size-input
    class="keyword"
    [ngClass]="{ 'input-disabled': inputTouched && (isSwear || !isValid || !isAvailable) }"
    [inplaceStaticText]="domainName"
    placeholder="{{ 'domain.inputKeyword1' | translate }}"
    (searchTextChange)="onReset()"
    (searchTextChangeDebounce)="getInputValue($event)"
    [loading]="loading"
    [value]="domainToChangeSpace ? domainToChangeSpace.Path.split('.')[0] : ''"
    [disabled]="domainToChangeSpace ? true : false"
    [onDebounceTime]="500"
  ></ui-auto-size-input>
  @if (!isClicked) {
    @if (!loading && inputTouched && isSwear) {
      <div class="error-message">{{ 'domain.invalidWord' | translate }}</div>
    }
    @if (!loading && inputTouched && !isValid) {
      <div class="error-message">{{ 'domain.inputEnglishOnly' | translate }}</div>
    }
    @if (!loading && inputTouched && !isAvailable) {
      <div class="error-message">{{ 'domain.exist' | translate }}</div>
    }
    <div class="button-wrapper">
      <button ui-button (click)="onClick()" [disabled]="loading || !inputTouched || isSwear || !isValid || !isAvailable">
        {{ 'domain.completion' | translate }}
      </button>
    </div>
  } @else {
    <ui-loader></ui-loader>
  }
}

<ui-layout>
  @if (!isMappedDomain) {
    <ui-body class="p-0 landing">
      <section class="container">
        <header class="header">
          <div class="ownxr-letter"></div>
          <h1 class="title">{{ 'tempLandingPage.title' | translate }}</h1>
        </header>
        <nav class="navigation">
          <button
            ui-button
            md
            (click)="loginRedirect()"
            uiTooltip="{{ 'header.login.signUp.promoteTooltip' | translate }}"
            [tooltipPosition]="tooltipPosition.Above"
            [tooltipClasses]="'border-highlight'"
            [tooltipAnimation]="tooltipAnimation.VerticalBounce"
          >
            {{ 'tempLandingPage.navigation.startNow' | translate }}
          </button>
          <button ui-button md outlined (click)="goToCommunity()">
            {{ 'tempLandingPage.navigation.community' | translate }}
          </button>
        </nav>
      </section>
    </ui-body>
  } @else {
    <ui-body class="p-0">
      <app-view></app-view>
    </ui-body>
  }
</ui-layout>

<i class="icon icon-edit" (click)="open()"></i>
@if (dialog) {
  <div class="collaborator-modal-wrapper">
    <div class="collaborator-modal">
      <i class="close-button icon icon-close" (click)="close()"></i>
      <div class="header">
        <div class="title">{{ 'collaborator.groupNameEditTitle' | translate }}</div>
      </div>
      <div class="body">
        <div class="form">
          @if (!isLoading) {
            <div [formGroup]="form">
              <div class="form-group">
                <input
                  uiInput
                  formControlName="name"
                  type="text"
                  placeholder="{{ 'collaborator.groupNameEditTitlePlaceholder' | translate }}"
                />
                @if (form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)) {
                  @if (form.controls['name'].errors?.['maxlength']) {
                    <div class="error-msg">
                      {{ 'collaborator.groupNameMaxlength' | translate }}
                    </div>
                  }
                }
              </div>
            </div>
          } @else {
            <ui-loader></ui-loader>
          }
          <button ui-button primary class="action-button" (click)="change()" [disabled]="isLoading || form.invalid">
            {{ 'shared.actions.change' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
}

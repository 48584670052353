import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteParam } from '@/data/src/lib/enums/route-param';
import { ViewComponent } from '@/view/src/app/module/view.component';
import { CardRequestPaymentParam } from './../../../data/src/lib/enums/payment';
import { CommunityLikedListComponent } from './community/community-liked-list/community-liked-list.component';
import { CommunityListComponent } from './community/community-list/community-list.component';
import { CommunityComponent } from './community/community.component';
import { EditorListComponent } from './editor/editor-list/editor-list.component';
import { EditorViewComponent } from './editor/editor-view/editor-view.component';
import { EditorComponent } from './editor/editor.component';
import { LandingComponent } from '../../../ui/src/lib/layout/landing/landing.component';
import { MarketCartListComponent } from './market/market-cart-list/market-cart-list.component';
import { MarketLikedListComponent } from './market/market-liked-list/market-liked-list.component';
import { MarketListComponent } from './market/market-list/market-list.component';
import { MarketOwnedListComponent } from './market/market-owned-list/market-owned-list.component';
import { MarketComponent } from './market/market.component';
import { OxrAnalyticsComponent } from './oxr/oxr-analytics/oxr-analytics.component';
import { OxrDashboardComponent } from './oxr/oxr-dashboard/oxr-dashboard.component';
import { OxrPricingComponent } from './oxr/oxr-pricing/oxr-pricing.component';
import { OxrSpaceComponent } from './oxr/oxr-space/oxr-space.component';
import { OxrComponent } from './oxr/oxr.component';
import { DomainComponent } from './domain/domain.component';
import { DomainSearchComponent } from './domain/domain-search/domain-search.component';
import { DomainOwnedListComponent } from './domain/domain-owned-list/domain-owned-list.component';
import { DomainPageConnectionComponent } from './domain/domain-page-connection/domain-page-connection.component';
import { PaymentResultComponent } from './payment/payment-result/payment-result.component';
import { OxrOwnedComponent } from './oxr/oxr-owned/oxr-owned.component';
import { OxrPurchaseHistoryComponent } from './oxr/oxr-purchase-history/oxr-purchase-history.component';
import { OxrSubscriptionManageComponent } from './oxr/oxr-subscription-manage/oxr-subscription-manage.component';
import { GuideComponent } from './guide/guide.component';
import { DomainSettingOwnxrComponent } from './domain/domain-search/domain-setting-ownxr/domain-setting-ownxr.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './termsOfUse/termsOfUse.component';
import { ApplicationsComponent } from './applications/applications.component';
import { OxrCollaboratorComponent } from './oxr/oxr-collaborator/oxr-collaborator.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { MsalLocaleGuard } from '@/data/src/lib/guards/msalLocaleGuard.guard';
import { ExitWithoutSavingGuard } from '@/data/src/lib/guards/exit-without-saving.guard';
import { TempLandingComponent } from './temp-landing/temp-landing.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriptionPlanComponent } from './subscription/subscription-plan/subscription-plan.component';
import { SuccessComponent } from './subscription/success/success.component';
import { FailureComponent } from './subscription/failure/failure.component';

const routes: Routes = [
  {
    path: `oxr/space/:${RouteParam.Scene}`,
    component: OxrSpaceComponent,
    canActivate: [MsalLocaleGuard],
    canDeactivate: [ExitWithoutSavingGuard],
  },
  {
    path: 'subscription',
    component: SubscriptionComponent,
    children: [
      {
        path: '',
        component: SubscriptionPlanComponent,
      },
      {
        path: 'success',
        component: SuccessComponent,
      },
      {
        path: 'failure',
        component: FailureComponent,
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
  {
    path: 'oxr',
    component: OxrComponent,
    canActivate: [MsalLocaleGuard],
    children: [
      {
        path: 'dashboard',
        component: OxrDashboardComponent,
      },
      {
        path: 'owned',
        component: OxrOwnedComponent,
      },
      {
        path: 'analytics',
        component: OxrAnalyticsComponent,
      },
      {
        path: 'pricing',
        component: OxrPricingComponent,
      },
      {
        path: 'purchaseHistory',
        component: OxrPurchaseHistoryComponent,
      },
      {
        path: 'subscriptionManagement',
        component: OxrSubscriptionManageComponent,
      },
      {
        path: 'collaborator',
        component: OxrCollaboratorComponent,
      },
      {
        path: '**',
        redirectTo: 'owned',
      },
    ],
  },
  {
    path: 'market',
    component: MarketComponent,
    children: [
      {
        path: 'list',
        component: MarketListComponent,
      },
      {
        path: 'list/shared',
        component: MarketListComponent,
      },
      {
        path: 'liked',
        component: MarketLikedListComponent,
        canActivate: [MsalLocaleGuard],
      },
      {
        path: 'cart',
        component: MarketCartListComponent,
        canActivate: [MsalLocaleGuard],
      },
      {
        path: 'owned',
        component: MarketOwnedListComponent,
        canActivate: [MsalLocaleGuard],
      },
      {
        path: '**',
        redirectTo: 'list',
      },
    ],
  },
  {
    path: `community/space/:${RouteParam.Scene}`,
    component: LandingComponent,
    children: [
      {
        path: '',
        component: ViewComponent,
      },
    ],
  },
  {
    path: `view/:${RouteParam.Scene}`,
    component: LandingComponent,
    children: [
      {
        path: '',
        component: ViewComponent,
      },
    ],
  },
  {
    path: 'community',
    component: CommunityComponent,
    children: [
      {
        path: 'list',
        component: CommunityListComponent,
      },
      {
        path: 'liked',
        component: CommunityLikedListComponent,
        canActivate: [MsalLocaleGuard],
      },
      {
        path: '**',
        redirectTo: 'list',
      },
    ],
  },
  {
    path: `editor/asset/:${RouteParam.Asset}`,
    canActivate: [MsalLocaleGuard],
    canDeactivate: [ExitWithoutSavingGuard],
    component: EditorViewComponent,
  },
  {
    path: 'editor',
    canActivate: [MsalLocaleGuard],
    component: EditorComponent,
    children: [
      {
        path: 'list',
        component: EditorListComponent,
      },
      {
        path: '**',
        redirectTo: 'list',
      },
    ],
  },
  {
    path: 'domain',
    component: DomainComponent,
    canActivate: [MsalLocaleGuard],
    children: [
      {
        path: 'search',
        component: DomainSearchComponent,
      },
      {
        path: 'search/setting/ownxr',
        component: DomainSettingOwnxrComponent,
      },
      // {
      //   path: 'search/setting/buy',
      //   component: DomainSettingBuyComponent,
      // },
      // {
      //   path: 'search/setting/connection',
      //   component: DomainSettingConnectionComponent,
      // },
      {
        path: 'owned',
        component: DomainOwnedListComponent,
      },
      {
        path: 'connection',
        component: DomainPageConnectionComponent,
      },
      {
        path: '**',
        redirectTo: 'search',
      },
    ],
  },
  {
    path: 'applications',
    component: ApplicationsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'applications',
      },
      {
        path: '**',
        component: ApplicationsComponent,
      },
    ],
  },
  {
    path: 'guide',
    component: GuideComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'oxr',
      },
      {
        path: '**',
        component: GuideComponent,
      },
    ],
  },
  {
    path: `paymentResult/:${CardRequestPaymentParam.OrderId}/:${CardRequestPaymentParam.PaymentKey}/:${CardRequestPaymentParam.Amount}/:${CardRequestPaymentParam.Code}/:${CardRequestPaymentParam.Message}`,
    canActivate: [MsalLocaleGuard],
    component: PaymentResultComponent,
  },
  {
    path: 'paymentResult',
    canActivate: [MsalLocaleGuard],
    component: PaymentResultComponent,
  },
  { path: 'termsOfUse', component: TermsOfUseComponent },
  {
    path: 'privacyPolicy',
    component: PrivacyPolicyComponent,
  },
  {
    /* Temporary Page to set server after sign up
       Only load this module when necessary (first login) */
    path: 'login',
    canActivate: [MsalLocaleGuard],
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'view',
    loadChildren: () => import('./../../../view/src/app/module/view.module').then((m) => m.ViewModule),
  },
  {
    path: '',
    component: TempLandingComponent,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', initialNavigation: 'enabledBlocking', useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

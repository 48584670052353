@if (isLoading) {
  <ui-loader></ui-loader>
} @else {
  <section class="title">
    <div class="group-info">
      <div class="avatar"></div>
      @if (enterpriseAccount) {
        <div class="group-name">
          {{ enterpriseAccount.Name }}
          @if (isOwner) {
            <app-collaborator-group-edit
              [enterpriseAccount]="enterpriseAccount"
              (onChange)="onChangeGroupName($event)"
            ></app-collaborator-group-edit>
          }
        </div>
      }
    </div>
    @if (isOwner) {
      <app-collaborator-invitation
        [enterpriseId]="enterpriseAccount?.Id || ''"
        (onInvite)="onInviteCollaborator($event)"
        [disabled]="disableInvitation"
      ></app-collaborator-invitation>
    }
  </section>
  <section class="data">
    <section class="right-col" #rightCol>
      <div class="data-table" (scroll)="closeRowContextMenu('tableRowContextMenu')">
        <div class="table-header">
          <div>
            <ui-check-box
              class="sm outlined"
              [checked]="selectedAll"
              [disabled]="!isOwner"
              (checkedChange)="selectAllChange()"
            ></ui-check-box>
          </div>
          <div>{{ 'collaborator.collaborator' | translate }}</div>
          <div>{{ 'collaborator.role' | translate }}</div>
          <div class="dropdown-header" (click)="toggleDropdown($event)" (uiClickOutside)="toggleDropdown($event, true)">
            <span>{{ 'collaborator.invitationSpace' | translate }}</span>
            @if (!!enterpriseScenes.length) {
              <i class="icon icon-dropdown"></i>
              <div class="dropdown-header-options">
                <div class="option" [class.active]="!spaceFilterId" (click)="selectSpaceFilter('all')">
                  {{ 'collaborator.all' | translate }}
                </div>
                @for (scene of enterpriseScenes; track scene) {
                  <div class="option" [class.active]="spaceFilterId === scene.Id" (click)="selectSpaceFilter(scene.Id)">
                    {{ scene.Name }}
                  </div>
                }
              </div>
            }
          </div>
          <div class="action-col" [class.disabled]="!isOwner || !selectedUserIds.length">
            <div class="icon icon-view-more sm" (click)="toggleContextMenu($event, 'tableRowContextMenu', 'all')"></div>
          </div>
        </div>
        @if (!!filteredUsers && !!filteredUsers.length) {
          <div class="table-body">
            @for (user of filteredUsers; track user) {
              <div class="table-row">
                <div>
                  <ui-check-box
                    class="sm outlined"
                    [checked]="selectedUserIds.includes(user.UserId)"
                    [disabled]="!isOwner"
                    (checkedChange)="selectUser(user)"
                  ></ui-check-box>
                </div>
                <div class="left cell-with-avatar">
                  <div class="avatar"></div>
                  @if (user.User) {
                    <div class="content">
                      <div>{{ user.User.Alias }}</div>
                      <div class="sub-text">{{ user.User.DisplayName }}</div>
                    </div>
                  }
                </div>
                <div>{{ user.Role }}</div>
                @if (!!user.EnterpriseScenes.length) {
                  @if (user.EnterpriseScenes.length > 1) {
                    <div
                      class="dropdown-header user-scenes"
                      (click)="toggleDropdown($event)"
                      (uiClickOutside)="toggleDropdown($event, true)"
                    >
                      <span> {{ user.EnterpriseScenes[0].EnterpriseScene.Scene.Name }} +{{ user.EnterpriseScenes.length - 1 }}</span>
                      <div class="dropdown-header-options">
                        <div class="list-title">
                          <span>{{ 'collaborator.invitationListScene' | translate }}</span>
                          <i class="icon icon-close xxs" (click)="toggleDropdown($event, true)"></i>
                        </div>
                        <ul class="list-body">
                          @for (eScene of user.EnterpriseScenes; track eScene) {
                            <li class="list-item" (click)="openSpace(eScene.EnterpriseScene.Scene.Id)">
                              {{ eScene.EnterpriseScene.Scene.Name }}
                            </li>
                          }
                        </ul>
                      </div>
                    </div>
                  }
                  @if (user.EnterpriseScenes.length === 1) {
                    <div>
                      {{ user.EnterpriseScenes[0].EnterpriseScene.Scene.Name }}
                    </div>
                  }
                } @else {
                  <div></div>
                }
                <ng-template #emptySpace>
                  <div></div>
                </ng-template>
                <div class="action-col" [class.disabled]="!isOwner && accountInfo?.Id !== user.User.Id">
                  <div
                    class="icon icon-view-more sm"
                    (click)="toggleContextMenu($event, accountInfo?.Id !== user.User.Id ? 'tableRowContextMenu' : 'selfContextMenu', user)"
                  ></div>
                </div>
              </div>
            }
          </div>
        } @else {
          <div class="no-data" [innerHTML]="'collaborator.collaboratorEmpty' | translate"></div>
        }
        <ng-template #noData>
          <div class="no-data" [innerHTML]="'collaborator.collaboratorEmpty' | translate"></div>
        </ng-template>
      </div>
    </section>
    <section class="left-col" #leftCol>
      <div class="available-collaborator">
        <div class="data-list-name">
          <div><i class="icon icon-community"></i>{{ 'collaborator.availableCollaborator' | translate }}</div>
          @if (limitNumber !== -1) {
            <div>{{ invitedCollaborators }}/{{ limitNumber }}</div>
          }
          @if (limitNumber === -1) {
            <div>{{ invitedCollaborators }}/{{ 'oxr.enterpriseSpace.unlimited' | translate }}</div>
          }
        </div>
      </div>
      <div class="pending-list" (scroll)="closeRowContextMenu('pendingListContextMenu')">
        <div class="data-list-name">
          <div><i class="icon icon-mail"></i> {{ 'collaborator.pendingInvitationList' | translate }}</div>
          <div>{{ pendingInvitations.length }}</div>
        </div>
        @if (!!pendingInvitations && !!pendingInvitations.length) {
          <div class="data-list">
            @for (invitation of pendingInvitations; track trackByFn($index, invitation)) {
              <div class="data-list-item">
                <div class="col-1">{{ invitation.ReceiverEmail }}</div>
                <div class="col-2">
                  <div class="date-string">{{ invitation.DateCreated | dateDiff: currentLanguage }}</div>
                  <div
                    class="icon icon-view-more sm action-button"
                    (click)="toggleContextMenu($event, 'pendingListContextMenu', invitation)"
                  ></div>
                </div>
              </div>
            }
          </div>
        } @else {
          <div class="no-data" [innerHTML]="'collaborator.pendingEmpty' | translate"></div>
        }
        <ng-template #noPendingData>
          <div class="no-data" [innerHTML]="'collaborator.pendingEmpty' | translate"></div>
        </ng-template>
      </div>
    </section>
  </section>
  @if (selfContextMenu.data.show) {
    <ui-context-menu
      class="data-context-menu"
      [data]="selfContextMenu.options"
      [ngStyle]="selfContextMenu.data.style"
      (hide)="closeRowContextMenu(selfContextMenu, $event)"
      (onSelect)="onContextMenuSelect($event, selfContextMenu)"
    ></ui-context-menu>
  }
  @if (tableRowContextMenu.data.show) {
    <ui-context-menu
      class="data-context-menu"
      [data]="tableRowContextMenu.options"
      [ngStyle]="tableRowContextMenu.data.style"
      (hide)="closeRowContextMenu(tableRowContextMenu, $event)"
      (onSelect)="onContextMenuSelect($event, tableRowContextMenu)"
    ></ui-context-menu>
  }
  @if (pendingListContextMenu.data.show) {
    <ui-context-menu
      class="data-context-menu"
      [data]="pendingListContextMenu.options"
      [ngStyle]="pendingListContextMenu.data.style"
      (hide)="closeRowContextMenu(pendingListContextMenu, $event)"
      (onSelect)="onContextMenuSelect($event, pendingListContextMenu)"
    ></ui-context-menu>
  }
}
<ng-template #content>
  <section class="title">
    <div class="group-info">
      <div class="avatar"></div>
      @if (enterpriseAccount) {
        <div class="group-name">
          {{ enterpriseAccount.Name }}
          @if (isOwner) {
            <app-collaborator-group-edit
              [enterpriseAccount]="enterpriseAccount"
              (onChange)="onChangeGroupName($event)"
            ></app-collaborator-group-edit>
          }
        </div>
      }
    </div>
    @if (isOwner) {
      <app-collaborator-invitation
        [enterpriseId]="enterpriseAccount?.Id || ''"
        (onInvite)="onInviteCollaborator($event)"
        [disabled]="disableInvitation"
      ></app-collaborator-invitation>
    }
  </section>
  <section class="data">
    <section class="right-col" #rightCol>
      <div class="data-table" (scroll)="closeRowContextMenu('tableRowContextMenu')">
        <div class="table-header">
          <div>
            <ui-check-box
              class="sm outlined"
              [checked]="selectedAll"
              [disabled]="!isOwner"
              (checkedChange)="selectAllChange()"
            ></ui-check-box>
          </div>
          <div>{{ 'collaborator.collaborator' | translate }}</div>
          <div>{{ 'collaborator.role' | translate }}</div>
          <div class="dropdown-header" (click)="toggleDropdown($event)" (uiClickOutside)="toggleDropdown($event, true)">
            <span>{{ 'collaborator.invitationSpace' | translate }}</span>
            @if (!!enterpriseScenes.length) {
              <i class="icon icon-dropdown"></i>
              <div class="dropdown-header-options">
                <div class="option" [class.active]="!spaceFilterId" (click)="selectSpaceFilter('all')">
                  {{ 'collaborator.all' | translate }}
                </div>
                @for (scene of enterpriseScenes; track scene) {
                  <div class="option" [class.active]="spaceFilterId === scene.Id" (click)="selectSpaceFilter(scene.Id)">
                    {{ scene.Name }}
                  </div>
                }
              </div>
            }
          </div>
          <div class="action-col" [class.disabled]="!isOwner || !selectedUserIds.length">
            <div class="icon icon-view-more sm" (click)="toggleContextMenu($event, 'tableRowContextMenu', 'all')"></div>
          </div>
        </div>
        @if (!!filteredUsers && !!filteredUsers.length) {
          <div class="table-body">
            @for (user of filteredUsers; track user) {
              <div class="table-row">
                <div>
                  <ui-check-box
                    class="sm outlined"
                    [checked]="selectedUserIds.includes(user.UserId)"
                    [disabled]="!isOwner"
                    (checkedChange)="selectUser(user)"
                  ></ui-check-box>
                </div>
                <div class="left cell-with-avatar">
                  <div class="avatar"></div>
                  @if (user.User) {
                    <div class="content">
                      <div>{{ user.User.Alias }}</div>
                      <div class="sub-text">{{ user.User.DisplayName }}</div>
                    </div>
                  }
                </div>
                <div>{{ user.Role }}</div>
                @if (!!user.EnterpriseScenes.length) {
                  @if (user.EnterpriseScenes.length > 1) {
                    <div
                      class="dropdown-header user-scenes"
                      (click)="toggleDropdown($event)"
                      (uiClickOutside)="toggleDropdown($event, true)"
                    >
                      <span> {{ user.EnterpriseScenes[0].EnterpriseScene.Scene.Name }} +{{ user.EnterpriseScenes.length - 1 }}</span>
                      <div class="dropdown-header-options">
                        <div class="list-title">
                          <span>{{ 'collaborator.invitationListScene' | translate }}</span>
                          <i class="icon icon-close xxs" (click)="toggleDropdown($event, true)"></i>
                        </div>
                        <ul class="list-body">
                          @for (eScene of user.EnterpriseScenes; track eScene) {
                            <li class="list-item" (click)="openSpace(eScene.EnterpriseScene.Scene.Id)">
                              {{ eScene.EnterpriseScene.Scene.Name }}
                            </li>
                          }
                        </ul>
                      </div>
                    </div>
                  }
                  @if (user.EnterpriseScenes.length === 1) {
                    <div>
                      {{ user.EnterpriseScenes[0].EnterpriseScene.Scene.Name }}
                    </div>
                  }
                } @else {
                  <div></div>
                }
                <ng-template #emptySpace>
                  <div></div>
                </ng-template>
                <div class="action-col" [class.disabled]="!isOwner && accountInfo?.Id !== user.User.Id">
                  <div
                    class="icon icon-view-more sm"
                    (click)="toggleContextMenu($event, accountInfo?.Id !== user.User.Id ? 'tableRowContextMenu' : 'selfContextMenu', user)"
                  ></div>
                </div>
              </div>
            }
          </div>
        } @else {
          <div class="no-data" [innerHTML]="'collaborator.collaboratorEmpty' | translate"></div>
        }
        <ng-template #noData>
          <div class="no-data" [innerHTML]="'collaborator.collaboratorEmpty' | translate"></div>
        </ng-template>
      </div>
    </section>
    <section class="left-col" #leftCol>
      <div class="available-collaborator">
        <div class="data-list-name">
          <div><i class="icon icon-community"></i>{{ 'collaborator.availableCollaborator' | translate }}</div>
          @if (limitNumber !== undefined) {
            <div>{{ invitedCollaborators }}/{{ limitNumber }}</div>
          } @else {
            <div>{{ invitedCollaborators }}/{{ 'oxr.enterpriseSpace.unlimited' | translate }}</div>
          }
        </div>
      </div>
      <div class="pending-list" (scroll)="closeRowContextMenu('pendingListContextMenu')">
        <div class="data-list-name">
          <div><i class="icon icon-mail"></i> {{ 'collaborator.pendingInvitationList' | translate }}</div>
          <div>{{ pendingInvitations.length }}</div>
        </div>
        @if (!!pendingInvitations && !!pendingInvitations.length) {
          <div class="data-list">
            @for (invitation of pendingInvitations; track trackByFn($index, invitation)) {
              <div class="data-list-item">
                <div class="col-1">{{ invitation.ReceiverEmail }}</div>
                <div class="col-2">
                  <div class="date-string">{{ invitation.DateCreated | dateDiff: currentLanguage }}</div>
                  <div
                    class="icon icon-view-more sm action-button"
                    (click)="toggleContextMenu($event, 'pendingListContextMenu', invitation)"
                  ></div>
                </div>
              </div>
            }
          </div>
        } @else {
          <div class="no-data" [innerHTML]="'collaborator.pendingEmpty' | translate"></div>
        }
        <ng-template #noPendingData>
          <div class="no-data" [innerHTML]="'collaborator.pendingEmpty' | translate"></div>
        </ng-template>
      </div>
    </section>
  </section>
  @if (selfContextMenu.data.show) {
    <ui-context-menu
      class="data-context-menu"
      [data]="selfContextMenu.options"
      [ngStyle]="selfContextMenu.data.style"
      (hide)="closeRowContextMenu(selfContextMenu, $event)"
      (onSelect)="onContextMenuSelect($event, selfContextMenu)"
    ></ui-context-menu>
  }
  @if (tableRowContextMenu.data.show) {
    <ui-context-menu
      class="data-context-menu"
      [data]="tableRowContextMenu.options"
      [ngStyle]="tableRowContextMenu.data.style"
      (hide)="closeRowContextMenu(tableRowContextMenu, $event)"
      (onSelect)="onContextMenuSelect($event, tableRowContextMenu)"
    ></ui-context-menu>
  }
  @if (pendingListContextMenu.data.show) {
    <ui-context-menu
      class="data-context-menu"
      [data]="pendingListContextMenu.options"
      [ngStyle]="pendingListContextMenu.data.style"
      (hide)="closeRowContextMenu(pendingListContextMenu, $event)"
      (onSelect)="onContextMenuSelect($event, pendingListContextMenu)"
    ></ui-context-menu>
  }
</ng-template>

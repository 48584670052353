import { ChangeDetectionStrategy, NgZone } from '@angular/core';
import { Component } from '@angular/core';
import { XRScene } from 'projects/data/src/lib/models/data/scene';
import { Input } from '@angular/core';
import { environment } from '@/mobile/src/environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MobileInformationComponent } from '@/ui/src/lib/modal/mobile/m-information/m-information.component';
import { UserRoles } from '@/data/src/lib/enums/user';
import { interval, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'm-community-item',
  templateUrl: './community-item.component.html',
  styleUrls: ['./community-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityItemComponent {
  @Input() scene!: XRScene;
  @Output() imageClick = new EventEmitter<string>();
  isDescriptionExpanded = false;

  get isCreatedByAdmin(): boolean {
    if (this.scene && this.scene.CreatorUser?.UserRoles && this.scene.CreatorUser.UserRoles.length > 0) {
      return this.scene.CreatorUser.UserRoles[0].Role === UserRoles.Admin;
    }
    return false;
  }

  constructor(
    private _clipboardApi: ClipboardService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _ngZone: NgZone,
  ) {}

  onImageClick() {
    this.imageClick.emit(this.scene.Id);
  }

  onShareClick(): void {
    this._clipboardApi.copy(`${environment.redirectURL}/view/${this.scene.Id}`);
    const modalRef = this._modalService.open(MobileInformationComponent);

    if (modalRef) {
      modalRef.instance.message = this._translateService.instant('shared.information.linkCopied');

      this._ngZone.runOutsideAngular(() => {
        interval(1000)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            if (modalRef) {
              modalRef.instance.close();
              this._ngZone.run(() => {});
            }
          });
      });
    }
  }

  toggleDescription() {
    this.isDescriptionExpanded = !this.isDescriptionExpanded;
  }
}

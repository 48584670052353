<ui-search-bar [(searchText)]="searchText"></ui-search-bar>
<ui-search-filter [tags]="tags" [checkedTags]="checkedTags" (tagsChecked)="setCheckedTags($event)">
  <ng-select
    class="small"
    [searchable]="false"
    [clearable]="false"
    placeholder="{{ 'shared.list.orderBy.placeholder' | translate }}"
    [ngModel]="orderBy"
    (ngModelChange)="changeOrderBy($event)"
    [disabled]="loading"
  >
    @for (option of orderingOptions; track option) {
      <ng-option [value]="option">
        <div class="select-option-wrapper">
          {{ 'shared.list.orderBy.' + option.name | translate }}
        </div>
      </ng-option>
    }
  </ng-select>
</ui-search-filter>
@if (!loading) {
  @if (
    (
      marketItems
      | filterBy: ['Type'] : checkedTags
      | filterBy: ['DisplayName', 'Name', 'Description', 'Tags', 'Type', 'Username'] : (searchText | removeComma | removeHash) : false
    )?.length
  ) {
    <ui-card-list>
      @for (
        marketItem of marketItems
          | filterBy: ['Type'] : checkedTags
          | filterBy: ['DisplayName', 'Name', 'Description', 'Tags', 'Type', 'Username'] : (searchText | removeComma | removeHash) : false
          | orderByImpure: orderBy.value;
        track marketItem
      ) {
        <ui-display-card [imageSrc]="marketItem.Thumbnail" (imageClick)="openPreview(marketItem)">
          <app-market-card-description
            [marketItem]="marketItem"
            [isLiked]="liked.includes(marketItem.Id)"
            [isInCart]="cart.includes(marketItem.Id)"
            [isPurchased]="owned.includes(marketItem.Id)"
          ></app-market-card-description>
        </ui-display-card>
      }
    </ui-card-list>
  } @else {
    <div class="empty-list">
      <img src="./../../assets/images/magnifying-glass-color.png" />
      <div class="description">{{ 'market.noResultItems' | translate }}</div>
    </div>
  }
  <ng-template #empty>
    <div class="empty-list">
      <img src="./../../assets/images/magnifying-glass-color.png" />
      <div class="description">{{ 'market.noResultItems' | translate }}</div>
    </div>
  </ng-template>
} @else {
  <ui-loader></ui-loader>
}
@if (showPreview) {
  <app-market-card-preview
    [assetPreview]="assetPreview"
    [marketItem]="marketItem"
    [isLiked]="liked.includes(marketItem.Id)"
    [isInCart]="cart.includes(marketItem.Id)"
    [isPurchased]="owned.includes(marketItem.Id)"
    (closeEvent)="onPreviewClose($event)"
    (selectTagEvent)="onClickTag($event)"
  ></app-market-card-preview>
}

import { AccountService } from '@/data/src/lib/services/account.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  accountInfo$ = this._accountService.activeAccount$;

  constructor(private _accountService: AccountService) {}

  ngOnInit(): void {}

  loginRedirect() {
    this._accountService.loginRedirect();
  }
}

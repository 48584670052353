import { ChangeDetectorRef, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  EffectType,
  ElementType,
  INTERACTABLE_TYPES,
  PopupType,
  TInteractableElement,
  TScreenElement,
  TransformationMode,
  ViewMode,
  ViewTool,
} from '@/data/src/lib/view-manager';
import { ApplicationService } from '@/view/src/app/app.service';
import { TooltipPosition } from '@/ui/src/lib/directive/tooltip.directive';

@UntilDestroy()
@Component({
  selector: 'oxr-toolbar',
  templateUrl: './oxr-toolbar.component.html',
  styleUrls: ['./oxr-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrToolbarComponent implements OnInit {
  modes = ViewMode;
  tools = ViewTool;
  transformationModes = TransformationMode;
  popupTypes = Object.keys(PopupType);
  effectTypes = Object.keys(EffectType);
  currentTransformationMode$ = this._appService.transformationMode$;
  isSelectionActive = false;
  isLightSelected = false;
  isPlayerSelected = false;
  isTransformationDisabled = true;
  isInteractable = false;
  _isSaveDisabled = false;
  toolTipPositions = TooltipPosition;
  dropdownMenu = {
    popupContent: false,
    effect: false,
  };

  selectedElement?: TInteractableElement | TScreenElement;

  @Input() set isSaveDisabled(flag: boolean) {
    this._isSaveDisabled = flag;
  }
  @Input() isCreatingAsset = false;
  @Input() isPublishDisabled = false;
  @Input() isEnterpriseCollaborator: boolean;

  @Output() deleteClicked = new EventEmitter<void>();
  @Output() playerModeClicked = new EventEmitter<void>();
  @Output() thumbnailClicked = new EventEmitter<void>();
  @Output() saveClicked = new EventEmitter<void>();
  @Output() saveAsClicked = new EventEmitter<void>();
  @Output() publishClicked = new EventEmitter<void>();
  @Output() previewClicked = new EventEmitter<void>();

  selectedEvents: string[] = [];

  get isSaveDisabled() {
    return this._isSaveDisabled;
  }

  get isEditorMode() {
    return this._appService.getViewMode() === ViewMode.Editor;
  }

  get isCreatorMode() {
    return this._appService.getViewMode() === ViewMode.Creator;
  }

  get activeToolName() {
    return this._appService.getViewManager()?.tool?.name;
  }

  constructor(
    private _appService: ApplicationService,
    private _cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._appService.selectedElementsSubject.pipe(untilDestroyed(this)).subscribe((elements) => {
      this.isLightSelected = elements.some(({ type }) => type === ElementType.Light);
      this.isPlayerSelected = elements.some(({ type }) => type === ElementType.Checkpoint);
      if (elements.length) {
        this.selectedElement =
          elements.length === 1 && INTERACTABLE_TYPES.includes(elements[0].type) ? (elements[0] as TInteractableElement) : undefined;
        this.isSelectionActive = true;
        this.isTransformationDisabled = elements.length > 1 || this.isLightSelected;
        this.isInteractable = !!this.selectedElement && INTERACTABLE_TYPES.includes(elements[0].type);
      } else {
        this.selectedElement = undefined;
        this.isSelectionActive = false;
        this.isTransformationDisabled = true;
        this.isInteractable = false;
      }
      this._cd.detectChanges();
    });

    this._appService.viewModeSubject.pipe(untilDestroyed(this)).subscribe((_mode) => {
      this._cd.detectChanges();
    });

    this._appService.transformationMode$.pipe(untilDestroyed(this)).subscribe((_mode) => {
      this._cd.detectChanges();
    });
  }

  onUndo() {
    this._appService.undo();
  }

  onRedo() {
    this._appService.redo();
  }

  onSelect() {
    this._appService.setTool(ViewTool.Select);
  }

  onMove() {
    this._appService.setTransformationMode(TransformationMode.Translate);
  }

  onRotate() {
    this._appService.setTransformationMode(TransformationMode.Rotate);
  }

  onScale() {
    this._appService.setTransformationMode(TransformationMode.Scale);
  }

  onProjection() {}

  onRefresh() {
    this._appService.refreshView();
  }

  onThumbnail() {
    this.thumbnailClicked.emit();
  }

  onSave() {
    this.saveClicked.emit();
  }

  onSaveAs() {
    this.saveAsClicked.emit();
  }

  onPreview() {
    this.previewClicked.emit();
  }

  onPublish() {
    this.publishClicked.emit();
  }
}

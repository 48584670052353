import { ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@/app/src/environments/environment';
import { UserRoles } from '@/data/src/lib/enums/user';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { toastConfigExtension } from '@/ui/src/lib/components/toast/toast-config-extension';
import { InformationComponent } from '@/ui/src/lib/modal/information/information.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { Subject, interval, take, takeUntil } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-community-card-description',
  templateUrl: './community-card-description.component.html',
  styleUrls: ['./community-card-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CommunityCardDescriptionComponent implements OnInit, OnDestroy {
  @Input() scene: XRScene;
  @Input() isLiked!: boolean | undefined;

  private _destroy = new Subject<void>();
  isCreatorUserAdmin = false;

  constructor(
    private _sceneService: SceneService,
    private _clipboardApi: ClipboardService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _toastr: ToastrService,
    private _router: Router,
    private _ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    if (this.scene.CreatorUser?.UserRoles && this.scene.CreatorUser.UserRoles.length > 0) {
      this.isCreatorUserAdmin = this.scene?.CreatorUser?.UserRoles[0].Role === UserRoles.Admin;
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  toggleLike() {
    let message = '';

    if (this.isLiked) {
      this._sceneService.deleteLike(this.scene.Id).then(() => {
        this.scene.Likes = this.scene.Likes - 1;
        message = this._translateService.instant('shared.information.removedLike');
        this._toastr.info(message, '', toastConfigExtension({ imageSrc: this.scene.Thumbnail }));
      });
    } else {
      this._sceneService.postLikes([this.scene.Id]).then(() => {
        this.scene.Likes = this.scene.Likes + 1;
        message = this._translateService.instant('shared.information.savedLike');
        const actionLabel = this._translateService.instant('shared.actions.checkNow');
        this._toastr
          .info(
            message,
            '',
            toastConfigExtension({
              enableHtml: true,
              imageSrc: this.scene.Thumbnail,
              showExtraAction: true,
              extraActionLabel: actionLabel,
            }),
          )
          .onAction.pipe(takeUntil(this._destroy))
          .subscribe(() => {
            this._router.navigate(['/', 'community', 'liked']);
          });
      });
    }
  }

  onShareClick(): void {
    this._clipboardApi.copy(`${environment.redirectURL}/view/${this.scene.Id}`);
    const modalRef = this._modalService.open(InformationComponent);

    if (modalRef) {
      modalRef.instance.message = this._translateService.instant('shared.information.linkCopied');

      this._ngZone.runOutsideAngular(() => {
        interval(1000)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            if (modalRef) {
              modalRef.instance.close();
              this._ngZone.run(() => {});
            }
          });
      });
    }
  }
}

@if (!isNewCard) {
  <div class="display-card">
    <section>
      @if (canSelect) {
        <ui-check-box class="selection-checkbox primary sm" [checked]="isSelected" (checkedChange)="toggleSelection($event)"></ui-check-box>
      }
      @if (scenePlan) {
        <div
          class="pricing-plan"
          [ngClass]="{
            enterprise: scenePlan === plans.Enterprise,
            free: scenePlan === plans.Free,
            pro: scenePlan === plans.Pro
          }"
        >
          <div class="icon"></div>
          <div class="text">{{ scenePlan }}</div>
        </div>
      }
      <div class="image" (click)="onImageClick($event)">
        @if (imageSrc) {
          <img [src]="imageSrc" />
        }
      </div>
      <ng-content></ng-content>
    </section>
    @if (isEnterprise && isCollaborator) {
      <div class="locked-section">
        <div class="icon icon-lock"></div>
        <div class="text" [innerHtml]="'oxr.dashboard.permissionBackdrop' | translate"></div>
      </div>
    } @else {
      @if (!isExpired && isFreeLock) {
        <div class="locked-section">
          <div class="icon icon-lock"></div>
          <div class="text" [innerHtml]="'oxr.dashboard.subscriptionBackDrop' | translate"></div>
          <div class="function text-underline" (click)="onPreviewClick()">{{ 'oxr.dashboard.planPreview' | translate }}</div>
        </div>
      } @else {
        @if (!isEnterpriseOrAdmin && isExpired) {
          <div class="locked-section">
            <div class="icon icon-lock"></div>
            <div class="text" [innerHtml]="'oxr.dashboard.resubscribeBackDrop' | translate"></div>
            <div class="function text-underline" (click)="onResubscriptionClick()">{{ 'oxr.dashboard.resubscribe' | translate }}</div>
          </div>
        } @else {
          @if (!isPublic) {
            <div class="locked-section">
              <div class="icon icon-lock"></div>
              <div class="text" [innerHtml]="'oxr.dashboard.unPublishedBackdrop' | translate"></div>
              <div
                class="function text-transform-none"
                (click)="goToPublish()"
                [innerHtml]="'oxr.dashboard.goToPublish' | translate: { spaceName: spaceName }"
              ></div>
            </div>
          } @else {
            @if (isConnected) {
              <div class="taken-section">
                <div class="text" [innerHtml]="'oxr.dashboard.takenSpace' | translate: { spaceName: spaceName }"></div>
                <div class="function text-underline" (click)="onEditDomain()">{{ 'domain.disconnect' | translate }}</div>
              </div>
            }
          }
        }
      }
    }
  </div>
} @else {
  <div class="new-card" [class.disabled]="!canCreate" (click)="onImageClick($event)">
    <div class="icon icon-plus xl"></div>
    @if (!canCreate) {
      <div class="locked-section">
        <div class="icon icon-lock"></div>
        <div class="text" [innerHtml]="'oxr.dashboard.addSpaceInFullVersion' | translate"></div>
        <div class="preview" (click)="onPreviewClick()">{{ 'oxr.dashboard.planPreview' | translate }}</div>
      </div>
    }
  </div>
}

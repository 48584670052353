import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-domain-page-connection',
  templateUrl: './domain-page-connection.component.html',
  styleUrls: ['./domain-page-connection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainPageConnectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

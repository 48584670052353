<div class="container">
  <div class="sub-container">
    <div class="sub-container-title">{{ 'oxr.subscription.subscription' | translate }} {{ 'oxr.subscription.management' | translate }}</div>
    @if (!loading) {
      @for (scenesAndPayment of userPayments.ScenesAndPayments; track scenesAndPayment; let i = $index) {
        <div class="subscriptions">
          <div class="title">
            <div class="left">
              <div class="icon-wrapper">
                <div class="icon"></div>
                {{ scenesAndPayment.ScenePlan.Plan }}
              </div>
              <div class="spacename">{{ scenesAndPayment.Scene.Name }}</div>
            </div>
            @if (
              !scenesAndPayment.Subscription.cancel_at_period_end && scenesAndPayment.Subscription.status == stripeSubscriptionState.Active
            ) {
              <button ui-outlined-button (click)="onUnsubscribe(i)">
                {{ 'oxr.subscription.unsubscribe' | translate }}
              </button>
            } @else {
              <button ui-outlined-button (click)="onResubscribe(i)">
                {{ 'oxr.dashboard.resubscribe' | translate }}
              </button>
            }
            <ng-template #resubscribe>
              <button ui-outlined-button (click)="onResubscribe(i)">
                {{ 'oxr.dashboard.resubscribe' | translate }}
              </button>
            </ng-template>
          </div>
          <div class="header">
            <div class="col1">{{ 'oxr.subscription.periodOfUse' | translate }}</div>
            <div class="col2">{{ 'oxr.subscription.amountOfPayment' | translate }}</div>
            <div class="col3">{{ 'oxr.subscription.nextPaymentDate' | translate }}</div>
            <div class="col4">{{ 'oxr.subscription.nextPaymentMethod' | translate }}</div>
            <div class="col5">{{ 'oxr.subscription.nextPaymentAmount' | translate }}</div>
          </div>
          <div class="body">
            <div class="col1">
              @if (currentLanguage === 'en') {
                <span>Until</span>
              }
              {{
                getDateWithSeconds(
                  scenesAndPayment.Subscription.canceled_at
                    ? scenesAndPayment.Subscription.cancel_at
                    : scenesAndPayment.Subscription.current_period_end
                ) | date: 'YYYY.MM.dd'
              }}
              @if (currentLanguage === 'ko') {
                <span>까지</span>
              }
            </div>
            <div class="col2">${{ returnDiscountAmount(i) }}</div>
            <div class="col3">
              {{ returnNextPaymentDate(scenesAndPayment.Subscription.current_period_end) | date: 'YYYY.MM.dd' }}
            </div>
            <div class="col4">
              {{ scenesAndPayment.Subscription.default_payment_method.card.brand | uppercase }}
            </div>
            <div class="col5">${{ scenesAndPayment.ScenePlanPurchase.Amount }}</div>
          </div>
          @if (scenesAndPayment.Subscription.status === stripeSubscriptionState.Canceled) {
            <div class="locked-section">
              {{ 'oxr.subscription.guide3' | translate }}
            </div>
          }
        </div>
      }
    } @else {
      <ui-loader></ui-loader>
    }
    <div class="sub-container-footer">
      {{ 'oxr.subscription.footerLine1' | translate }}
    </div>
    <div class="sub-container-footer">
      {{ 'oxr.subscription.footerLine2' | translate }}
    </div>
    <div class="sub-container-footer">{{ 'oxr.subscription.footerLine3' | translate }}</div>
    <div class="sub-container-footer">{{ 'oxr.subscription.footerLine4' | translate }}</div>
    <div class="sub-container-footer">
      {{ 'oxr.subscription.footerLine5' | translate }}
    </div>
  </div>
</div>

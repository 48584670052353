<ui-layout>
  <ui-side-bar>
    <ui-side-bar-item
      icon="icon-mobile"
      text="sidenav.mySpace"
      [selected]="isActive('owned')"
      (click)="navigateTo('owned')"
    ></ui-side-bar-item>
    <ui-side-bar-item
      icon="icon-dashboard"
      text="sidenav.dashboard"
      [tooltip]="tooltipContent"
      [disabled]="!!isEnterpriseCollaborator || !hasScenes"
      [selected]="isActive('dashboard')"
      (click)="navigateTo('dashboard')"
    ></ui-side-bar-item>
    <ui-side-bar-item
      icon="icon-analytics"
      text="sidenav.analytics"
      [selected]="isActive('analytics')"
      (click)="navigateTo('analytics')"
    ></ui-side-bar-item>
    <!-- TODO: Remove toggle feature when collaborator is ready to release - 2703 -->
    @if (!!isEnterpriseAccount) {
      <ui-side-bar-item
        icon="icon-collaborate"
        text="sidenav.collaborator"
        [selected]="isActive('collaborator')"
        (click)="navigateTo('collaborator')"
      ></ui-side-bar-item>
    }
  </ui-side-bar>
  <ui-body>
    <router-outlet></router-outlet>
  </ui-body>
</ui-layout>
<ui-oxr-settings></ui-oxr-settings>
<ui-collaborator-invitation-modal [invitations]="pendingInvitations"></ui-collaborator-invitation-modal>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { Account } from '@/data/src/lib/models/data/account';
import { XRAsset } from '@/data/src/lib/models/data/asset';
import { AccountOption } from '@/data/src/lib/models/data/account';
import { MarketItem } from '@/data/src/lib/models/data/market';
import { ListOrderOption } from '@/data/src/lib/models/interfaces/list-order-option';
import { ApplicationService } from '@/view/src/app/app.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { MarketService } from '@/data/src/lib/services/market.service';
import { filter, Subject, take, takeUntil } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-market-list',
  templateUrl: './market-list.component.html',
  styleUrls: ['./market-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MarketListComponent implements OnInit, OnDestroy {
  loading = true;
  searchText = '';
  tags: string[] = ['Environment', 'Landscape', 'Stage', 'Object'];
  checkedTags: string[] = [];

  orderingOptions: ListOrderOption[] = [
    { value: '-Purchases', name: 'mostSold' },
    { value: '-Likes', name: 'mostLiked' },
    { value: '-DateCreated', name: 'newest' },
    { value: 'DateCreated', name: 'oldest' },
    { value: '-Tokens', name: 'highestPrice' },
    { value: 'Tokens', name: 'lowestPrice' },
  ];
  orderBy: ListOrderOption = this.orderingOptions[0];

  marketItem!: MarketItem;
  marketItems: MarketItem[] = [];
  assetPreview!: XRAsset | undefined;
  showPreview = false;

  liked: string[] = [];
  cart: string[] = [];
  owned: string[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private _appService: ApplicationService,
    private _marketService: MarketService,
    private _accountService: AccountService,
    private _activatedRoute: ActivatedRoute,
    private _cd: ChangeDetectorRef,
  ) {
    this._marketService.likes$.pipe(takeUntil(this._destroy$)).subscribe((likes) => {
      this.liked = likes;
    });

    this._marketService.owned$.pipe(takeUntil(this._destroy$)).subscribe((owned) => {
      this.owned = [...owned];
    });

    this._marketService.cart$.pipe(takeUntil(this._destroy$)).subscribe((cart) => {
      this.cart = cart;
    });
  }

  async ngOnInit() {
    this._marketService.getPublicItems(true).then((items) => {
      this.marketItems = items;
      this.loading = false;
    });

    this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe((data: AccountOption | null) => {
      if (data) {
        this.searchText = '';
        this._marketService.getOwned(data.EnterpriseContract?.EnterpriseId);
      }
    });

    this._accountService.activeAccount$
      .pipe(
        filter((data) => !!data),
        take(1),
      )
      .subscribe(async (account: Account | undefined) => {
        if (account) {
          const currentAccountOption = this._accountService.getCurrentAccount();
          this._marketService.getLikes();
          this._marketService.getCart();
          currentAccountOption && this._marketService.getOwned(currentAccountOption.EnterpriseContract?.EnterpriseId);
        }
      });

    this._activatedRoute.queryParams.pipe(takeUntil(this._destroy$)).subscribe((params) => {
      if (params.assetId) {
        this._marketService.getByAssetId(params.assetId).then((data) => {
          this.openPreview(data as MarketItem);
        });
      }
      if (params.selectedTag) {
        this.setCheckedTags([params.selectedTag === 'Background' ? 'Environment' : params.selectedTag]);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  setCheckedTags(tags: string[]) {
    this.checkedTags = [...tags];
    if (tags.includes('Environment')) {
      this.checkedTags.push('Background');
    } else {
      this.checkedTags = this.checkedTags.filter((tag) => tag !== 'Background');
    }
  }

  changeOrderBy(orderBy: ListOrderOption): void {
    this.orderBy = orderBy;
  }

  async openPreview(marketItem: MarketItem) {
    this.marketItem = marketItem;
    this.showPreview = true;
    this.assetPreview = await this._appService.apiv2.getAssetById(marketItem.AssetId);
    await this._marketService.postViews([marketItem]);
  }

  onPreviewClose(event) {
    this.showPreview = event;
    this.assetPreview = undefined;
  }

  onClickTag(event: string) {
    if (event.startsWith('#')) {
      event = event.replace('#', '');
    }
    this.searchText = event.trim();
  }
}

<div class="content">
  @if (showGuide) {
    <m-community-view-control-guide (finished)="onGuideFinished()"></m-community-view-control-guide>
  }
  @if (scene) {
    <div class="view-container">
      @if (!showGuide) {
        <ui-ad-banner [isScenePlanPropertyRestriction]="scenePlan!" [isMobile]="true"></ui-ad-banner>
      }
      <view-canvas [readonly]="true" [activeModel]="scene" [isHandHeldDevice]="true"></view-canvas>
      @if (os === 'Android') {
        <div class="icon icon-fullscreen" (click)="toggleFullScreen()"></div>
      }
      @if (!showGuide && supportsXR) {
        <div class="floating left" [style.top.px]="30" (click)="setVRMode()">
          <div class="icon icon-vr"></div>
        </div>
      }
      <div class="move-joystick noselect" (touchmove)="$event.stopPropagation(); $event.preventDefault()">
        <div class="zone">
          <ui-joystick #staticJoystick [options]="staticOptions" (move)="onMoveStatic($event)" (end)="stopMove()"></ui-joystick>
        </div>
      </div>
      <ui-joystick class="icon icon-jump" [class.portrait]="isPortrait" (pointerdown)="onJump()"></ui-joystick>
      <div class="pan-joystick noselect" [class.portrait]="isPortrait">
        <div class="zone">
          <ui-joystick
            #dynamicJoystick
            [options]="dynamicOptions"
            (move)="onLook($event)"
            (start)="onLookStart($event)"
            (end)="onLookEnd()"
          ></ui-joystick>
        </div>
      </div>
    </div>
  }
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubscriptionPlanCard } from '@/data/src/lib/models/data/subscription';

@Component({
  selector: 'app-subscription-plan-card',
  templateUrl: './subscription-plan-card.component.html',
  styleUrl: './subscription-plan-card.component.scss',
})
export class SubscriptionPlanCardComponent {
  @Input() subscriptionPlanCard: SubscriptionPlanCard;
  @Output() onButtonClick: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}

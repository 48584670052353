<ui-layout>
  <ui-side-bar>
    <ui-side-bar-item
      icon="icon-collection"
      text="sidenav.viewAll"
      [selected]="isActive('list')"
      (click)="navigateTo('list')"
    ></ui-side-bar-item>
    <ui-side-bar-item
      icon="icon-like"
      text="sidenav.favorite"
      [selected]="isActive('liked')"
      (click)="navigateTo('liked')"
    ></ui-side-bar-item>
  </ui-side-bar>

  <ui-body>
    <router-outlet></router-outlet>
  </ui-body>
</ui-layout>

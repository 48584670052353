<div class="container">
  <div class="icon-wrapper close" (click)="close()">
    <div class="icon icon-close md"></div>
  </div>
  <h1>{{ 'oxr.publish.title' | translate | uppercase }}</h1>
  <h2>{{ 'oxr.publish.subtitle' | translate }}</h2>
  <section class="toggle-publish">
    <h2>
      {{ 'oxr.publish.shareToCommunity' | translate }}
      <div class="icon xs icon-info" uiTooltip="{{ 'oxr.publish.chooseIfPublish' | translate }}" oneLine></div>
    </h2>
    <ui-switch
      [onContent]="'oxr.publish.show' | translate"
      [offContent]="'oxr.publish.hide' | translate"
      [checked]="scene.IsAccessible"
      [onShowContentMode]="true"
      (changed)="onAccessibilityChange($event)"
      size="lg"
    ></ui-switch>
  </section>
  @if (subDomain) {
    <section class="card">
      <h3>
        <span>{{ 'oxr.publish.subDomainStatus' | translate }}&nbsp;</span>
        <span>{{ 'domain.list.state.' + subDomain.Status | translate }}</span>
      </h3>
      @if (subDomain.Status === domainStatus.Connected) {
        <span class="text link">
          {{ subDomain.Path }}
          <div class="icon icon-copy sm" (click)="copyToClipboard(subDomain.Path)"></div>
        </span>
      }
    </section>
  } @else {
    @if (!subDomainLoading) {
      <section class="card">
        <span class="text link">
          {{ shareUrl + scene.Id }}
          <div class="icon icon-copy sm" (click)="copyToClipboard(shareUrl + scene.Id)"></div>
        </span>
      </section>
      @if (canConnectToDomain(scene.Plan)) {
        <section class="card sub-domain" (click)="onConnectSubdomain()">
          <h3>
            {{ 'oxr.publish.subDomain' | translate }}
            <div class="icon xs icon-info" uiTooltip="{{ 'oxr.publish.subDomainTooltip' | translate }}" oneLine></div>
          </h3>
        </section>
      }
    } @else {
      <section class="card">
        <ui-loader></ui-loader>
      </section>
    }
  }
  <section class="card">
    <h3>
      {{ 'oxr.publish.existingDomain' | translate }}
      <div class="icon xs icon-info" uiTooltip="{{ 'oxr.publish.existingDomainTooltip' | translate }}" oneLine></div>
    </h3>
    <span class="text">{{ 'shared.information.serviceInPreparation' | translate }}</span>
  </section>
  <button tabindex="-1" ui-button (click)="save()">
    @if (isLoading) {
      <ui-loader sm [loaderColor]="'white'"></ui-loader>
    }
    @if (!isLoading) {
      <span>{{ 'oxr.publish.goToCommunity' | translate }}</span>
    }
  </button>
  <!-- <div class="social">
  <span class="text">{{ 'oxr.publish.share' | translate }}</span>
  <div class="icon icon-facebook"></div>
  <div class="icon icon-twitter"></div>
  <div class="icon icon-instagram"></div>
  <div class="icon icon-mail"></div>
</div> -->
</div>

<div class="left">
  <div uiTooltip="{{ 'toolbarTooltips.undo' | translate }}" [tooltipPosition]="toolTipPositions.Right" tooltipClasses="tooltip">
    <div class="item" (click)="onUndo()">
      <div class="icon icon-undo"></div>
    </div>
  </div>
  <div uiTooltip="{{ 'toolbarTooltips.redo' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div class="item" (click)="onRedo()">
      <div class="icon icon-redo"></div>
    </div>
  </div>
  <div class="divider"></div>
  <div uiTooltip="{{ 'toolbarTooltips.select' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div
      class="item"
      [class.active]="isLightSelected || (activeToolName === tools.Select && !isSelectionActive)"
      [class.disabled]="!isLightSelected && activeToolName === tools.Select && isSelectionActive"
      (click)="onSelect()"
    >
      <div class="icon icon-pointer"></div>
    </div>
  </div>
  <div uiTooltip="{{ 'toolbarTooltips.move' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div
      class="item"
      [class.active]="
        activeToolName === tools.Select &&
        !isLightSelected &&
        isSelectionActive &&
        (currentTransformationMode$ | async) === transformationModes.Translate
      "
      [class.disabled]="isLightSelected || !isSelectionActive"
      (click)="onMove()"
    >
      <div class="icon icon-move"></div>
    </div>
  </div>
  <div uiTooltip="{{ 'toolbarTooltips.rotate' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div
      class="item"
      [class.active]="
        activeToolName === tools.Select &&
        !isLightSelected &&
        isSelectionActive &&
        (currentTransformationMode$ | async) === transformationModes.Rotate
      "
      [class.disabled]="isLightSelected || !isSelectionActive"
      (click)="onRotate()"
    >
      <div class="icon icon-rotate-cw"></div>
    </div>
  </div>
  <div uiTooltip="{{ 'toolbarTooltips.scale' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div
      class="item"
      [class.active]="
        activeToolName === tools.Select &&
        !isLightSelected &&
        isSelectionActive &&
        (currentTransformationMode$ | async) === transformationModes.Scale
      "
      [class.disabled]="isLightSelected || !isSelectionActive || isPlayerSelected"
      (click)="onScale()"
    >
      <div class="icon icon-resize"></div>
    </div>
  </div>
  <ng-content></ng-content>
  <!-- <div uiTooltip="{{ 'toolbarTooltips.projection' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
  <div class="item disabled" (click)="onProjection()">
    <div class="icon icon-box"></div>
  </div>
</div> -->
</div>
<div class="right">
  @if (isCreatorMode) {
    <div uiTooltip="{{ 'shared.actions.refreshView' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
      <div class="item" (click)="onRefresh()">
        <div class="icon icon-refresh"></div>
      </div>
    </div>
  }
  <div uiTooltip="{{ 'shared.actions.preview' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
    <div class="item" title="{{ 'shared.actions.preview' | translate }}" (click)="onPreview()">
      <div class="icon icon-preview"></div>
    </div>
  </div>
  @if (isCreatorMode) {
    <div
      uiTooltip="{{ 'toolbarTooltips.captureThumbnail' | translate }}"
      [tooltipPosition]="toolTipPositions.Below"
      tooltipClasses="tooltip"
    >
      <div class="item" (click)="onThumbnail()">
        <div class="icon icon-image"></div>
      </div>
    </div>
    <div uiTooltip="{{ 'toolbarTooltips.save' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
      <div class="item" [class.disabled]="isSaveDisabled" (click)="onSave()">
        <div class="icon icon-save"></div>
      </div>
    </div>
    <div
      uiTooltip="{{
        (isEnterpriseCollaborator ? 'toolbarTooltips.cannotPublishForCollaborator' : 'toolbarTooltips.publishSpace') | translate
      }}"
      [tooltipPosition]="toolTipPositions.Below"
      tooltipClasses="tooltip"
    >
      <div
        class="item"
        [class.disabled]="isPublishDisabled"
        title="{{
          (isEnterpriseCollaborator ? 'toolbarTooltips.cannotPublishForCollaborator' : 'toolbarTooltips.publishSpace') | translate
        }}"
        (click)="onPublish()"
      >
        <div class="icon icon-upload"></div>
      </div>
    </div>
  }
  @if (isEditorMode) {
    <div uiTooltip="{{ 'shared.actions.save' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
      <div class="item" title="{{ 'shared.actions.save' | translate }}" (click)="onSave()">
        <div class="icon icon-save"></div>
      </div>
    </div>
    @if (!isCreatingAsset) {
      <div uiTooltip="{{ 'shared.actions.saveAs' | translate }}" [tooltipPosition]="toolTipPositions.Below" tooltipClasses="tooltip">
        <div class="item" title="{{ 'shared.actions.saveAs' | translate }}" (click)="onSaveAs()">
          <div class="icon icon-save-as"></div>
        </div>
      </div>
    }
  }
</div>

<ui-search-bar [(searchText)]="searchText"> </ui-search-bar>
@if (!loading && isEnterprise && scenes !== undefined) {
  @if (currentAccount?.Role === enterpriseRole.Owner) {
    <span class="space-count">
      <span [innerHTML]="'oxr.enterpriseSpace.used' | translate"></span>
      <span class="count pink">{{ scenes.length }}</span>
      <span class="vertical-line"> </span>
      <span [innerHTML]="'oxr.enterpriseSpace.available' | translate"></span>
      <span class="count">{{ sceneLeft ? sceneLeft : ('oxr.enterpriseSpace.unlimited' | translate) }}</span>
    </span>
  }
  @if (currentAccount?.Role === enterpriseRole.Collaborator) {
    <span class="space-count">
      <span [innerHTML]="'oxr.enterpriseSpace.invited' | translate"></span>
      <span class="count pink">{{ scenes.length }}</span>
    </span>
  }
}
<ui-search-filter>
  <ng-select
    class="small"
    [searchable]="false"
    [clearable]="false"
    placeholder="{{ 'shared.list.orderBy.placeholder' | translate }}"
    [ngModel]="orderBy"
    (ngModelChange)="changeOrderBy($event)"
    [disabled]="!!loading"
  >
    @for (option of orderingOptions; track option) {
      <ng-option [value]="option">
        <div class="select-option-wrapper">
          {{ 'shared.list.orderBy.' + option.name | translate }}
        </div>
      </ng-option>
    }
  </ng-select>
</ui-search-filter>
@if (!loading && scenes !== undefined) {
  @if (scenes.length > 0) {
    @if (scenes && (scenes | filterBy: ['Name', 'Description', 'Tags'] : (searchText | removeComma | removeHash) : false)?.length) {
      <ui-card-list>
        @if (currentAccount?.Role !== enterpriseRole.Collaborator) {
          <ui-display-card [isNewCard]="true" [canCreate]="true" (imageClick)="createNewScene()"></ui-display-card>
        }
        @for (
          scene of scenes
            | filterBy: ['Name', 'Description', 'Tags'] : (searchText | removeComma | removeHash) : false
            | orderByImpure: orderBy.value;
          track scene
        ) {
          <ui-display-card
            [id]="scene.Id"
            [imageSrc]="scene.Thumbnail"
            [scenePlan]="scene.Plan"
            [isExpired]="scene.isExpired && scene.Plan !== plan.Free"
            (imageClick)="setSelectedScene(scene.Id, $event)"
          >
            <app-oxr-card-description
              [isCollaborator]="currentAccount?.Role === enterpriseRole.Collaborator"
              [scene]="scene"
            ></app-oxr-card-description>
          </ui-display-card>
        }
      </ui-card-list>
    } @else {
      <div class="empty-list">
        <img src="./../../assets/images/magnifying-glass-color.png" />
        <div class="description">{{ 'market.noResultItems' | translate }}</div>
      </div>
    }
    <ng-template #empty>
      <div class="empty-list">
        <img src="./../../assets/images/magnifying-glass-color.png" />
        <div class="description">{{ 'market.noResultItems' | translate }}</div>
      </div>
    </ng-template>
  } @else {
    <ui-card-list>
      @if (currentAccount?.Role !== enterpriseRole.Collaborator) {
        <ui-display-card [isNewCard]="true" [canCreate]="true" (imageClick)="createNewScene()"></ui-display-card>
      }
    </ui-card-list>
  }
} @else {
  <ui-loader></ui-loader>
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { filter, retry, Subject, takeUntil } from 'rxjs';
import * as uuid from 'uuid';
import { createUsernameExistsValidator } from '@/ui/src/lib/validators/username-exists.validator';
import { Account } from '@/data/src/lib/models/data/account';
import { AccountService, Region } from '@/data/src/lib/services/account.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { MAX_CHARACTERS } from '@/data/src/lib/enums/max-length';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';

@Component({
  selector: 'm-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  account: Account | undefined;
  userName: string;

  loading = false;
  scenes: XRScene[] = [];
  updateSceneName = false;

  private _destroy$ = new Subject<void>();

  get hasServer() {
    return this._accountService.account?.Server ? true : false;
  }

  get usernameError(): boolean {
    return this.form.get('Username')?.errors ? true : false;
  }

  get usernameExitstsError(): boolean {
    return this.form.get('Username')?.errors?.usernameExists;
  }

  get usernameMaxlengthError(): boolean {
    return this.form.get('Username')?.errors?.maxlength;
  }

  get usernamePatternError(): boolean {
    return this.form.get('Username')?.errors?.pattern;
  }

  constructor(
    private _router: Router,
    private _accountService: AccountService,
    private _accountPanelService: AccountPanelService,
    private _sceneService: SceneService,
  ) {
    this._sceneService.data$
      .pipe(
        filter((data) => !!data),
        takeUntil(this._destroy$),
      )
      .subscribe((scenes) => {
        this.scenes = scenes;
      });
    this._sceneService.getAll();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      Username: new UntypedFormControl(
        { value: '', disabled: this.hasServer },
        [Validators.required, Validators.maxLength(MAX_CHARACTERS.Default), Validators.pattern('[a-zA-Z0-9_\\-\\.]*')],
        [createUsernameExistsValidator(this._accountPanelService)],
      ),
      Server: new UntypedFormControl(
        { value: this._accountService.region === 'kr' ? Region.kr : Region.us, disabled: this.hasServer },
        Validators.required,
      ),
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  async onSave() {
    if (!this.hasServer) {
      this.loading = true;
      await this._accountPanelService.updateAccount(this.form.getRawValue());
      await this._accountService.setRegion();

      if (this.updateSceneName) {
        const name = this.form.get('Username')?.value + "'s Pro Trial";
        this.scenes.forEach(async (scene) => {
          if (!scene.Name) {
            scene.Name = name;
            await this._sceneService.putName(scene);
          }
        });
      }

      this.loading = false;
      this._router.navigate(['community']);
    }
  }
}

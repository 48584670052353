<ui-search-bar [(searchText)]="searchText"></ui-search-bar>
<div class="market-list__checkbox_group d-flex align-center">
  <ui-search-filter [checkedTags]="checkedTags" [tags]="tags" (tagsChecked)="setCheckedTags($event)" [isResettable]="false">
  </ui-search-filter>
  <div class="divider"></div>
  <ui-search-filter
    [checkedTags]="checkedAssetTags"
    [tags]="assetFilters"
    (tagsChecked)="setAssetCheckedTags($event)"
    [isResettable]="false"
  ></ui-search-filter>
  <button class="reset-tags-btn" [disabled]="!checkedTags.length && !checkedAssetTags.length" (click)="resetAllFilters()">
    <div class="reset-tags-btn__icon icon-circle-movement"></div>
    <span>{{ 'shared.filters.reset' | translate }}</span>
  </button>
</div>
@if (!loading) {
  <div class="market-list__checkbox_group d-flex align-center justify-space-between">
    <ui-check-box
      class="selection-checkbox primary sm"
      [checked]="isSelectedAll"
      (checkedChange)="toggleSelectAll($event)"
      [disabled]="!filteredIds.length"
    >
      {{ 'shared.fields.selectAll' | translate }}
    </ui-check-box>
  </div>
  @if (
    (
      assets
      | filterBy: ['Id'] : ngPipeIds
      | filterBy: ['Type'] : checkedTags
      | filterBy: ['DisplayName', 'Name', 'Description', 'Tags', 'Type', 'Username'] : (searchText | removeComma | removeHash) : false
    )?.length
  ) {
    <ui-card-list>
      @for (
        asset of assets
          | filterBy: ['Id'] : ngPipeIds
          | filterBy: ['Type'] : checkedTags
          | filterBy: ['DisplayName', 'Name', 'Description', 'Tags', 'Type', 'Username'] : (searchText | removeComma | removeHash) : false
          | orderByImpure: orderBy.value;
        track asset
      ) {
        <ui-display-card
          [imageSrc]="asset.Thumbnail"
          (imageClick)="openPreview(asset)"
          [canSelect]="true"
          [isSelected]="isSelected(asset.Id)"
          [id]="asset.Id"
          (selected)="onSelected($event, asset.Id)"
        >
          <app-market-card-description
            [marketItem]="asset"
            [isLiked]="liked.includes(asset.Id)"
            [isInCart]="cart.includes(asset.Id)"
            [isPurchased]="owned.includes(asset.Id)"
          ></app-market-card-description>
        </ui-display-card>
      }
    </ui-card-list>
  } @else {
    <div class="empty-list">
      <img src="./../../assets/images/img-heart.png" />
      <div class="description" [innerHtml]="'market.noLikedItems' | translate: { username: username }"></div>
    </div>
  }
  <ng-template #empty>
    <div class="empty-list">
      <img src="./../../assets/images/img-heart.png" />
      <div class="description" [innerHtml]="'market.noLikedItems' | translate: { username: username }"></div>
    </div>
  </ng-template>
} @else {
  <ui-loader></ui-loader>
}
@if (showPreview) {
  <app-market-card-preview
    [assetPreview]="assetPreview"
    [marketItem]="asset"
    [isLiked]="liked.includes(asset.Id)"
    [isInCart]="cart.includes(asset.Id)"
    [isPurchased]="owned.includes(asset.Id)"
    (closeEvent)="onPreviewClose($event)"
    (selectTagEvent)="onClickTag($event)"
  ></app-market-card-preview>
}

<ui-search-bar [(searchText)]="searchText"> </ui-search-bar>
<ui-search-filter> </ui-search-filter>
@if (!loading) {
  @if (
    (scenes | filterBy: ['Id'] : likes | filterBy: ['Name', 'Tags', 'Username'] : (searchText | removeComma | removeHash) : false)?.length
  ) {
    <ui-card-list>
      @for (
        scene of scenes
          | filterBy: ['Id'] : likes
          | filterBy: ['Name', 'Tags', 'Username'] : (searchText | removeComma | removeHash) : false
          | orderByImpure: orderBy.value;
        track scene
      ) {
        <ui-display-card [chatOn]="scene.ChatOn" [imageSrc]="scene.Thumbnail" (imageClick)="viewScene(scene)">
          <app-community-card-description [scene]="scene" [isLiked]="true"></app-community-card-description>
        </ui-display-card>
      }
    </ui-card-list>
  } @else {
    <div class="empty-list">
      <img src="./../../assets/images/img-heart.png" />
      <div class="description" [innerHtml]="'community.noLikedItems' | translate: { username: username }"></div>
    </div>
  }
  <ng-template #empty>
    <div class="empty-list">
      <img src="./../../assets/images/img-heart.png" />
      <div class="description" [innerHtml]="'community.noLikedItems' | translate: { username: username }"></div>
    </div>
  </ng-template>
} @else {
  <ui-loader></ui-loader>
}

import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval, map, take } from 'rxjs';
import { environment } from '@/app/src/environments/environment';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { InformationComponent } from '@/ui/src/lib/modal/information/information.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { DomainService } from '@/data/src/lib/services/domain.service';

import { DomainStatus } from '@/data/src/lib/enums/domain';
import { AccountService } from '@/data/src/lib/services/account.service';
import { EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
import { DomainMap } from './../../../../../../data/src/lib/models/data/domain';
import { ScenePlan, ScenePlanDetail } from '../../../../../../data/src/lib/enums/pricing-plan';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';

@UntilDestroy()
@Component({
  selector: 'oxr-publish',
  templateUrl: './oxr-publish.component.html',
  styleUrls: ['./oxr-publish.component.scss'],
})
export class OxrPublishComponent implements OnInit {
  @Input() scene: XRScene;
  @Output() onClose = new EventEmitter<void>();

  shareUrl = `${environment.redirectURL}/view/`;
  isAccessible = false;
  isLoading = false;
  subDomainLoading = true;

  subDomain: DomainMap | undefined = undefined;

  plan = ScenePlan;
  domainStatus = DomainStatus;

  constructor(
    private _clipboardApi: ClipboardService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _sceneService: SceneService,
    private _router: Router,
    private _domainService: DomainService,
    private _accountService: AccountService,
    private _ngZone: NgZone,
    private _pricingPlanService: PricingPlanService,
  ) {}

  async ngOnInit() {
    this.subDomainLoading = true;
    this.isAccessible = !!this.scene?.IsAccessible;
    const accountOption = this._accountService.currentAccountOption.getValue();
    if (accountOption) {
      await this._domainService.get(accountOption.EnterpriseContract?.EnterpriseId);
    }

    this._domainService.data$
      .pipe(map((data: DomainMap[]) => data.find((data: DomainMap) => data.SceneId === this.scene.Id)))
      .subscribe((data: DomainMap | undefined) => {
        this.subDomainLoading = false;
        this.subDomain = data ?? undefined;
      });
  }

  close(): void {
    this.onClose.emit();
  }

  onAccessibilityChange(isAccessible = false) {
    this.isAccessible = isAccessible;
  }

  async save() {
    if (this._pricingPlanService.hasScenePlanPropertyRestriction(this.scene.Plan, ScenePlanDetail.PublishSpace, true)) {
      return;
    }

    if (this._accountService.currentAccountOption.getValue()?.Role !== EnterpriseRole.Collaborator) {
      this.isLoading = true;
      if (this.isAccessible) {
        await this._sceneService.publishScene(this.scene.Id);
      } else {
        await this._sceneService.unPublishScene(this.scene.Id);
      }
      this.isLoading = false;
      this._router.navigate(['/', 'community']);
    }
  }

  copyToClipboard(url: string): void {
    this._clipboardApi.copy(url);
    const modalRef = this._modalService.open(InformationComponent);

    if (modalRef) {
      modalRef.instance.message = this._translateService.instant('shared.information.linkCopied');

      this._ngZone.runOutsideAngular(() => {
        interval(1000)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            if (modalRef) {
              modalRef.instance.close();
              this._ngZone.run(() => {});
            }
          });
      });
    }
  }

  onConnectSubdomain() {
    if (this.canConnectToDomain(this.scene.Plan)) {
      this._router.navigate(['domain', 'search', 'setting', 'ownxr'], { queryParams: { sceneId: this.scene.Id } });
    }
  }

  canConnectToDomain(scenePlan: ScenePlan): boolean {
    if (scenePlan === ScenePlan.Enterprise) {
      return true;
    }

    return (
      this._pricingPlanService.scenePlans![scenePlan][ScenePlanDetail.PublishSpace] &&
      this._pricingPlanService.scenePlans![scenePlan][ScenePlanDetail.ConnectOwnXrSubdomain] &&
      this._pricingPlanService.scenePlans![scenePlan][ScenePlanDetail.ConnectOwnedDomain]
    );
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WINDOW } from '@ng-web-apis/common';
import { AccountService } from '@/data/src/lib/services/account.service';
import { PaymentStripeService } from '@/data/src/lib/services/payment-stripe.service';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { DomainMap } from '@/data/src/lib/models/data/domain';
import { DisplayCardComponent } from '@/ui/src/lib/layout/body/display-card/display-card.component';

@UntilDestroy()
@Component({
  selector: 'app-domain-display-card',
  templateUrl: './domain-display-card.component.html',
  styleUrls: ['./domain-display-card.component.scss'],
})
export class DomainDisplayCardComponent extends DisplayCardComponent implements OnInit {
  @Input() scene!: XRScene;
  @Input() isPublic: boolean = false;
  @Input() takenScenes: DomainMap[] = [];
  @Input() isCollaborator = false;
  @Input() isFreeLock = false;
  @Input() isEnterprise = false;

  get isConnected() {
    if (this.takenScenes.length > 0) {
      return this.takenScenes.filter((e) => e.SceneId === this.scene.Id).length > 0 ? true : false;
    }
    return false;
  }

  constructor(
    router: Router,
    _accountService: AccountService,
    _paymentStripeService: PaymentStripeService,
    @Inject(WINDOW) readonly _window: Window,
  ) {
    super(router, _accountService, _paymentStripeService, _window);
  }

  ngOnInit(): void {}

  goToPublish() {
    this.router.navigate(['oxr', 'space', this.scene.Id]);
  }
}

import { AccountOption, AccountOptionType } from '@/data/src/lib/models/data/account';
import { DomainMap } from '@/data/src/lib/models/data/domain';
import { EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
import { AccountService } from '@/data/src/lib/services/account.service';
import { DomainService } from '@/data/src/lib/services/domain.service';
import { LocalStorageKeys } from '@/data/src/lib/enums/storage-keys';
import { LocalStorageService } from '@/data/src/lib/services/local-storage.service';
import { UrlService } from '@/data/src/lib/services/url.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { OrderByImpurePipe } from 'ngx-pipes';
import { environment } from 'projects/app/src/environments/environment';
import { XRScene, XRSceneInteraction } from 'projects/data/src/lib/models/data/scene';
import { ListOrderOption } from 'projects/data/src/lib/models/interfaces/list-order-option';
import { SceneService } from 'projects/data/src/lib/services/scene.service';
import { ConfirmationComponent } from 'projects/ui/src/lib/modal/confirmation/confirmation.component';
import { ModalService } from 'projects/ui/src/lib/modal/modal.service';

@UntilDestroy()
@Component({
  selector: 'app-oxr-dashboard',
  templateUrl: './oxr-dashboard.component.html',
  styleUrls: ['./oxr-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [OrderByImpurePipe],
})
export class OxrDashboardComponent implements OnInit {
  loading = true;

  scenes: XRScene[] = [];
  activeScene: XRScene | undefined;
  lastSelectedSceneId: string | undefined;
  storageValue: { accountOptionId: string | null; sceneId: string }[] = []; //To remember last selected scene by enterprise's id.
  unreadCounts: { [key: string]: number } = {};

  accountOption: AccountOption | null;
  private accountOptionId: string | null;
  subDomain: DomainMap | undefined;

  orderingOptions: ListOrderOption[] = [{ value: '-Date', name: 'createdDate' }];
  orderBy: ListOrderOption = this.orderingOptions[0];

  selectedInquiry: XRSceneInteraction | undefined;
  interactions: XRSceneInteraction[] | undefined = [];
  reply = '';
  deleteList: string[] = [];
  popupContentsUsage: { name: string; count: number }[];

  constructor(
    private _sceneService: SceneService,
    private _cd: ChangeDetectorRef,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _router: Router,
    private _urlService: UrlService,
    private _accountService: AccountService,
    private _domainService: DomainService,
    private _clipboardApi: ClipboardService,
    private _localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.loading = true;
      this._cd.detectChanges();

      if (res) {
        this.accountOption = res;
        this.accountOptionId = res.Type === AccountOptionType.Enterprise ? res.Id : AccountOptionType.Individual;
        this.storageValue = JSON.parse(this._localStorageService.getItem(LocalStorageKeys.SELECTED_DASHBOARD_SCENE_INFO)!) || [];
        this.lastSelectedSceneId = this.storageValue.find((value) => value.accountOptionId === this.accountOptionId)?.sceneId;

        if (res.Role === EnterpriseRole.Collaborator) {
          this._router.navigate(['oxr', 'owned']);
        } else {
          const query = `includeInteractions=true${res.EnterpriseContract?.EnterpriseId ? `&enterpriseId=${res.Id}` : ''}`;
          this._sceneService.get('', query, undefined, true, true).then((res) => {
            if (!res.length) {
              this._router.navigate(['oxr', 'owned']);
            }
          });
        }
      }
    });

    this._sceneService.data$.pipe(untilDestroyed(this)).subscribe(async (scenes) => {
      if (scenes.length) {
        this.scenes.length = 0;
        this.scenes = scenes;
        this.countUnread(this.scenes);
        this.activeScene = this.lastSelectedSceneId ? scenes.find((scene) => scene.Id === this.lastSelectedSceneId) : scenes[0];
        await this.changeActiveScene(this.activeScene);

        this.loading = false;
        this._cd.detectChanges();
      }
    });
  }

  countUnread(scenes: XRScene[]) {
    let unreadCounts: { [key: string]: number } = {};
    for (const scene of scenes) {
      if (scene.Interactions) {
        unreadCounts[scene.Id] = scene.Interactions.filter((i: XRSceneInteraction) => i.Interaction['IsRead'] === 'false').length;
        this.unreadCounts = { ...this.unreadCounts, ...unreadCounts };
      }
    }
  }

  async changeActiveScene(scene: XRScene | undefined) {
    this.activeScene = scene;

    if (scene) {
      if (this.storageValue.find((value) => value.accountOptionId === this.accountOptionId)) {
        this.storageValue.map((value) => value.accountOptionId === this.accountOptionId && (value.sceneId = scene.Id));
      } else {
        this.storageValue.push({ accountOptionId: this.accountOptionId, sceneId: scene.Id });
      }

      this._localStorageService.setItem(LocalStorageKeys.SELECTED_DASHBOARD_SCENE_INFO, JSON.stringify(this.storageValue));

      await Promise.all([
        this._domainService.get(this.accountOption?.EnterpriseContract?.EnterpriseId),
        this._sceneService.getPopupContentsUsage(scene.Id),
      ]).then(([domains, popupContentsUsage]) => {
        this.subDomain = domains.find((data: DomainMap) => data.SceneId === scene.Id);

        const filteredKeys = Object.keys(popupContentsUsage).filter((key) => key !== 'SceneId' && key !== 'SceneName');
        const resultArray = filteredKeys.map((key) => ({
          name: key.replace('Count', '').toLowerCase(),
          count: popupContentsUsage[key],
        }));
        this.popupContentsUsage = resultArray;
      });

      this.selectedInquiry = undefined;
      this.interactions = [];
      this.interactions = scene.Interactions?.filter((i: XRSceneInteraction) => i.Interaction['IsDelete'] === 'false');

      this._cd.detectChanges();
    }
  }

  checkedInteraction(interaction: XRSceneInteraction) {
    const index = this.deleteList.findIndex((id) => id === interaction.Id);

    if (index === -1) {
      this.deleteList.push(interaction.Id);
    } else {
      this.deleteList.splice(index, 1);
    }
  }

  async showDetail(interactionId: string) {
    if (this.interactions) {
      this.selectedInquiry = this.interactions.filter((e) => e.Id === interactionId)[0];

      if (this.selectedInquiry.Interaction['IsRead'] == 'false') {
        this.selectedInquiry.Interaction['IsRead'] = 'true';
        await this._sceneService.patchInteraction(this.selectedInquiry);

        let unreadCounts: { [key: string]: number } = {};

        if (this.interactions) {
          unreadCounts[this.selectedInquiry.SceneId] = this.interactions.filter(
            (interaction) => interaction.Interaction['IsRead'] === 'false',
          ).length;
          this.unreadCounts = { ...this.unreadCounts, ...unreadCounts };
          this._cd.detectChanges();
        }
      }
    }
  }

  onDelete(id?: string) {
    const modalRef = this._modalService.open(ConfirmationComponent);

    if (modalRef) {
      modalRef.instance.title = this._translateService.instant('shared.confirmation.notice');
      modalRef.instance.body = this._translateService.instant('oxr.dashboard.deletionConfirmation');
      modalRef.instance.confirmAction = this._translateService.instant('shared.confirmation.yes');
      modalRef.instance.cancelAction = this._translateService.instant('shared.confirmation.cancel');

      modalRef.result.then(async (result) => {
        if (result) {
          if (id) {
            this.selectedInquiry!.Interaction['IsDelete'] = 'true';
            await this._sceneService.patchInteraction(this.selectedInquiry!);
          } else {
            for (const id of this.deleteList) {
              this.interactions?.forEach((interaction) => {
                if (interaction.Id === id) {
                  interaction.Interaction['IsDelete'] = 'true';
                  this._sceneService.patchInteraction(interaction);
                }
              });
            }
          }

          this.interactions = this.interactions?.filter((i) => i.Interaction['IsDelete'] === 'false');
          this.deleteList.length = 0;
          this.selectedInquiry = undefined;
        }
      });
    }
  }

  onDomain(domainPath?: string) {
    if (domainPath) {
      this._urlService.windowOpenWithPlatform(`https://${domainPath}`);
    } else {
      this._router.navigate(['domain', 'search']);
    }
  }

  onEdit() {
    this._urlService.windowOpenWithPlatform(`${environment.redirectURL}/oxr/space/${this.activeScene?.Id}`);
  }

  onSubscriptionManage() {
    this._urlService.windowOpenWithPlatform(`${environment.redirectURL}/oxr/subscriptionManagement`);
  }

  copyUrl(path: string) {
    this._clipboardApi.copy(path);
  }
}

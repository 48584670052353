@if (!loading) {
  @if (activeScene) {
    <div class="container">
      <div class="header">
        <div class="header detail">
          <ng-select [virtualScroll]="true" [clearable]="false" [ngModel]="activeScene.Name" (ngModelChange)="changeActiveScene($event)">
            @for (scene of scenes | orderByImpure: orderBy!.value; track scene) {
              <ng-option [value]="scene">
                @if (!scene.Favicon) {
                  <div class="icon icon-ownxr"></div>
                }
                @if (scene.Favicon) {
                  <img class="icon" [src]="scene.Favicon" />
                }
                <div class="text">{{ scene.Name }}</div>
                @if (unreadCounts[scene.Id] > 0) {
                  <div class="unread-count">{{ unreadCounts[scene.Id] }}</div>
                }
              </ng-option>
            }
          </ng-select>
          <div class="center">
            @if (subDomain) {
              <div class="flex-wrapper">
                <span class="domain-path" (click)="onDomain(subDomain.Path)">{{ subDomain.Path }}</span>
                <div class="icon icon-copy sm" (click)="copyUrl(subDomain.Path)"></div>
              </div>
            } @else {
              <div class="icon-wrapper" (click)="onDomain()">
                <div class="icon icon-plus"></div>
              </div>
              {{ 'oxr.dashboard.addDomain' | translate }}
            }
            <ng-template #addDomain>
              <div class="icon-wrapper" (click)="onDomain()">
                <div class="icon icon-plus"></div>
              </div>
              {{ 'oxr.dashboard.addDomain' | translate }}
            </ng-template>
          </div>
          <div class="right">
            <button ui-button outlined (click)="onSubscriptionManage()">
              {{ 'oxr.dashboard.subscriptionManagement' | translate }}
            </button>
            <button ui-button outlined (click)="onEdit()">
              {{ 'oxr.dashboard.spaceEdit' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="left-side">
        <div class="header">
          <div class="key">{{ 'oxr.dashboard.popupContent' | translate }}</div>
          <div class="count">{{ 'oxr.dashboard.usageCount' | translate }}</div>
        </div>
        <div class="body">
          @for (popupContentUsage of popupContentsUsage; track popupContentUsage) {
            <div class="set">
              <div class="key">
                <span class="icon icon-{{ popupContentUsage.name }}-line"></span>
                <span>{{ 'oxr.dashboard.' + popupContentUsage.name | translate }}</span>
              </div>
              <div class="count" [ngClass]="{ highlight: popupContentUsage.count }">
                {{ popupContentUsage.count }}
              </div>
            </div>
          }
        </div>
      </div>
      <div class="right-side">
        <div class="header">
          <span>INBOX&nbsp;·&nbsp;</span>
          <span class="unread-count"
            >{{ 'oxr.dashboard.unread' | translate }}&nbsp;{{ activeScene ? unreadCounts[activeScene.Id] : 0 }}</span
          >
          <button ui-text-button [disabled]="!deleteList.length" class="delete" (click)="onDelete()">
            {{ 'oxr.dashboard.delete' | translate }}
          </button>
        </div>
        <div class="body">
          @if (interactions && interactions.length > 0) {
            <div class="left">
              <div class="left-scroll">
                @for (interaction of interactions | orderByImpure: orderBy!.value; track interaction) {
                  <div
                    class="wrapper"
                    [ngClass]="{ selected: interaction === selectedInquiry, isRead: interaction.Interaction['IsRead'] === 'true' }"
                    (click)="showDetail(interaction.Id)"
                  >
                    <div class="wrapper-left">
                      <ui-check-box
                        class="selection-checkbox background-white white xs"
                        [checked]="deleteList.includes(interaction.Id)"
                        (checkedChange)="checkedInteraction(interaction)"
                      ></ui-check-box>
                      <div class="icon-wrapper">
                        <div class="icon icon-ownxr"></div>
                      </div>
                      <span class="user-name"
                        >{{ interaction.Username }} <span [ngClass]="{ dot: interaction.Interaction['IsRead'] === 'false' }"></span
                      ></span>
                    </div>
                    <div class="wrapper-right">
                      <span class="date">{{ interaction.Date | date: 'yyyy.MM.dd' }}</span>
                      <div class="icon icon-delete" (click)="onDelete(interaction.Id)"></div>
                    </div>
                  </div>
                }
              </div>
            </div>
            @if (selectedInquiry) {
              <div class="right">
                <div class="right-scroll" [ngClass]="{ disabled: !!selectedInquiry.Interaction['Reply'] }">
                  <span class="title">문의 내역</span>
                  <div class="text">{{ selectedInquiry.Interaction['PlaceholderFirst'] }}</div>
                  <div class="text">{{ selectedInquiry.Interaction['PlaceholderSecond'] }}</div>
                  <div class="text">{{ selectedInquiry.Interaction['PlaceholderThird'] }}</div>
                  <div class="text inquiry-title">{{ selectedInquiry.Interaction['PlaceholderFourth'] }}</div>
                </div>
              </div>
            } @else {
              <div class="right-empty" [innerHtml]="'oxr.dashboard.checkMessage' | translate"></div>
            }
          } @else {
            <div class="empty">{{ 'oxr.dashboard.inboxIsEmpty' | translate }}</div>
          }
          <ng-template #empty>
            <div class="empty">{{ 'oxr.dashboard.inboxIsEmpty' | translate }}</div>
          </ng-template>
        </div>
      </div>
    </div>
  } @else {
    <div class="disabled-for-collaborator">
      <img src="./../../../assets/images/img-dashboard-background.png" />
      <div class="wrapper">
        <div class="icon icon-lock"></div>
        {{ 'shared.fields.comingSoon2' | translate }}
      </div>
    </div>
  }
  <ng-template #disabled>
    <div class="disabled-for-collaborator">
      <img src="./../../../assets/images/img-dashboard-background.png" />
      <div class="wrapper">
        <div class="icon icon-lock"></div>
        {{ 'shared.fields.comingSoon2' | translate }}
      </div>
    </div>
  </ng-template>
} @else {
  <ui-loader></ui-loader>
}

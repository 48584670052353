import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { XRAsset } from '../models/data/asset';
import { ModelType } from '../models/data/base';
import { MarketItem, MarketHistory } from '../models/data/market';
import { AssetService } from './asset.service';
import { BaseApiService } from './base-api.service';
import { AccountService } from './account.service';
import { BehaviorSubject, lastValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as uuid from 'uuid';
import { newModel } from '../factories/model-factory';

/**
 * Tracks and manages the state of market models and commits changes to the backend
 */
@Injectable({
  providedIn: 'root',
})
export class MarketService extends BaseApiService<MarketItem> {
  private viewedBehaviourSubject = new BehaviorSubject<MarketItem[]>([]);
  private likesBehaviourSubject = new BehaviorSubject<string[]>([]);
  private cartBehaviourSubject = new BehaviorSubject<string[]>([]);
  private ownedBehaviourSubject = new BehaviorSubject<string[]>([]);
  private purchasedBehaviourSubject = new BehaviorSubject<MarketItem[]>([]);
  private noAuthHttp: HttpClient;

  public views$ = this.viewedBehaviourSubject.asObservable();
  public likes$ = this.likesBehaviourSubject.asObservable();
  public cart$ = this.cartBehaviourSubject.asObservable();
  public owned$ = this.ownedBehaviourSubject.asObservable();
  public purchased$ = this.purchasedBehaviourSubject.asObservable();

  constructor(
    public http: HttpClient,
    private assetService: AssetService,
    accountService: AccountService,
    handler: HttpBackend,
  ) {
    super(http, accountService, ModelType.Market);
    this.noAuthHttp = new HttpClient(handler);
  }

  async getPublicItems(forceHttp = false) {
    if (!this.dataSubject.value?.length || forceHttp) {
      return lastValueFrom(
        this.noAuthHttp.get<MarketItem[]>(`${this.endPoint}`).pipe(
          map((data) => {
            if (data && data.length) {
              data = data.map((item) => {
                {
                  const newItem = newModel(item, this.modelType);
                  this.attach(newItem);
                  return newItem;
                }
              });
              this.updateDataSubject(data);

              return data.slice(0);
            } else {
              return [];
            }
          }),
        ),
      );
    } else {
      return this.dataSubject.value;
    }
  }

  /**
   * Gets a market model by the asset id
   * @param assetId
   * @returns the market model
   */
  async getByAssetId(assetId: string): Promise<MarketItem | undefined> {
    return !this.accountService.trialOnly
      ? lastValueFrom(this.http.get<MarketItem>(`${this.endPoint}/ByAsset/${assetId}`).pipe(catchError(() => of(undefined))))
      : undefined;
  }

  /**
   * Creates a market model for the input asset
   * @param asset Asset to market
   * @param name name of marketed asset
   * @param tags tags to assign to market model
   * @param value token price of market item
   */
  async marketAsset(asset: XRAsset, value: string = '0') {
    if (this.accountService.account) {
      const market = new MarketItem(
        uuid.v4(),
        asset.Id,
        (asset as any).CategoryId,
        asset.Name,
        '',
        asset.Tags,
        parseFloat(value),
        asset.Thumbnail,
        '',
        '',
        this.accountService.account.Username,
        this.accountService.account.DisplayName,
        this.accountService.account.Thumbnail,
        true,
      );
      this.attach(market);
      this.updateDataSubject(market);
      await this.post(market);
    }
  }

  /**
   * Purchase an array of selected market items
   * @param orderId the order id in which confirms the purchase
   * @param itemIds array of item ids to purchase
   * @returns true if purchase was successful, false otherwise
   */
  async purchase(orderId: string, itemIds: string[], enterpriseId?: string) {
    const param = enterpriseId ? `?enterpriseId=${enterpriseId}` : '';
    return lastValueFrom(
      this.http.post<string>(`${this.endPoint}/purchase/${orderId}${param}`, JSON.stringify(itemIds), this.postOptions).pipe(
        map(async (assetId) => {
          // await this.assetService.get(assetId);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
      ),
    );
  }

  /**
   * Preview the marketed asset
   * @param itemId market item id
   * @returns the asset to preview
   */
  async preview(itemId: string) {
    // const key = await lastValueFrom(
    //   this.noAuthHttp.get<{ Id: string; Key: string }>(`${this.endPoint}/preview/${itemId}`, this.postOptions),
    // );
    // return (await this.assetService.loadAssetGeometry(key.Id, key.Key)) ?? this.preview(itemId);
  }

  /**
   * Get the ids of the all the market items the current user has viewed
   * @returns
   */
  async getViews() {
    return lastValueFrom(
      await this.http.get<MarketItem[]>(`${this.endPoint}/Viewed`).pipe(
        map((data) => {
          this.viewedBehaviourSubject.next(data);
          return data;
        }),
      ),
    );
  }

  /**
   * Post the ids of the market items the current user has viewed
   * @param items previewed market items
   * @returns post result
   */
  async postViews(items: MarketItem[]) {
    const account = await this.accountService.getActiveAccount();

    if (account) {
      return lastValueFrom(
        this.http
          .post(`${this.endPoint}/Viewed`, JSON.stringify(items.map((v) => v.Id)), {
            headers: { 'Content-Type': 'application/json' },
          })
          .pipe(
            map(() => {
              this.viewedBehaviourSubject.next([...this.viewedBehaviourSubject.value, ...items]);
            }),
          ),
      );
    } else {
      return lastValueFrom(
        this.noAuthHttp
          .post(`${this.endPoint}/Viewed`, JSON.stringify(items.map((v) => v.Id)), {
            headers: { 'Content-Type': 'application/json' },
          })
          .pipe(
            map(() => {
              this.viewedBehaviourSubject.next([...this.viewedBehaviourSubject.value, ...items]);
            }),
          ),
      );
    }
  }

  /**
   * Gets the purchase history of the all the marketed items the current user has created
   * @returns list of market history
   */
  async getPurchaseHistory() {
    return await lastValueFrom(
      this.http.get<MarketHistory[]>(`${this.endPoint}/History`).pipe(
        map((data) => {
          return data;
        }),
      ),
    );
  }

  /**
   * Gets the ids of all the market items the current user has liked
   * @returns array of ids of market item the user has liked
   */
  async getLikes() {
    return lastValueFrom(
      this.http.get<string[]>(`${this.endPoint}/Likes`).pipe(
        map((data) => {
          this.likesBehaviourSubject.next(data);
          return data;
        }),
      ),
    );
  }

  /**
   * Posts the ids of the market items the current user has liked
   * @param likes
   */
  async postLikes(likes: string[]) {
    return lastValueFrom(
      this.http
        .post(`${this.endPoint}/Likes`, JSON.stringify(likes), {
          headers: { 'Content-Type': 'application/json' },
        })
        .pipe(
          map(() => {
            this.likesBehaviourSubject.next([...this.likesBehaviourSubject.value, ...likes]);
          }),
        ),
    );
  }

  /**
   * removes the like for the market item
   * @param like market id of like to remove
   */
  async deleteLike(like: string) {
    return lastValueFrom(
      this.http.delete(`${this.endPoint}/Likes/${like}`).pipe(
        map(() => {
          const data = this.likesBehaviourSubject.value;
          const index = data.indexOf(like);
          if (index >= 0) {
            data.splice(index, 1);
            this.likesBehaviourSubject.next(data);
          }
        }),
      ),
    );
  }

  /**
   * Gets the ids of all the market items the current user has in their cart
   * @returns array of ids of market items
   */
  async getCart() {
    return lastValueFrom(
      await this.http.get<string[]>(`${this.endPoint}/Cart`).pipe(
        map((data) => {
          this.cartBehaviourSubject.next(data);
          return data;
        }),
      ),
    );
  }

  /**
   * Gets the all the market items the current user has purchased
   * @returns array of MarketItems
   */
  async getPurchased(enterpriseId?: string) {
    const param = enterpriseId ? `?enterpriseId=${enterpriseId}` : '';
    return await lastValueFrom(
      this.http.get<MarketItem[]>(`${this.endPoint}/Purchased${param}`).pipe(
        map((data) => {
          this.purchasedBehaviourSubject.next(data);
          return data;
        }),
      ),
    );
  }

  /**
   * Gets the ids of all the market items the current user has purchased
   * @returns array of ids
   */
  async getOwned(enterpriseId?: string) {
    const param = enterpriseId ? `?enterpriseId=${enterpriseId}` : '';
    return await lastValueFrom(
      this.http.get<string[]>(`${this.endPoint}/Purchases${param}`).pipe(
        map((data) => {
          this.ownedBehaviourSubject.next(data);
          return data;
        }),
      ),
    );
  }

  /**
   * Posts the ids of the market items the current user has cart
   * @param cart
   */
  async postCart(cart: string[]) {
    return lastValueFrom(
      this.http
        .post(`${this.endPoint}/Cart`, JSON.stringify(cart), {
          headers: { 'Content-Type': 'application/json' },
        })
        .pipe(
          map(() => {
            this.cartBehaviourSubject.next([...this.cartBehaviourSubject.value, ...cart]);
          }),
        ),
    );
  }

  /**
   * removes the item from the cart
   * @param cart market id to remove
   */
  async deleteCart(cart: string) {
    const data = this.cartBehaviourSubject.value;
    const index = data.indexOf(cart);
    if (index >= 0) {
      data.splice(index, 1);
      this.cartBehaviourSubject.next(data);
    }
    return lastValueFrom(this.http.delete(`${this.endPoint}/Cart/${cart}`));
  }

  /**
   * Clears all the items in the current user's cart
   */
  async clearCart() {
    this.cartBehaviourSubject.next([]);
    return lastValueFrom(this.http.delete(`${this.endPoint}/Cart`));
  }
}

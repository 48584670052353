<ui-search-bar [(searchText)]="searchText" (searchTriggered)="onSearchTriggered()"> </ui-search-bar>
<ui-search-filter>
  <ng-select
    class="small"
    [searchable]="false"
    [clearable]="false"
    placeholder="{{ 'shared.list.orderBy.placeholder' | translate }}"
    [ngModel]="orderBy"
    (ngModelChange)="changeOrderBy($event)"
    [disabled]="loading"
  >
    @for (option of orderingOptions; track option) {
      <ng-option [value]="option">
        <div class="select-option-wrapper">
          {{ 'shared.list.orderBy.' + option | translate }}
        </div>
      </ng-option>
    }
  </ng-select>
</ui-search-filter>
@if (!loading) {
  @if (!!currentPageScenes.length) {
    <ui-card-list>
      @for (scene of currentPageScenes; track scene) {
        <ui-display-card [chatOn]="scene.ChatOn" [imageSrc]="scene.Thumbnail" (imageClick)="viewScene(scene)">
          <app-community-card-description [scene]="scene" [isLiked]="likedMap.get(scene.Id)"></app-community-card-description>
        </ui-display-card>
      }
    </ui-card-list>
  } @else {
    <div class="empty-list">
      <img src="./../../assets/images/magnifying-glass-color.png" />
      <div class="description">{{ 'community.noResultItems' | translate }}</div>
    </div>
  }
  <ng-template #empty>
    <div class="empty-list">
      <img src="./../../assets/images/magnifying-glass-color.png" />
      <div class="description">{{ 'community.noResultItems' | translate }}</div>
    </div>
  </ng-template>
} @else {
  <ui-loader></ui-loader>
}
@if (!!currentPageScenes.length) {
  <ui-pagination
    [totalPages]="totalPages"
    [currentPage]="currentPage"
    (clickPageEmitter)="getPublicScenesByPagination($event, false)"
  ></ui-pagination>
}

import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { AssetService } from '@/data/src/lib/services/asset.service';
import { MarketService } from '@/data/src/lib/services/market.service';
import { MarketItem } from '@/data/src/lib/models/data/market';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { TooltipPosition } from '@/ui/src/lib/directive/tooltip.directive';
import { IAsset } from '@/data/src/lib/models/data/asset';

@Component({
  selector: 'app-editor-card-description',
  templateUrl: './editor-card-description.component.html',
  styleUrls: ['./editor-card-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorCardDescriptionComponent implements OnInit {
  @Input() asset: IAsset;
  @Input() isOwner = false;
  @Input() isSold = false;

  public marketItem!: MarketItem | undefined;
  tooltipPosition = TooltipPosition;

  constructor(
    private _assetService: AssetService,
    private _marketService: MarketService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _toastr: ToastrService,
    private _cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  async edit(event, editMode: boolean) {
    event.stopPropagation();
    // const modalRef = this._modalService.open(EditorAssetInfoComponent, { closeOnBackdropClick: false });
    // this._marketService.getByAssetId(this.asset.Id).then((marketItem) => {
    //   this.marketItem = marketItem;
    //   if (modalRef.instance) {
    //     modalRef.instance.shouldPublish = !!marketItem?.DisplayToMarket;
    //   }
    // });

    // if (modalRef) {
    //   if (this.asset) {
    //     modalRef.instance.asset = this.asset;
    //     modalRef.instance.editMode = editMode;
    //     modalRef.instance.isOwner = this.isOwner;
    //     modalRef.result.then(async (result) => {
    //       if (result.save) {
    //         // this._assetService.modify(this.asset);
    //         // await this._assetService.commit();
    //         // if (this.isOwner && result.canUploadMarket) {
    //         //   if (!!this.marketItem) {
    //         //     this.marketItem.Tags = this.asset.Tags;
    //         //     this.marketItem.Name = this.asset.Name;
    //         //     this.marketItem.Thumbnail = this.asset.Thumbnail;
    //         //     this.marketItem.DisplayToMarket = result.publish;
    //         //     await this._marketService.put(this.marketItem);
    //         //   } else {
    //         //     this._marketService.marketAsset(this.asset);
    //         //   }
    //         // }
    //         // // await this._assetService.versionAsset(this.asset);
    //         this._cd.markForCheck();
    //       }
    //     });
    //   }
    // }
  }

  delete(): void {
    if (!this.isSold && this.isOwner) {
      const modalRef = this._modalService.open(ConfirmationComponent);

      if (modalRef) {
        modalRef.instance.title = this._translateService.instant('shared.confirmation.warning');
        modalRef.instance.body = this._translateService.instant('editor.deleteConfirmation');
        modalRef.instance.confirmAction = this._translateService.instant('shared.actions.delete');
        modalRef.instance.cancelAction = this._translateService.instant('shared.actions.cancel');

        modalRef.result.then((result) => {
          if (result) {
            // this._assetService.delete(this.asset).then(() => {
            //   const message = this._translateService.instant('shared.information.hasBeenDeleted', { name: this.asset.Name });
            //   this._toastr.info(message);
            // });
          }
        });
      }
    }
  }
}

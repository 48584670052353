import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ListOrderOption } from '@/data/src/lib/models/interfaces/list-order-option';
import { AssetService } from '@/data/src/lib/services/asset.service';
import { ApplicationService } from '@/view/src/app/app.service';
import { DataCacheService } from '@/data/src/lib/services/data-cache.service';
// import { UserAssetService } from '@/data/src/lib/services/user-asset.service';
import { Permission } from '@/data/src/lib/enums/permission';
import { MediaUploadComponent } from '@/ui/src/lib/modal/media-upload/media-upload.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { AccountOption } from '@/data/src/lib/models/data/account';
import { EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
import { forkJoin, switchMap } from 'rxjs';
import { IAsset } from '@/data/src/lib/models/data/asset';
import { NEW_ASSET_ID } from '@/data/src/lib/apiv2';

@UntilDestroy()
@Component({
  selector: 'app-editor-list',
  templateUrl: './editor-list.component.html',
  styleUrls: ['./editor-list.component.scss'],
})
export class EditorListComponent implements OnInit {
  loading = true;
  searchText = '';
  tags: string[] = ['Environment', 'Object'];
  checkedTags: string[] = [];

  orderingOptions: ListOrderOption[] = [
    { value: '-DateCreated', name: 'newest' },
    { value: 'DateCreated', name: 'oldest' },
  ];
  orderBy: ListOrderOption = this.orderingOptions[0];

  // lastAddedAsset?: IAsset;
  assets: IAsset[] = [];
  asset: IAsset;
  ownerAssets;
  soldAssetIds: string[];
  accountOption: AccountOption | null;

  constructor(
    private _assetService: AssetService,
    // private _userAssetService: UserAssetService,
    private _router: Router,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _appService: ApplicationService,
    public _dataCache: DataCacheService,
    private _accountService: AccountService,
    private _cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._accountService.currentAccountOption$
      .pipe(
        untilDestroyed(this),
        switchMap((accountOption: AccountOption | null) => {
          this.searchText = '';
          this.loading = true;
          this.accountOption = accountOption;
          // const getAssetQuery = accountOption?.EnterpriseContract?.EnterpriseId
          //   ? `forEditor=true&enterpriseId=${accountOption?.EnterpriseContract?.EnterpriseId}`
          //   : 'forEditor=true';
          // const getUserAssetByUserQuery = accountOption?.EnterpriseContract?.EnterpriseId
          //   ? `enterpriseId=${accountOption?.EnterpriseContract?.EnterpriseId}`
          //   : '';
          // this._assetService.reset();
          // this._userAssetService.reset();

          this._assetService
            .getSoldAssetIds(accountOption?.EnterpriseContract?.EnterpriseId)
            .pipe(untilDestroyed(this))
            .subscribe((assetIds: string[]) => assetIds && (this.soldAssetIds = assetIds));

          return forkJoin(
            this._appService.apiv2.getEditorAssets(),
            // this._assetService.get('', getAssetQuery, undefined, true),
            // this._userAssetService.getByUser(getUserAssetByUserQuery),
          );
        }),
      )
      .subscribe((assets) => {
        this.assets = assets[0];
        this.loading = false;
        this._cd.detectChanges();
      });

    // this._assetService.data$.pipe(untilDestroyed(this)).subscribe((assets) => {
    //   this.assets = assets;
    //   if (assets?.length) {
    //     this.lastAddedAsset = assets.reduce((a, b) => (new Date(a.DateCreated) > new Date(b.DateCreated) ? a : b));
    //   }
    // });

    // this._userAssetService.data$.pipe(untilDestroyed(this)).subscribe((userAssets) => {
    //   this.ownerAssets = userAssets.filter((val) => val.Permission === Permission.Owner);
    // });
  }

  setCheckedTags(tags: string[]) {
    this.checkedTags = [...tags];
    if (tags.includes('Environment')) {
      this.checkedTags.push('Background');
    } else {
      this.checkedTags = this.checkedTags.filter((tag) => tag !== 'Background');
    }
  }

  isOwner(assetId: string) {
    return true; //this.accountOption?.Role === EnterpriseRole.Owner || !!this.ownerAssets?.some((item) => item.AssetId === assetId);
  }

  isSold(assetId: string) {
    return this.soldAssetIds && this.soldAssetIds.includes(assetId);
  }

  changeOrderBy(orderBy: ListOrderOption): void {
    this.orderBy = orderBy;
  }

  viewAsset(asset: IAsset) {
    if (this.isOwner(asset.Id)) {
      this._router.navigate(['editor', 'asset', asset.Id]);
    }
  }

  createNewAsset() {
    this._router.navigate(['editor', 'asset', NEW_ASSET_ID]);
  }
}

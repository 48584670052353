import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { XRAsset } from 'projects/data/src/lib/models/data/asset';
import { AccountOption } from 'projects/data/src/lib/models/data/account';
import { MarketItem } from 'projects/data/src/lib/models/data/market';
import { ListOrderOption } from 'projects/data/src/lib/models/interfaces/list-order-option';
import { AccountService } from 'projects/data/src/lib/services/account.service';
import { MarketService } from 'projects/data/src/lib/services/market.service';
import { combineLatest, filter, Subject, take, takeUntil } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-market-owned-list',
  templateUrl: './market-owned-list.component.html',
  styleUrls: ['./market-owned-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MarketOwnedListComponent implements OnInit, OnDestroy {
  loading = true;
  searchText = '';
  tags: string[] = ['Environment', 'Landscape', 'Stage', 'Object'];
  checkedTags: string[] = [];
  orderBy: ListOrderOption = { value: '-Likes', name: 'mostLiked' };

  asset!: MarketItem;
  assets: MarketItem[] = [];
  assetPreview!: XRAsset | undefined;
  showPreview = false;

  liked: string[] = [];
  selectedItems: string[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private _marketService: MarketService,
    private _cd: ChangeDetectorRef,
    private _accountService: AccountService,
  ) {
    this._marketService.likes$.pipe(takeUntil(this._destroy$)).subscribe((likes) => {
      this.liked = likes;
    });
  }

  async ngOnInit() {
    this._accountService.activeAccount$
      .pipe(
        filter((account) => !!account),
        take(1),
      )
      .subscribe(() => {
        const currentAccountOption = this._accountService.getCurrentAccount();
        currentAccountOption &&
          combineLatest([this._marketService.getPurchased(currentAccountOption.EnterpriseContract?.EnterpriseId)])
            .pipe(take(1))
            .subscribe(([items]) => {
              this.assets = items;
              this.loading = false;
              this._cd.markForCheck();
            });
      });

    this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe((data: AccountOption | null) => {
      if (data) {
        this.loading = true;
        this.searchText = '';
        this._marketService.getPurchased(data.EnterpriseContract?.EnterpriseId).then((items) => {
          this.assets = items;
          this.loading = false;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get filteredIds() {
    const Ids = this.assets.filter((item) => !this.checkedTags.length || this.checkedTags.includes(item.Type)).map((i) => i.Id);
    return Ids;
  }

  get isSelectedAll() {
    const Ids = [...this.filteredIds];
    const condition = !!this.selectedItems.length && Ids.every((i) => this.selectedItems.includes(i));
    return condition;
  }

  toggleSelectAll(value) {
    this.selectedItems.length = 0;
    value && this.selectedItems.push(...this.filteredIds);
  }

  setCheckedTags(tags: string[]) {
    if (this.isSelectedAll) {
      this.selectedItems.length = 0;
    }
    this.checkedTags = [...tags];
    if (tags.includes('Environment')) {
      this.checkedTags.push('Background');
    } else {
      this.checkedTags = this.checkedTags.filter((tag) => tag !== 'Background');
    }
  }

  changeOrderBy(orderBy: ListOrderOption): void {
    this.orderBy = orderBy;
  }

  toggleLike(item: MarketItem) {
    if (this.liked.includes(item.Id)) {
      this._marketService.deleteLike(item.Id).then(() => {
        item.Likes = item.Likes - 1;
      });
    } else {
      this._marketService.postLikes([item.Id]).then(() => {
        item.Likes = item.Likes + 1;
      });
    }
  }

  async openPreview(marketItem: MarketItem) {
    // this.assetPreview = await this._marketService.preview(marketItem.Id);
    this.asset = marketItem;
    await this._marketService.postViews([marketItem]);
    this.showPreview = true;
  }

  onPreviewClose(event) {
    this.showPreview = event;
  }

  onClickTag(event: string) {
    if (event.startsWith('#')) {
      event = event.replace('#', '');
    }
    this.searchText = event.trim();
  }

  onSelected(value: boolean, id: string): void {
    if (value) {
      this.selectedItems.push(id);
    } else {
      this.selectedItems = this.selectedItems.filter((item) => item !== id);
    }
  }

  isSelected(id: string): boolean {
    return this.selectedItems.includes(id);
  }
}

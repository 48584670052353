import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { filter, skip } from 'rxjs';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { ListOrderOption } from '@/data/src/lib/models/interfaces/list-order-option';
import { AccountService } from '@/data/src/lib/services/account.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { Account } from '@/data/src/lib/models/data/account';
import { AccountOption, AccountOptionType } from '@/data/src/lib/models/data/account';
import { EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { UrlService } from '@/data/src/lib/services/url.service';
import { environment } from '@/app/src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-oxr-owned',
  templateUrl: './oxr-owned.component.html',
  styleUrls: ['./oxr-owned.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OxrOwnedComponent implements OnInit {
  loading: boolean | undefined = undefined;

  public searchText = '';
  public searchTags: string[] = [];
  public scenes: XRScene[] | undefined = undefined;
  public OthersScenes: XRScene[] = [];
  public EnterpriseScenes: XRScene[] = [];
  public startIndex = 0;
  public endIndex = 16;

  orderingOptions: ListOrderOption[] = [
    { value: '-DateCreated', name: 'newest' },
    { value: 'DateCreated', name: 'oldest' },
    { value: '-DateModified', name: 'modifiedDesc' },
    { value: 'DateModified', name: 'modifiedAsc' },
    { value: 'Name', name: 'alphabeticallyAscShort' },
    { value: '-Name', name: 'alphabeticallyDescShort' },
  ];
  orderBy: ListOrderOption = this.orderingOptions[0];

  isEnterpriseOrAdmin = false;
  isEnterprise = false;
  server;
  plan = ScenePlan;
  account: Account | undefined;
  currentAccount: AccountOption | null = null;
  enterpriseRole = EnterpriseRole;

  get sceneLeft() {
    if (!this.currentAccount) return 0;
    return this.currentAccount.EnterpriseContract?.NumberOfSpaces
      ? this.currentAccount.EnterpriseContract.NumberOfSpaces - this.scenes!.length
      : undefined;
  }
  constructor(
    private elementRef: ElementRef,
    private sceneService: SceneService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private accountService: AccountService,
    private router: Router,
    private _cd: ChangeDetectorRef,
    private _urlService: UrlService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.accountService.activeAccount$
      .pipe(
        filter((account) => !!account),
        untilDestroyed(this),
      )
      .subscribe((account: Account | undefined) => {
        this.server = account?.Server;
        this.account = account;
        if (account) {
          this.isEnterpriseOrAdmin = this.accountService.isEnterpriseOrAdmin;
        }
      });

    this.sceneService.data$.pipe(untilDestroyed(this), skip(1)).subscribe((data: XRScene[]) => {
      this.scenes = [];
      this.scenes = data;
      this.loading = false;
      this._cd.detectChanges();
    });

    this.accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe(async (data: AccountOption | null) => {
      if (data) {
        this.searchText = '';
        this.loading = true;
        this._cd.detectChanges();
        this.currentAccount = data;
        this.isEnterprise = data.Type === AccountOptionType.Enterprise;
        await this.getScenes(data.EnterpriseContract?.EnterpriseId);
      }
    });

    const parent = (this.elementRef.nativeElement as HTMLElement).parentElement;
    if (parent) {
      parent.onscroll = () => {
        this.updateListIndices(parent);
      };
    }
  }

  async getScenes(enterpriseId: string | undefined) {
    const query = enterpriseId ? `enterpriseId=${enterpriseId}` : '';
    await this.sceneService.get('', query, undefined, true, true);
  }

  async createNewScene() {
    if (this.isEnterprise && this.currentAccount?.Role === this.enterpriseRole.Owner && this.account) {
      const scene = await this.sceneService.setEnterpriseTrial(this.currentAccount?.Id).catch((error: HttpErrorResponse) => {
        if (error.error === 'Enterprise Scene limit is reached!') {
          const message = this._modalService.open(ConfirmationComponent);
          message.instance.title = this._translateService.instant('shared.confirmation.notice');
          message.instance.body = this._translateService.instant('oxr.information.createSceneLimit', {
            enterpriseName: this.currentAccount?.EnterpriseContract?.Enterprise.Name,
          });
          message.instance.confirmAction = 'shared.confirmation.confirm';
          message.instance.showCancelAction = false;
        }
        return;
      });

      if (scene) {
        scene.Name = `${this.accountService.getUsername()}'s enterprise space`;
        this.sceneService.modify(scene);
        this.router.navigate(['oxr', 'space', scene.Id]);
      }
    } else if (this.isEnterprise === false && this.isEnterpriseOrAdmin) {
      const scene = await this.sceneService.getTrial(ScenePlan.Pro);
      if (scene) {
        scene.Name = `${this.accountService.getUsername()}'s trial space`;
        this.sceneService.modify(scene);
        this.router.navigate(['oxr', 'space', scene.Id]);
      }
    } else {
      this.router.navigate(['subscription']);
    }
  }

  changeOrderBy(orderBy: ListOrderOption): void {
    this.orderBy = orderBy;
  }

  openModal() {
    const modal = this._modalService.open(ConfirmationComponent);
    modal.result.then((res) => {});
  }

  updateListIndices(element: HTMLElement) {
    const ratio = Math.round(element.clientWidth / element.clientHeight) * 2;
    this.endIndex = Math.max(Math.round(((element.scrollTop + element.clientHeight) / 520) * 1.5) * ratio, this.endIndex);
  }

  setSelectedScene(id: string, middleClick: boolean) {
    if (middleClick) {
      this._urlService.windowOpenWithPlatform(`${environment.redirectURL}/oxr/space/${id}`, true);
      return;
    }
    this.router.navigate(['oxr', 'space', id]);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteParam } from '@/data/src/lib/enums/route-param';
import { CommunityListComponent } from './community/community-list/community-list.component';
import { CommunityComponent } from './community/community.component';
import { CommunityViewComponent } from './community/community-view/community-view.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MobileAccountPanelTermsOfUseComponent } from './components/layout/m-account-panel/m-account-panel-details/m-account-panel-terms-of-use/m-account-panel-terms-of-use.component';
import { MobileAccountPanelPrivacyPolicyComponent } from './components/layout/m-account-panel/m-account-panel-details/m-account-panel-privacy-policy/m-account-panel-privacy-policy.component';
import { MobileAccountPanelSubscriptionManagementComponent } from './components/layout/m-account-panel/m-account-panel-details/m-account-panel-subscription-management/m-account-panel-subscription-management.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { TempLandingComponent } from './temp-landing/temp-landing.component';

const routes: Routes = [
  {
    path: 'community',
    component: CommunityComponent,
    children: [
      {
        path: 'list',
        component: CommunityListComponent,
      },
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      {
        path: '**',
        redirectTo: 'list',
      },
    ],
  },
  {
    path: 'signUp',
    component: SignUpComponent,
  },
  {
    path: 'termsOfUse',
    component: MobileAccountPanelTermsOfUseComponent,
  },
  {
    path: 'privacyPolicy',
    component: MobileAccountPanelPrivacyPolicyComponent,
  },
  {
    path: 'subscription',
    component: MobileAccountPanelSubscriptionManagementComponent,
  },
  {
    path: `view/:${RouteParam.Scene}`,
    component: CommunityViewComponent,
  },
  {
    path: '',
    component: TempLandingComponent,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', initialNavigation: 'enabledBlocking', useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<oxr-toolbar
  (thumbnailClicked)="onThumbnail()"
  (saveClicked)="onSave()"
  (saveAsClicked)="onSaveAs()"
  (previewClicked)="onPreview()"
  [isCreatingAsset]="isCreating"
></oxr-toolbar>
@if (asset) {
  <ui-body class="p-0">
    @if (showCaptureZone) {
      <section #captureZone class="capture-zone icon-capture">
        <span class="help-text">{{ 'editor.creatingAsset.pleaseFocus' | translate }}</span>
      </section>
    }
    @if (showCaptureZone) {
      <section class="capture-button-group">
        <button ui-outlined-button secondary sm (click)="exitCaptureMode()">
          {{ 'shared.actions.cancel' | translate }}
        </button>
        <button ui-button sm (click)="captureScreen()">
          {{ 'editor.creatingAsset.shoot' | translate }}
        </button>
      </section>
    }
    <img #uncroppedImage class="uncropped-image" [src]="image" />
    @if (showCroppedImage) {
      <div class="preview-image-container">
        <img class="preview-image" [src]="croppedImage" />
      </div>
      <div class="capture-button-group">
        <button ui-outlined-button secondary sm [disabled]="isLoading" (click)="onThumbnailRetake()">
          {{ 'editor.creatingAsset.retake' | translate }}
        </button>
        <button ui-button sm [disabled]="isLoading" (click)="onThumbnailSave()">{{ 'shared.actions.save' | translate }}</button>
      </div>
    }
    <view-canvas #viewCanvas [activeModel]="asset" [readonly]="false"></view-canvas>
  </ui-body>
}
<ui-panels-menu [canOpen]="true">
  @if (!!asset && !!assetVersionElement) {
    <ui-info-panel [asset]="asset" [element]="assetVersionElement"></ui-info-panel>
    <ui-asset-versions-panel *ngIf="!isCreating" [asset]="asset"></ui-asset-versions-panel>
    <ui-environment-panel
      [disableType]="true"
      [disableRotation]="true"
      [disableLevel]="isAssetObject"
      [disableIntensity]="!isAssetObject"
    ></ui-environment-panel>
    @if (isAssetObject) {
      <ui-transformation-panel [element]="assetVersionElement"></ui-transformation-panel>
      <!-- <ui-customization-panel [element]="assetVersionElement"></ui-customization-panel> -->
    }
  }
</ui-panels-menu>

<section class="actions" [class.sticky]="scrollingUp">
  <section uiMWrapper search class="search">
    <div class="icon icon-search"></div>
    <input uiMInput placeholder="{{ 'shared.fields.searchPlaceholder' | translate }}" type="text" [formControl]="searchControl" />
    @if (filterText) {
      <div class="icon icon-close-circle" (click)="searchControl.reset()"></div>
    }
  </section>
  <section class="sort-container">
    <ui-m-dropdown [position]="dropdownPositions.bottomCenter">
      <div #sort trigger uiMPill class="sort-btn">
        <div class="icon icon-settings-horizontal"></div>
        {{ 'shared.fields.' + orderBy.name | translate }}
      </div>
      <div class="sort-dropdown">
        <div class="header">
          <div class="title">{{ 'shared.fields.sort' | translate }}</div>
          <div class="icon icon-close" (click)="sort.click()"></div>
        </div>
        <div class="sort-pills-container">
          @for (option of orderingOptions; track option) {
            <div uiMPill [ngClass]="option.name === orderBy.name ? 'primary' : 'grey'" (click)="onOrderBy(option, $event, sort)">
              {{ 'shared.fields.' + option.name | translate }}
            </div>
          }
        </div>
      </div>
    </ui-m-dropdown>
    <div
      uiMRoundButton
      class="refresh"
      [class.disabled]="orderBy.name === 'recommended'"
      (click)="onOrderBy(orderingOptions[0], $event, null)"
    >
      <div class="icon icon-refresh"></div>
    </div>
  </section>
</section>
@if (scenes$ | async; as scenes) {
  <cdk-virtual-scroll-viewport [itemSize]="148">
    <m-community-item
      *cdkVirtualFor="
        let scene of scenes
          | filterBy: ['Name', 'Tags', 'Username'] : (filterText | removeComma | removeHash)
          | orderByImpure: orderBy.value
      "
      [scene]="scene"
      (imageClick)="viewScene($event)"
    ></m-community-item>
  </cdk-virtual-scroll-viewport>
}
@if (!!previousScroll) {
  <div uiMRoundButton class="up-btn" (click)="onScrollTop()">
    <div class="icon icon-chevron-up"></div>
  </div>
}

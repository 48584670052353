<div class="title-wrapper">
  <div class="title">{{ 'domain.titleOfDomainSearch1' | translate }}</div>
  <div class="title">{{ 'domain.titleOfDomainSearch2' | translate }}</div>
</div>

<div class="card-wrapper">
  <ui-domain-card-description
    [settingData]="settingData1"
    [showingRecommend]="true"
    (clicked)="onClick($event)"
    [loading]="loading"
  ></ui-domain-card-description>
  <ui-domain-card-description [settingData]="settingData2" (clicked)="onClick($event)" [showComingSoonBtn]="true">
  </ui-domain-card-description>
  <ui-domain-card-description [settingData]="settingData3" (clicked)="onClick($event)" [showComingSoonBtn]="true">
  </ui-domain-card-description>
</div>

import 'zone.js/plugins/task-tracking';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, NgZone } from '@angular/core';
import { COMPOSITION_BUFFER_MODE, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UiModule } from 'projects/ui/src/public-api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OxrComponent } from './oxr/oxr.component';
import { MarketComponent } from './market/market.component';
import { OxrDashboardComponent } from './oxr/oxr-dashboard/oxr-dashboard.component';
import { OxrPricingComponent } from './oxr/oxr-pricing/oxr-pricing.component';
import { OxrAnalyticsComponent } from './oxr/oxr-analytics/oxr-analytics.component';
import { MarketCardDescriptionComponent } from './market/market-card-description/market-card-description.component';
import { OxrCardDescriptionComponent } from './oxr/oxr-card-description/oxr-card-description.component';
import { CommunityComponent } from './community/community.component';
import { CommunityCardDescriptionComponent } from './community/community-card-description/community-card-description.component';
import { EditorComponent } from './editor/editor.component';
import { EditorCardDescriptionComponent } from './editor/editor-card-description/editor-card-description.component';
import { OxrSpaceComponent } from './oxr/oxr-space/oxr-space.component';
import { DataModule } from 'projects/data/src/lib/data.module';
import { QuillModule } from 'ngx-quill';
import { ViewModule } from 'projects/view/src/app/module/view.module';
import { ClipboardModule } from 'ngx-clipboard';
import { EditorViewComponent } from './editor/editor-view/editor-view.component';
import { CommunityListComponent } from './community/community-list/community-list.component';
import { CommunityLikedListComponent } from './community/community-liked-list/community-liked-list.component';
import { MarketListComponent } from './market/market-list/market-list.component';
import { MarketLikedListComponent } from './market/market-liked-list/market-liked-list.component';
import { MarketCartListComponent } from './market/market-cart-list/market-cart-list.component';
import { MarketOwnedListComponent } from './market/market-owned-list/market-owned-list.component';
import { ToastrModule } from 'ngx-toastr';
import { ToastComponent } from 'projects/ui/src/lib/components/toast/toast.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarketCardPreviewComponent } from './market/market-card-preview/market-card-preview.component';
import { EditorListComponent } from './editor/editor-list/editor-list.component';
import { OxrPublishComponent } from './oxr/oxr-space/oxr-publish/oxr-publish.component';
import { DomainComponent } from './domain/domain.component';
import { DomainOwnedListComponent } from './domain/domain-owned-list/domain-owned-list.component';
import { DomainPageConnectionComponent } from './domain/domain-page-connection/domain-page-connection.component';
import { DomainSearchComponent } from './domain/domain-search/domain-search.component';
import { PaymentResultComponent } from './payment/payment-result/payment-result.component';
import { OxrOwnedComponent } from './oxr/oxr-owned/oxr-owned.component';
import { OxrPurchaseHistoryComponent } from './oxr/oxr-purchase-history/oxr-purchase-history.component';
import { OxrSubscriptionManageComponent } from './oxr/oxr-subscription-manage/oxr-subscription-manage.component';
import { GuideComponent } from './guide/guide.component';
import { DomainSettingOwnxrComponent } from './domain/domain-search/domain-setting-ownxr/domain-setting-ownxr.component';
import { DomainSettingBuyComponent } from './domain/domain-search/domain-setting-buy/domain-setting-buy.component';
import { DomainSettingConnectionComponent } from './domain/domain-search/domain-setting-connection/domain-setting-connection.component';
import { OxrToolbarComponent } from './oxr/oxr-space/oxr-toolbar/oxr-toolbar.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './termsOfUse/termsOfUse.component';
import { ApplicationsComponent } from './applications/applications.component';
import { interval, take } from 'rxjs';
import { OxrCollaboratorComponent } from './oxr/oxr-collaborator/oxr-collaborator.component';
import { CollaboratorInvitationComponent } from './oxr/oxr-collaborator/collaborator-invitation/collaborator-invitation.component';
import { CollaboratorGroupEditComponent } from './oxr/oxr-collaborator/collaborator-group-edit/collaborator-group-edit.component';
import { OxrManualComponent } from './oxr/oxr-manual/oxr-manual.component';
import { DomainDisplayCardComponent } from './domain/domain-display-card/domain-display-card.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { environment } from '../environments/environment';
import { CustomInterceptor } from './custom-interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInitializerService } from '@/data/src/lib/services/app-initializer.service';
import { MsalLocaleGuard } from '@/data/src/lib/guards/msalLocaleGuard.guard';
import { TempLandingComponent } from './temp-landing/temp-landing.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SuccessComponent } from './subscription/success/success.component';
import { FailureComponent } from './subscription/failure/failure.component';
import { SubscriptionPlanComponent } from './subscription/subscription-plan/subscription-plan.component';
import { SubscriptionPlanCardComponent } from './subscription/subscription-plan-card/subscription-plan-card.component';
import { SubscriptionPlanDetailComponent } from './subscription/subscription-plan-detail/subscription-plan-detail.component';

export const toastrConfiguration = {
  toastComponent: ToastComponent,
  closeButton: false,
  timeOut: 5000,
  extendedTimeOut: 2000,
  easing: 'ease-in',
  easeTime: 200,
  toastClass: 'oxr-toastr',
  enableHtml: true,
  progressBar: false,
  positionClass: 'toast-bottom-right',
  tapToDismiss: false,
  onActivateClick: false,
  iconClasses: {
    error: 'oxr-toast-error',
    info: 'oxr-toast-info',
    success: 'oxr-toast-success',
    warning: 'oxr-toast-warning',
  },

  maxOpened: 0,
  autoDismiss: true,
  newestOnTop: true,
  preventDuplicates: false,
};

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: environment.redirectURL,
      postLogoutRedirectUri: environment.redirectURL,
      knownAuthorities: [environment.b2cPolicies.authorityDomain],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['openid'],
    },
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[] | null>();

  for (const region of environment.apiConfig.uri) {
    protectedResourceMap.set(region, environment.apiConfig.scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function initializeApp(_appInitializerService: AppInitializerService) {
  return () => _appInitializerService.initializeApp();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    GuideComponent,
    OxrComponent,
    OxrDashboardComponent,
    OxrPricingComponent,
    OxrAnalyticsComponent,
    OxrCardDescriptionComponent,
    OxrPublishComponent,
    OxrToolbarComponent,
    OxrSpaceComponent,
    MarketComponent,
    MarketListComponent,
    MarketLikedListComponent,
    MarketCartListComponent,
    MarketOwnedListComponent,
    MarketCardDescriptionComponent,
    CommunityComponent,
    CommunityListComponent,
    CommunityLikedListComponent,
    CommunityCardDescriptionComponent,
    EditorComponent,
    EditorListComponent,
    EditorCardDescriptionComponent,
    EditorViewComponent,
    MarketCardPreviewComponent,
    DomainComponent,
    DomainOwnedListComponent,
    DomainPageConnectionComponent,
    DomainSearchComponent,
    DomainSettingOwnxrComponent,
    DomainSettingBuyComponent,
    DomainSettingConnectionComponent,
    PaymentResultComponent,
    OxrOwnedComponent,
    SuccessComponent,
    FailureComponent,
    OxrPurchaseHistoryComponent,
    OxrSubscriptionManageComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    ApplicationsComponent,
    OxrCollaboratorComponent,
    CollaboratorInvitationComponent,
    CollaboratorGroupEditComponent,
    OxrManualComponent,
    DomainDisplayCardComponent,
    ErrorPageComponent,
    SubscriptionPlanComponent,
    SubscriptionPlanCardComponent,
    SubscriptionPlanDetailComponent,
    TempLandingComponent,
    SubscriptionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    DataModule,
    UiModule,
    ViewModule,
    ClipboardModule,
    ToastrModule.forRoot(toastrConfiguration),
    QuillModule.forRoot(),
    NgxSliderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MsalModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AppInitializerService],
    },
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    provideHttpClient(withFetch()),
    MsalService,
    MsalLocaleGuard,
    MsalBroadcastService,
    AppInitializerService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(private _ngZone: NgZone) {
    if (!environment.production) {
      this.trackingAsyncTasks();
    }
  }

  /**
   * Angular Universal SSR (Server Side Rendering) hangs when some asynchronous task in our app is not completed.
   * Since version 16, Angular SSR no longer counts outgoing HTTP requests as macrotasks in Zone.js, but uses an internal http interceptor function instead.
   */
  trackingAsyncTasks() {
    /**
     * CONFIGURE how long to wait (in milliseconds)
     * before the pending tasks are dumped to the console.
     */
    const WAIT_MS = 2000;

    console.log(`⏳ ... Wait ${WAIT_MS} ms to dump pending tasks ... ⏳`);

    // Run the debugging `interval` code outside of
    // the Angular Zone, so it's not considered as
    // yet another pending Zone Task:
    this._ngZone.runOutsideAngular(() => {
      interval(WAIT_MS)
        .pipe(take(1))
        .subscribe(() => {
          // Access the NgZone's internals - TaskTrackingZone:
          const TaskTrackingZone = (this._ngZone as any)._inner._parent._properties.TaskTrackingZone;

          // Print to the console all pending tasks
          // (micro tasks, macro tasks and event listeners):
          console.debug('👀 Pending tasks in NgZone: 👀');
          console.debug({
            // eventTasks: TaskTrackingZone.getTasksFor('eventTask'),
            microTasks: TaskTrackingZone.getTasksFor('microTask'),
            macroTasks: TaskTrackingZone.getTasksFor('macroTask'),
          });

          // Advice how to find the origin of Zone tasks:
          console.debug(`👀 For every pending Zone Task listed above investigate the stacktrace in the property 'creationLocation' 👆`);
        });
    });
  }
}

@if (!isMappedDomain) {
  <section class="container">
    <header class="header">
      <div class="ownxr-letter"></div>
      <h1 class="title">{{ 'tempLandingPage.title' | translate }}</h1>
    </header>
    <nav class="navigation">
      <button
        ui-button
        md
        (click)="loginRedirect()"
        uiTooltip="{{ 'header.login.signUp.promoteTooltip' | translate }}"
        [tooltipPosition]="tooltipPosition.Below"
        [tooltipClasses]="'border-highlight'"
      >
        {{ 'tempLandingPage.navigation.startNow' | translate }}
      </button>

      <button ui-button md outlined (click)="goToCommunity()">
        {{ 'tempLandingPage.navigation.community' | translate }}
      </button>
    </nav>
  </section>
} @else {
  <m-community-view></m-community-view>
}

<section class="package top">
  <div class="header-card-wrapper">
    <div class="package-header">{{ 'subscriptionPlan.title' | translate }}</div>
    <div class="plan-card-wrapper">
      <app-subscription-plan-card
        [subscriptionPlanCard]="subscriptionPlanCards[0]"
        (onButtonClick)="onClick('Experience')"
      ></app-subscription-plan-card>
      <app-subscription-plan-card
        [subscriptionPlanCard]="subscriptionPlanCards[1]"
        (onButtonClick)="onClick('StripeCheckout')"
      ></app-subscription-plan-card>
      <app-subscription-plan-card
        [subscriptionPlanCard]="subscriptionPlanCards[2]"
        (onButtonClick)="onClick('Inquiry')"
      ></app-subscription-plan-card>
    </div>
  </div>

  <footer
    class="package-footer vertical-bounce-animation"
    (click)="showDetail = !showDetail"
    [ngStyle]="{
      '--animation-play-state': !showDetail ? 'running' : 'paused',
      '--animation-duration': '800ms',
      '--animation-delay': '200ms',
      '--animation-timing-function': 'linear',
      '--animation-direction': 'alternate',
      '--end-transform-y': '100%',
      '--mid-transform-y': '50%'
    }"
  >
    <p class="icon icon-double-arrow"></p>
    <p class="open-detail-button">
      {{ 'subscriptionPlan.openDetailButtonDescription' | translate }}
    </p>
  </footer>
</section>

@if (showDetail) {
  <section class="package" [ngStyle]="{ height: 'unset', gap: '40px' }">
    <div class="header-card-wrapper">
      <div class="package-header">{{ 'subscriptionPlan.subTitle' | translate }}</div>
      <app-subscription-plan-detail></app-subscription-plan-detail>
    </div>

    <footer class="package-footer" (click)="scrollToTop()">
      <p class="icon icon-double-arrow rotate"></p>
      <p class="open-detail-button">
        {{ 'subscriptionPlan.scrollToTheTop' | translate }}
      </p>
    </footer>
  </section>
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from '@/data/src/lib/models/data/account';
import { AccountService } from '@/data/src/lib/services/account.service';
import { TooltipAnimation, TooltipPosition } from '@/ui/src/lib/directive/tooltip.directive';
import { UrlService } from '@/data/src/lib/services/url.service';

@Component({
  selector: 'app-temp-landing',
  templateUrl: './temp-landing.component.html',
  styleUrl: './temp-landing.component.scss',
})
export class TempLandingComponent {
  account?: Account;
  tooltipPosition = TooltipPosition;
  tooltipAnimation = TooltipAnimation;
  isMappedDomain = this._urlService.urlTransformResult.isMappedDomain;

  constructor(
    private _urlService: UrlService,
    private _accountService: AccountService,
    private _router: Router,
  ) {
    this.account = this._accountService.account;
  }

  ngOnInit() {}

  loginRedirect() {
    this.account ? this._router.navigate(['oxr']) : this._accountService.loginRedirect();
  }

  goToCommunity() {
    this._router.navigate(['community']);
  }
}

<div class="preview-window">
  <div class="viewer">
    <div class="view">
      @if (assetPreview) {
        <view-canvas [readonly]="true" [activeModel]="assetPreview"></view-canvas>
      } @else {
        <ui-loader [fullScreen]="true"></ui-loader>
        <div class="close icon icon-close" (click)="onClose()"></div>
      }
      @if (assetPreview) {
        @if (zommStatus == 'zoomOut') {
          <div class="zoom icon icon-zoom-in" (click)="setZoomStatus('zoomIn')"></div>
        }
        @if (zommStatus == 'zoomIn') {
          <div class="zoom icon icon-zoom-out" (click)="setZoomStatus('zoomOut')"></div>
        }
        @if (zommStatus == 'zoomIn') {
          <div class="close icon icon-close" (click)="onClose()"></div>
        }
      }
    </div>
    @if (assetPreview) {
      @if (zommStatus == 'zoomOut') {
        <div class="view-info">
          <div class="close icon icon-close" (click)="onClose()"></div>
          <div class="header">
            <div class="asset-title">
              <div class="asset-name">{{ assetPreview.Name }}</div>
              <div class="asset-token">${{ marketItem.Tokens }}</div>
            </div>
            <div class="asset-dateModified">
              {{ 'market.cardPreview.uploadDate' | translate }} :
              {{
                (assetPreview.DateModified?.startsWith('0001') ? assetPreview.DateCreated : assetPreview.DateModified)
                  | date: 'yyyy.MM.dd HH:mm:ss'
              }}
            </div>
            <div class="asset-user">
              @if (!marketItem.UserThumbnail) {
                <div class="asset-userThumbnail">
                  <div class="inner-circle"></div>
                </div>
              }
              @if (marketItem.UserThumbnail) {
                <img class="asset-userThumbnail" [src]="marketItem.UserThumbnail" />
              }
              <div class="asset-userId">{{ marketItem.Username }}</div>
            </div>
            <ui-tag [tagsString]="marketItem.Tags" (tagSelected)="onSelectTag($event)"></ui-tag>
          </div>
          <div class="body">
            <div>{{ 'market.cardPreview.information' | translate }}</div>
            <div class="file">
              <div>{{ 'market.cardPreview.fileSize' | translate }}</div>
              <!-- <div>{{ assetPreview.Length / 1000000 | number: '0.2-2' }}MB</div> -->
            </div>
            <div class="asset">
              <div>{{ 'market.cardPreview.assetSize' | translate }}</div>
            </div>
          </div>
          <div class="footer">
            <div class="drop-shadow">
              <div class="icon" (click)="toggleLike()" [ngClass]="{ 'icon-like-fill ': isLiked, 'icon-like': !isLiked }"></div>
            </div>
            <div class="drop-shadow" (click)="onShareClick()">
              <div class="icon icon-share"></div>
            </div>
            <div class="purchase">
              @if (!isInCart && !isPurchased) {
                <button ui-button sm (click)="toggleCart()">{{ 'market.addToCart' | translate }}</button>
              }
              @if (isInCart) {
                <button ui-button secondary sm (click)="toggleCart()">{{ 'market.removeFromCart' | translate }}</button>
              }
              @if (isPurchased) {
                <button ui-button sm class="disabled">{{ 'market.owned' | translate }}</button>
              }
            </div>
          </div>
        </div>
      }
    }
  </div>
</div>

import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Location, DOCUMENT } from '@angular/common';
import { Observable, of, from } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import {
  AccountInfo,
  AuthenticationResult,
  BrowserConfigurationAuthError,
  InteractionType,
  StringUtils,
  UrlString,
} from '@azure/msal-browser';
import { Injectable, Inject, Injector } from '@angular/core';
import {
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
  MsalService,
  MsalInterceptorAuthRequest,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { MatchingResources } from '@azure/msal-angular/msal.interceptor.config';
import { ModalService } from 'projects/ui/src/lib/modal/modal.service';
import { ConfirmationComponent } from 'projects/ui/src/lib/modal/confirmation/confirmation.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfiguration,
    private injector: Injector,
    private modalService: ModalService,
    private authService: MsalService,
    private location: Location,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let httpRequest;

    // ipify.org does not allow Pragma or cache-control header
    if (req.url.includes('api.ipify.org')) {
      httpRequest = req;
    } else {
      httpRequest = req.clone({
        headers: req.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate').set('Pragma', 'no-cache').set('Expires', '0'),
      });
    }

    if (
      this.msalInterceptorConfig.interactionType !== InteractionType.Popup &&
      this.msalInterceptorConfig.interactionType !== InteractionType.Redirect
    ) {
      throw new BrowserConfigurationAuthError(
        'invalid_interaction_type',
        'Invalid interaction type provided to MSAL Interceptor. InteractionType.Popup, InteractionType.Redirect must be provided in the msalInterceptorConfiguration',
      );
    }

    if (!httpRequest.url.startsWith('http')) {
      return next.handle(httpRequest);
    }
    // this.authService.getLogger().verbose('MSAL Interceptor activated');
    const scopes = this.getScopesForEndpoint(httpRequest.url, httpRequest.method);

    // If no scopes for endpoint, does not acquire token
    if (!scopes || scopes.length === 0) {
      // this.authService.getLogger().verbose('Interceptor - no scopes for endpoint');
      return next.handle(httpRequest);
    }

    // Sets account as active account or first account
    let account: AccountInfo;
    const authRequest = (account: AccountInfo) =>
      typeof this.msalInterceptorConfig.authRequest === 'function'
        ? this.msalInterceptorConfig.authRequest(this.authService, httpRequest, { account: account })
        : { ...this.msalInterceptorConfig.authRequest, account };

    account = <AccountInfo>this.authService.instance.getActiveAccount() ?? this.authService.instance.getAllAccounts()[0];

    if (!account) {
      const instance = this.modalService.open(ConfirmationComponent);
      if (instance) {
        const translateService = this.injector.get(TranslateService);
        instance.instance.title = translateService.instant('shared.information.information');
        instance.instance.body = translateService.instant('shared.confirmation.loginRequired');
        instance.instance.confirmAction = translateService.instant('shared.confirmation.login');
        return from(instance.result).pipe(
          switchMap((res) => {
            if (res) {
              return this.accountLogin(httpRequest, next, authRequest(account), scopes, account);
            }
            return next.handle(httpRequest);
          }),
        );
      } else {
        return next.handle(httpRequest);
      }
    } else {
      // this.authService.getLogger().info(`Interceptor - ${scopes.length} scopes found for ${httpRequest.url}`);
      // this.authService.getLogger().infoPii(`Interceptor - [${scopes}] scopes found for ${httpRequest.url}`);

      // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
      return this.accountLogin(httpRequest, next, authRequest(account), scopes, account);
    }
  }

  private accountLogin(
    req: HttpRequest<any>,
    next: HttpHandler,
    authRequest: MsalInterceptorAuthRequest,
    scopes: string[],
    account: AccountInfo,
  ) {
    return this.authService.acquireTokenSilent({ ...authRequest, scopes, account }).pipe(
      catchError(() => {
        // this.authService.getLogger().error('Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve.');
        return this.acquireTokenInteractively(authRequest, scopes);
      }),
      switchMap((result: AuthenticationResult) => {
        if (!result.accessToken) {
          // this.authService
          //   .getLogger()
          //   .error(
          //     'Interceptor - acquireTokenSilent resolved with null access token. Known issue with B2C tenants, invoking interaction to resolve.',
          //   );
          return this.acquireTokenInteractively(authRequest, scopes);
        }
        return of(result);
      }),
      switchMap((result: AuthenticationResult) => {
        // this.authService.getLogger().verbose('Interceptor - setting authorization headers');
        const headers = req.headers.set('Authorization', `Bearer ${result.accessToken}`);

        const requestClone = req.clone({ headers });
        return next.handle(requestClone);
      }),
    );
  }

  /**
   * Invoke interaction for the given set of scopes
   * @param authRequest Request
   * @param scopes Array of scopes for the request
   * @returns Result from the interactive request
   */
  private acquireTokenInteractively(authRequest: MsalInterceptorAuthRequest, scopes: string[]): Observable<AuthenticationResult> {
    if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
      // this.authService.getLogger().verbose('Interceptor - error acquiring token silently, acquiring by popup');
      return this.authService.acquireTokenPopup({ ...authRequest, scopes });
    }
    // this.authService.getLogger().verbose('Interceptor - error acquiring token silently, acquiring by redirect');
    const redirectStartPage = this._document.location.href;
    return this.authService.acquireTokenRedirect({ ...authRequest, scopes, redirectStartPage }).pipe(
      switchMap(() => {
        return of({} as AuthenticationResult);
      }),
    );
  }

  /**
   * Looks up the scopes for the given endpoint from the protectedResourceMap
   * @param endpoint Url of the request
   * @param httpMethod Http method of the request
   * @returns Array of scopes, or null if not found
   *
   */
  private getScopesForEndpoint(endpoint: string, httpMethod: string): Array<string> | null {
    // this.authService.getLogger().verbose('Interceptor - getting scopes for endpoint');

    // Ensures endpoints and protected resources compared are normalized
    const normalizedEndpoint = this.location.normalize(endpoint);

    const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());

    const matchingProtectedResources = this.matchResourcesToEndpoint(protectedResourcesArray, normalizedEndpoint);

    // Check absolute urls of resources first before checking relative to prevent incorrect matching where multiple resources have similar relative urls
    if (matchingProtectedResources.absoluteResources.length > 0) {
      return this.matchScopesToEndpoint(
        this.msalInterceptorConfig.protectedResourceMap,
        matchingProtectedResources.absoluteResources,
        httpMethod,
      );
    } else if (matchingProtectedResources.relativeResources.length > 0) {
      return this.matchScopesToEndpoint(
        this.msalInterceptorConfig.protectedResourceMap,
        matchingProtectedResources.relativeResources,
        httpMethod,
      );
    }

    return null;
  }

  /**
   * Finds resource endpoints that match request endpoint
   * @param protectedResourcesEndpoints
   * @param endpoint
   * @returns
   */
  private matchResourcesToEndpoint(protectedResourcesEndpoints: string[], endpoint: string): MatchingResources {
    const matchingResources: MatchingResources = { absoluteResources: [], relativeResources: [] };

    protectedResourcesEndpoints.forEach((key) => {
      // Normalizes and adds resource to matchingResources.absoluteResources if key matches endpoint. StringUtils.matchPattern accounts for wildcards
      const normalizedKey = this.location.normalize(key);
      if (StringUtils.matchPattern(normalizedKey, endpoint)) {
        matchingResources.absoluteResources.push(key);
      }

      // Get url components for relative urls
      const absoluteKey = this.getAbsoluteUrl(key);
      const keyComponents = new UrlString(absoluteKey).getUrlComponents();
      const absoluteEndpoint = this.getAbsoluteUrl(endpoint);
      const endpointComponents = new UrlString(absoluteEndpoint).getUrlComponents();

      // Normalized key should include query strings if applicable
      const relativeNormalizedKey = keyComponents.QueryString
        ? `${keyComponents.AbsolutePath}?${keyComponents.QueryString}`
        : this.location.normalize(keyComponents.AbsolutePath);

      // Add resource to matchingResources.relativeResources if same origin, relativeKey matches endpoint, and is not empty
      if (
        keyComponents.HostNameAndPort === endpointComponents.HostNameAndPort &&
        StringUtils.matchPattern(relativeNormalizedKey, absoluteEndpoint) &&
        relativeNormalizedKey !== '' &&
        relativeNormalizedKey !== '/*'
      ) {
        matchingResources.relativeResources.push(key);
      }
    });

    return matchingResources;
  }

  /**
   * Transforms relative urls to absolute urls
   * @param url
   * @returns
   */
  private getAbsoluteUrl(url: string): string {
    const link = this._document?.createElement('a') ?? { href: '' };
    link.href = url;
    return link.href;
  }

  /**
   * Finds scopes from first matching endpoint with HTTP method that matches request
   * @param protectedResourceMap Protected resource map
   * @param endpointArray Array of resources that match request endpoint
   * @param httpMethod Http method of the request
   * @returns
   */
  private matchScopesToEndpoint(
    protectedResourceMap: Map<string, Array<string | ProtectedResourceScopes> | null>,
    endpointArray: string[],
    httpMethod: string,
  ): Array<string> | null {
    const allMatchedScopes: (string[] | null)[] = [];

    // Check each matched endpoint for matching HttpMethod and scopes
    endpointArray.forEach((matchedEndpoint) => {
      const scopesForEndpoint: string[] = [];
      const methodAndScopesArray = protectedResourceMap.get(matchedEndpoint) ?? [];

      // Return if resource is unprotected
      if (methodAndScopesArray === null) {
        allMatchedScopes.push(null);
        return;
      }

      methodAndScopesArray.forEach((entry) => {
        // Entry is either array of scopes or ProtectedResourceScopes object
        if (typeof entry === 'string') {
          scopesForEndpoint.push(entry);
        } else {
          // Ensure methods being compared are normalized
          const normalizedRequestMethod = httpMethod.toLowerCase();
          const normalizedResourceMethod = entry.httpMethod.toLowerCase();
          // Method in protectedResourceMap matches request http method
          if (normalizedResourceMethod === normalizedRequestMethod) {
            // Validate if scopes comes null to unprotect the resource in a certain http method
            if (entry.scopes === null) {
              allMatchedScopes.push(null);
            } else {
              entry.scopes.forEach((scope) => {
                scopesForEndpoint.push(scope);
              });
            }
          }
        }
      });

      // Only add to all scopes if scopes for endpoint and method is found
      if (scopesForEndpoint.length) {
        allMatchedScopes.push(scopesForEndpoint);
      }
    });

    if (allMatchedScopes.length) {
      if (allMatchedScopes.length > 1) {
        // this.authService.getLogger().warning('Interceptor - More than 1 matching scopes for endpoint found.');
      }
      // Returns scopes for first matching endpoint
      return allMatchedScopes[0];
    }

    return null;
  }
}

import { Router, ActivatedRoute } from '@angular/router';
import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { Account, AccountOptionType } from '@/data/src/lib/models/data/account';
import { AccountService } from '@/data/src/lib/services/account.service';
import { DomainMap } from '@/data/src/lib/models/data/domain';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { ListOrderOption } from '@/data/src/lib/models/interfaces/list-order-option';
import { DomainService } from '@/data/src/lib/services/domain.service';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { ConfirmationComponent } from '@/ui/src/lib/modal/confirmation/confirmation.component';
import { ModalService } from '@/ui/src/lib/modal/modal.service';
import { DomainType } from '@/data/src/lib/enums/domain';
import { EnterpriseRole } from '@/data/src/lib/models/data/enterprise';
import { environment } from '@/app/src/environments/environment';
import { Configurations } from '@/data/src/lib/enums/configurations';
import { PricingPlanService } from '@/data/src/lib/services/pricing-plan.service';

@UntilDestroy()
@Component({
  selector: 'app-domain-setting-ownxr',
  templateUrl: './domain-setting-ownxr.component.html',
  styleUrls: ['./domain-setting-ownxr.component.scss'],
})
export class DomainSettingOwnxrComponent implements OnInit, OnDestroy {
  searchText: string;
  loading = true;
  itemsPresent = false;
  setSubdomain = false;
  selectedScene: XRScene;
  isValid: boolean = false;
  isAvailable: boolean = false;
  isClicked: boolean = false;
  isSwear: boolean = false;
  inputTouched = false;
  subdomain = '';
  domain = '';
  domainName =
    environment.configuration === Configurations.Production
      ? '.ownxr.com'
      : environment.configuration === Configurations.Staging
        ? '.staging.ownxr.com'
        : '.feature.ownxr.com';
  public scenes: XRScene[] = [];
  public takenScenes: DomainMap[] = [];
  public endIndex = 16;
  account: Account | undefined;
  domainToChangeSpace: DomainMap | undefined;
  plan = ScenePlan;

  orderingOptions: ListOrderOption[] = [
    { value: '-DateCreated', name: 'newest' },
    { value: 'DateCreated', name: 'oldest' },
    { value: '-DateModified', name: 'modifiedDesc' },
    { value: 'DateModified', name: 'modifiedAsc' },
  ];
  orderBy: ListOrderOption = this.orderingOptions[0];

  isEnterprise = false;
  isCollaborator = false;

  constructor(
    private _sceneService: SceneService,
    private _modalService: ModalService,
    private elementRef: ElementRef,
    private _translateService: TranslateService,
    private _router: Router,
    private _domainService: DomainService,
    private _accountService: AccountService,
    private _activatedRoute: ActivatedRoute,
    private _pricingPlanService: PricingPlanService,
  ) {}

  ngOnDestroy(): void {
    this._domainService.setChangeSubject(undefined);
  }

  async ngOnInit(): Promise<void> {
    await this._accountService.currentAccountOption$.pipe(untilDestroyed(this)).subscribe(async (currentAccountOption) => {
      if (currentAccountOption) {
        this.loading = true;
        this.isEnterprise = currentAccountOption.Type === AccountOptionType.Enterprise;
        this.isCollaborator = currentAccountOption.Role === EnterpriseRole.Collaborator;
        this.scenes = [];
        this.itemsPresent = false;
        const scenes = await this.getData(currentAccountOption.EnterpriseContract?.EnterpriseId);
        this.scenes = scenes;
        this.itemsPresent = !!this.scenes?.length;
        this.loading = false;
      }
    });

    this._activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(async (params) => {
      if (params.sceneId) {
        this.setSubdomain = true;
        this._sceneService.getById(params.sceneId).then((data) => data && (this.selectedScene = data));
      }
    });

    this._domainService.changeSubject$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.domainToChangeSpace = data;
      if (data) {
        this.onReset();
        this.getInputValue(data.Path.split('.')[0]);
      }
    });

    this._domainService.data$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.takenScenes = data;
    });

    const parent = (this.elementRef.nativeElement as HTMLElement).parentElement;
    if (parent) {
      parent.onscroll = () => {
        this.updateListIndices(parent);
      };
    }
  }

  async getData(enterpriseId: string | undefined) {
    let filterConditions: ((scene: XRScene) => boolean)[] = [(scene: XRScene) => !scene.isExpired];

    const canConnectToDomainInFreeScene =
      this._pricingPlanService.scenePlans!.Free.PublishSpace &&
      this._pricingPlanService.scenePlans!.Free.ConnectOwnXrSubdomain &&
      this._pricingPlanService.scenePlans!.Free.ConnectOwnedDomain;
    const canConnectToDomainInProScene =
      this._pricingPlanService.scenePlans!.Pro.PublishSpace &&
      this._pricingPlanService.scenePlans!.Pro.ConnectOwnXrSubdomain &&
      this._pricingPlanService.scenePlans!.Pro.ConnectOwnedDomain;

    const query = enterpriseId ? `enterpriseId=${enterpriseId}` : '';
    await Promise.all([this._sceneService.get('', query, undefined, true, true), this._domainService.get(enterpriseId)]);

    if (!canConnectToDomainInFreeScene) {
      filterConditions.push((scene: XRScene) => scene.Plan !== ScenePlan.Free);
    }

    if (!canConnectToDomainInProScene) {
      filterConditions.push((scene: XRScene) => scene.Plan !== ScenePlan.Pro);
    }

    const filteredScenes = this._sceneService.getCached().filter((scene) => filterConditions.every((condition) => condition(scene)));

    return Promise.resolve(filteredScenes);
  }

  updateListIndices(element: HTMLElement) {
    const ratio = Math.round(element.clientWidth / element.clientHeight) * 2;
    this.endIndex = Math.max(Math.round(((element.scrollTop + element.clientHeight) / 520) * 1.5) * ratio, this.endIndex);
  }

  setSelectedScene(scene: XRScene) {
    this.selectedScene = scene;
    this.setSubdomain = true;
  }

  onReset() {
    this.inputTouched = false;
    this.loading = false;
    this.isValid = true;
    this.isAvailable = true;
    this.isSwear = false;
  }

  async getInputValue(value: string) {
    if (value !== '') {
      this.loading = true;
      this.inputTouched = true;

      this.isSwear = (await this._sceneService.CheckCommentOfInteraction(value)).includes('*');

      if (!this.isSwear) {
        const regexp = new RegExp('^[a-zA-Z0-9-]+$');
        this.isValid = regexp.test(value);

        if (this.isValid) {
          this.subdomain = value;
          this.domain = this.subdomain + this.domainName;
          await this._domainService.validate(this.domain).then((res) => {
            this.isAvailable = this.domainToChangeSpace === undefined ? res.IsAvailable : true;
            this.isValid = res.IsValid;
          });
        }
      }

      this.loading = false;
    }
  }

  async onClick() {
    const message = this._modalService.open(ConfirmationComponent);
    message.instance.title = this._translateService.instant('shared.confirmation.notice');
    message.instance.body = this._translateService.instant('domain.confirmation.message', {
      domain: this.domainToChangeSpace?.Path || this.domain,
    });
    message.instance.confirmAction = 'shared.actions.save';
    message.instance.cancelAction = 'shared.confirmation.cancel';

    if (await message.result) {
      this.isClicked = true;
      if (this.domainToChangeSpace) {
        const domain = {
          Path: this.domainToChangeSpace.Path,
          SceneId: this.selectedScene.Id,
          SslEnabled: true,
          Id: this.domainToChangeSpace.Id,
          DomainType: this.domainToChangeSpace.DomainType,
        };
        this._domainService.put(domain as DomainMap).then(() => {
          this._router.navigate(['domain', 'owned']);
        });
      } else {
        const domain = {
          Path: this.domain,
          SceneId: this.selectedScene.Id,
          SslEnabled: true,
          DomainType: DomainType.Subdomain,
        };
        this._domainService.post(domain as DomainMap).then(() => {
          this._router.navigate(['domain', 'owned']);
        });
      }
    }
  }
}
